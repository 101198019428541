import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import comingSoon from "../../../../assets/images/coming_soon.png";
import {InfoWindow, Marker} from "@react-google-maps/api";
import {roomLayoutEnums} from "../../enums/layouts";
import { MapPropertiesModal } from "./MapPropertiesModal";

const MapProperties = ({
  property,
  clusterer
}) => {

  const [selectedProperty, setSelectedProperty] = useState([]);

  const addSelectedProperty = position  => {
    const selectedPropertyList = [];
    if (selectedProperty != null) {
      selectedPropertyList.push(selectedProperty);
    }
    selectedPropertyList.push(position);
    setSelectedProperty(selectedPropertyList);
  }

  const removeSelectedProperty = position  => {
    setSelectedProperty(selectedProperty.filter(e => (e.lat != position.lat) && (e.lng != position.lng)));
  }

  const hasSelectedProperty = position  => {
    return !!selectedProperty.find(e => (e.lat == position.lat) && (e.lng == position.lng));
  }

  const elapsedYearsAndMonth = (year, month) => {
    const nowDate = new Date();
    const buildingDate = new Date(1970, 0, 1);
    buildingDate.setTime(nowDate.getTime() - buildingDate.getTime());
    let elapsedYear = buildingDate.getUTCFullYear() - year;
    let elapsedMonth = buildingDate.getUTCMonth() - (month - 1);
    if (elapsedMonth < 0) {
      elapsedYear--;
      elapsedMonth += 12;
    }
    return `築${elapsedYear}年`;
  };

  const position = { lat: Number(property.lat), lng: Number(property.lon) };
  const propertyRoom = property.rooms[0]

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const [showModal, setShowModal] = useState(false);

  const rooms = [];
  property.rooms.forEach(room => {
    rooms.push(
      <div className="MapPropertiesModal_item">
        <div className="MapPropertiesModal_movieArea">
          {/* <img src={comingSoon} /> */}
          {property.rooms[0].room_videos.length === 0 ? (
            <img src={comingSoon} />
          ) : (
            <img src={property.rooms[0].room_videos[0].movie_thumbnail_url} />
          )}
        </div>
        <div className="MapPropertiesModal_info">
          <div className="MapPropertiesModal_title">
            {property.name}
            <div className="MapPropertiesModal_roomNum">
              {propertyRoom.name}
            </div>
          </div>
          <div className="MapPropertiesModal_price">
            <div className="MapPropertiesModal_rent">
              {room.contracts[0]?.rent ? (
                <span>{(room.contracts[0]?.rent / 10000).toFixed(1)}</span>
              ) : (
                " お問い合わせください "
              )}
              {room.contracts[0]?.rent && "万円"}
            </div>
            <div className="MapPropertiesModal_fee">
              （管理費
              <span>
                {room.contracts[0]?.management_fee ? (
                  <>{room.contracts[0]?.management_fee.toLocaleString()}</>
                ) : (
                  " "
                )}
              </span>
              円）
            </div>
          </div>
          <div className="MapPropertiesModal_overview">
            {property.property_accesses[0]?.station_master.name}駅 徒歩
            {property.property_accesses[0]?.walkby}分
            <br />
            {roomLayoutEnums[room.layout]} / {room.size}㎡ / {room.floor}階 /{" "}
            {elapsedYearsAndMonth(
              property.building_year,
              property.building_month
            )}
          </div>

          <div className="btnStyle-fill" onClick={() => setShowModal(true)}>
            このお部屋をもっと見てみる
          </div>
        </div>
      </div>
    );
  });


  return (
    <div>
      <Marker
        key={property.id}
        position={{ lat: Number(property.lat), lng: Number(property.lon) }}
        clusterer={clusterer}
        onClick={() => {
          addSelectedProperty(position);
        }}
      />
      {hasSelectedProperty(position) && (
        <InfoWindow
          onCloseClick={() => {
            removeSelectedProperty(position);
          }}
          position={{ lat: Number(property.lat), lng: Number(property.lon) }}
          options={{ maxWidth: 500 }}
          style={{ backgroundColor: "#000000" }}
        >
          <div className="MapPropertiesModal">
            <div className="MapPropertiesModal_overlay">
              <div className="MapPropertiesModal_contents right">
                <Slider {...sliderSettings}>{rooms}</Slider>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
      {showModal ? (
        <MapPropertiesModal
          showModal={showModal}
          setShowModal={setShowModal}
          room={property.rooms[0]}
          property={property}
          property_accesses={property.property_accesses}
          contracts={property.rooms[0].contracts}
          room_images={property.rooms[0].room_images}
          src={""}
          room_movie_url={property.rooms[0].room_videos}
          property_movie_url={property.property_videos}
        />
      ) : null}
    </div>
  );
};

export default MapProperties;
