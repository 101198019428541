import React, { useState } from "react";
import Room from "./Room";
import iconSlideArrow from "../../../../assets/images/icon/icon-slide-arrow.svg";
import Slider from "react-slick";

const Property = ({ property, detail, sort }) => {
  const [total, setTotal] = useState(
    property.rooms ? property.rooms.length : 0
  );
  const [nowNum, setNowNum] = useState(1);
  const NextArrow = (props) => {
    const onClick = () => {
      if (nowNum === total) {
        setNowNum(1);
      } else {
        setNowNum(nowNum + 1);
      }
      props.onClick();
    };
    return (
      <div className="propertyCard_next" onClick={onClick}>
        <div className="propertyCard_next-text">他の物件も見てみる</div>
        <img src={iconSlideArrow} />
        <div className="propertyCard_next-number">
          {nowNum}/{total}件
        </div>
      </div>
    );
  };
  const PrevArrow = (props) => {
    const onClick = () => {
      if (nowNum === 1) {
        setNowNum(total);
      } else {
        setNowNum(nowNum - 1);
      }
      props.onClick();
    };

    return (
      <div
        style={{ ...props.style, zIndex: "1" }}
        className="propertyCard_prev"
        onClick={onClick}
      >
        <img src={iconSlideArrow} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const rooms = [...property.rooms].sort((a, b) => {
    if ((sort === "3" && a.contracts[0]?.rent > b.contracts[0]?.rent) || a.contracts.length === 0) {
      return 1
    } 
  
    if ((sort === "2" && a.contracts[0]?.rent > b.contracts[0]?.rent) || a.contracts.length === 0) {
      return 1
    } 

    if (b.contracts.length > 0 && b.contracts[0].rent >= detail.contracts_rent_gteq && b.contracts[0].rent <= detail.contracts_rent_lteq) {
      return 1
    }

    return -1
  })

  return (
    <>
      <div className="propertyCard_list-wrapper">
        <div className="propertyCard_list">
          <Slider {...settings}>
            {rooms.map((room, index) => {
              return (
                <Room
                  key={room.id}
                  property={property}
                  room={room}
                  total={total}
                  myNum={index + 1}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Property;
