import { Button } from "@material-ui/core";
import React from "react";
import { showStationSearchState } from "../state";
import { useRecoilState } from "recoil";
import iconTrain from "../../../../../assets/images/top/icon_train_36x36.svg";

function StationSearch() {
  const [isShowStationSearch, setShowStationSearch] = useRecoilState(
    showStationSearchState
  );

  return (
    <React.Fragment>
      <div
        className="top_searchMapRight-button"
        onClick={() => {
          sessionStorage.removeItem("city_ids");
          sessionStorage.removeItem("station_master");
          sessionStorage.removeItem("detail");
          setShowStationSearch(!isShowStationSearch);
        }}
      >
        <img
          className="top_searchMapRight-icon"
          src={iconTrain}
          alt="沿線・駅<探すアイコン"
        />
        <div>
          <div className="top_searchMapRight-buttonTop">沿線・駅</div>
          <div className="top_searchMapRight-buttonBottom">から</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StationSearch;
