import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import CheckBox from "./CheckBox";

const Rent = ({ values, handleSelectValues, details }) => {
  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">賃料</div>
      <div className="searchRent_select">
        <div className="searchRent_input">
          <div className="form-group">
            <select
              className="form-control widhout-shadow"
              name="detail[contracts_rent_gteq]"
              id={"lower"}
              // value={values.lower}
              onChange={handleSelectValues}
              defaultValue={
                details?.detail_contracts_rent_gteq &&
                details?.detail_contracts_rent_gteq
              }
            >
              <option hidden value={0}>
                下限なし
              </option>
              <option value={10000}>1万以上</option>
              <option value={20000}>2万以上</option>
              <option value={30000}>3万以上</option>
              <option value={35000}>3.5万以上</option>
              <option value={40000}>4万以上</option>
              <option value={45000}>4.5万以上</option>
              <option value={50000}>5万以上</option>
              <option value={55000}>5.5万以上</option>
              <option value={60000}>6万以上</option>
              <option value={65000}>6.5万以上</option>
              <option value={70000}>7万以上</option>
              <option value={75000}>7.5万以上</option>
              <option value={80000}>8万以上</option>
              <option value={85000}>8.5万以上</option>
              <option value={90000}>9万以上</option>
              <option value={95000}>9.5万以上</option>
              <option value={100000}>10万以上</option>
              <option value={105000}>10.5万以上</option>
              <option value={110000}>11万以上</option>
              <option value={115000}>11.5万以上</option>
              <option value={120000}>12万以上</option>
              <option value={125000}>12.5万以上</option>
              <option value={130000}>13万以上</option>
              <option value={135000}>13.5万以上</option>
              <option value={140000}>14万以上</option>
              <option value={145000}>14.5万以上</option>
              <option value={150000}>15万以上</option>
              <option value={155000}>15.5万以上</option>
              <option value={160000}>16万以上</option>
              <option value={165000}>16.5万以上</option>
              <option value={170000}>17万以上</option>
              <option value={175000}>17.5万以上</option>
              <option value={180000}>18万以上</option>
              <option value={185000}>18.5万以上</option>
              <option value={190000}>19万以上</option>
              <option value={195000}>19.5万以上</option>
              <option value={200000}>20万以上</option>
              <option value={210000}>21万以上</option>
              <option value={220000}>22万以上</option>
              <option value={230000}>23万以上</option>
              <option value={240000}>24万以上</option>
              <option value={250000}>25万以上</option>
              <option value={260000}>26万以上</option>
              <option value={270000}>27万以上</option>
              <option value={280000}>28万以上</option>
              <option value={290000}>29万以上</option>
              <option value={300000}>30万以上</option>
              <option value={350000}>35万以上</option>
              <option value={400000}>40万以上</option>
              <option value={500000}>50万以上</option>
              <option value={1000000}>100万以上</option>
            </select>
          </div>
        </div>
        <span>〜</span>
        <div className="searchRent_input">
          <div className="form-group">
            <select
              className="form-control widhout-shadow"
              id={"upper"}
              // value={values.upper}
              defaultValue={
                details?.detail_contracts_rent_lteq &&
                details?.detail_contracts_rent_lteq
              }
              name="detail[contracts_rent_lteq]"
              onChange={handleSelectValues}
            >
              <option hidden value={0}>
                上限なし
              </option>
              <option value={10000}>1万以下</option>
              <option value={20000}>2万以下</option>
              <option value={30000}>3万以下</option>
              <option value={35000}>3.5万以下</option>
              <option value={40000}>4万以下</option>
              <option value={45000}>4.5万以下</option>
              <option value={50000}>5万以下</option>
              <option value={55000}>5.5万以下</option>
              <option value={60000}>6万以下</option>
              <option value={65000}>6.5万以下</option>
              <option value={70000}>7万以下</option>
              <option value={75000}>7.5万以下</option>
              <option value={80000}>8万以下</option>
              <option value={85000}>8.5万以下</option>
              <option value={90000}>9万以下</option>
              <option value={95000}>9.5万以下</option>
              <option value={100000}>10万以下</option>
              <option value={105000}>10.5万以下</option>
              <option value={110000}>11万以下</option>
              <option value={115000}>11.5万以下</option>
              <option value={120000}>12万以下</option>
              <option value={125000}>12.5万以下</option>
              <option value={130000}>13万以下</option>
              <option value={135000}>13.5万以下</option>
              <option value={140000}>14万以下</option>
              <option value={145000}>14.5万以下</option>
              <option value={150000}>15万以下</option>
              <option value={155000}>15.5万以下</option>
              <option value={160000}>16万以下</option>
              <option value={165000}>16.5万以下</option>
              <option value={170000}>17万以下</option>
              <option value={175000}>17.5万以下</option>
              <option value={180000}>18万以下</option>
              <option value={185000}>18.5万以下</option>
              <option value={190000}>19万以下</option>
              <option value={195000}>19.5万以下</option>
              <option value={200000}>20万以下</option>
              <option value={210000}>21万以下</option>
              <option value={220000}>22万以下</option>
              <option value={230000}>23万以下</option>
              <option value={240000}>24万以下</option>
              <option value={250000}>25万以下</option>
              <option value={260000}>26万以下</option>
              <option value={270000}>27万以下</option>
              <option value={280000}>28万以下</option>
              <option value={290000}>29万以下</option>
              <option value={300000}>30万以下</option>
              <option value={350000}>35万以下</option>
              <option value={400000}>40万以下</option>
              <option value={500000}>50万以下</option>
              <option value={1000000}>100万以下</option>
            </select>
          </div>
        </div>
      </div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"commonServiceFee"}
            name="detail[contracts_management_fee]"
            value={"true"}
            // checked={values.commonServiceFee}
            defaultChecked={
              details && details.contracts_management_fee === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"commonServiceFee"}>
            共益費/管理費含む
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"parkingFee"}
            name="detail[have_bicycle_parking_eq]"
            value={"true"}
            // checked={values.parkingFee}
            defaultChecked={
              details && details.have_bicycle_parking_eq === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"parkingFee"}>
            駐車場料金含む
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"noKeyMoney"}
            name="detail[contracts_reikin_blank]"
            value={"true"}
            // checked={values.noKeyMoney}
            defaultChecked={
              details && details.contracts_reikin_blank === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"noKeyMoney"}>
            礼金なし
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"noDeposit"}
            name="detail[contracts_shikikin_blank]"
            value={"true"}
            // checked={values.noDeposit}
            defaultChecked={
              details && details.contracts_shikikin_blank === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"noDeposit"}>
            敷金/保証金なし
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"initialCostPayment"}
            name="detail[initialCostPayment_blank]"
            value={"true"}
            // checked={values.initialCostPayment}
            defaultChecked={
              details && details.initialCostPayment_blank === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"initialCostPayment"}>
            初期費用カード決済可
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"rentCostPayment"}
            name="detail[rentCostPayment_blank]"
            value={"true"}
            // checked={values.rentCostPayment}
            defaultChecked={
              details && details.rentCostPayment_blank === "true"
            }
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"rentCostPayment"}>
            家賃カード決済可
          </label>
        </li>
      </ul>
    </div>
  );
};

export default Rent;
