<template>
  <div>
    <h2 class="contents-title font-weight-bold container mt-3">はじめに</h2>
    <div class="contents-box my-2">
      <div class="container py-3">
        <div class="row align-items-center">
          <div class="col-4 text-center g-2">
            <img src="~navi/navi_default.png" height="160" class="mx-auto" alt="アドバイザー">
          </div>
          <div class="col-8 g-2">
            <h3 class="h5 font-weight-bold">はじめまして！</h3>
            <p class="mb-1">
              OHEYA案内24ナビへようこそ。<br />
              お客様だけで内見ができますようこのページでサポートして参ります。<br />
              内見完了までよろしくお願いいたします。<br />
              わからないことがあれば画面下のチャットサポートにご連絡ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data() {
    return {}
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>

