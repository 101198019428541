<template>
  <header>
    <div class="navbar navbar-dark bg-dark shadow-sm">
      <div class="container">
        <a href="#" class="navbar-brand d-flex align-items-center">
          <img src="~navi/logo.png" width='164'>
        </a>
        <a href="#" class="user-name">
          {{customerFullName}} 様
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('customer', ['customerFullName']),
  },
  methods: {
  }
}
</script>

<style scoped>
.navbar-brand{
  cursor: default;
}
.user-name{
  cursor: default;
}
</style>

