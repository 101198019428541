import React, { useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StationAccordion = (props) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          className="station-accordion-header"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions-content"
          id="additional-actions-header"
        >
          <div className={props.isLine ? "searchTrain_item formStyle_check" : ""}>
            {props.isLine && (
              <input
                type="checkbox"
                id={props.id}
                name="station_master[][line]"
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
              />
            )}
            <label className="searchRent_name non-style" htmlFor={props.id}>{props.title}</label>
          </div>
        </AccordionSummary>
        <AccordionDetails className="station-accordion-detail">
          {props.children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default StationAccordion;
