export const propertyDirectionEnums = {
  north: "北",
  north_east: "北東",
  east: "東",
  south_east: "南東",
  south: "南",
  south_west: "南西",
  west: "西",
  north_west: "北西",
};
