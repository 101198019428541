import React, {useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconArrow from "../../../../assets/images/icon/icon-arrow-left.svg";
import { imageTypeEnums } from "../../enums/imageType";

const PropertyModal = ({ room_images, setModalAppear }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderNavSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: room_images.length,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    focusOnSelect: true,
  };

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  return (
    <>
      <div className="PropertiesModal">
        <div className="PropertiesModal_overlay">
          <div className="PropertiesModalBlack image">
            <div className="PropertiesModalBlack_header">
              <div
                className="btnStyle-white"
                onClick={() => {
                  setModalAppear((prev) => !prev);
                }}
              >
                <img src={iconArrow} />
                お部屋情報に戻る
              </div>
              <div className="PropertiesModalBlack_number">
                1/{room_images.length}
              </div>
            </div>
            <Slider
              {...sliderSettings}
              asNavFor={slider2}
              ref={(slider) => setSlider1(slider)}
              className="slider-for PropertiesModalBlackSlider_for"
            >
              {room_images.map((value, index) => (
                <div className="PropertiesModalBlackSlider_item" key={index}>
                  <div className="PropertiesModalBlackSlider_img">
                    <img src={value.name.url} />
                  </div>
                  <p>{imageTypeEnums[value.role]}</p>
                </div>
              ))}
            </Slider>
            <Slider
              {...sliderNavSettings}
              asNavFor={slider2}
              ref={(slider) => setSlider1(slider)}
              className="slider-nav PropertiesModalBlackSlider_nav"
            >
              {room_images.map((value, index) => (
                <div className="PropertiesModalBlackSlider_navItem" key={index}>
                  <img src={value.name.url} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};
export default PropertyModal;