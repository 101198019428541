<template>
  <div>
    <div v-show="shooting">
      <div>
        <div class="alert alert-danger alert-rounded" v-if="!errorMsg == ''">
          {{ errorMsg }}
        </div>
        <div class='row' v-show="capturedUrl == ''">
          <div class='col-12 text-center' id='faceCamera'>
            <video ref="video" id="video" width='300' height="200" autoplay></video>
            <img src="~face_size_image.png" width='300' height='200' id="faceSizeImage">
          </div>
          <div class='col-12 text-center'>
            <a href="#" id="camera-icon" @click="capture()" class="btn btn--circle btn--circle-a btn--shadow">
              <i class="fas fa-camera fs28"></i>
            </a>
          </div>
        </div>
        <div class='row' v-show="!capturedUrl == ''">
          <div class='col-sm-12 text-center'>
            <img :src="capturedUrl" />
          </div>
        </div>
        <div class="row bgw pr-3 pl-3 pb-5 pt-3" v-show="!capturedUrl == ''">
          <div class='col-6 text-center mt-3'>
            <button class='btn btn-block btn-rounded btn-secondary' @click="retake">取り直し</button>
          </div>
          <div class='col-6 text-center mt-3'>
            <button class='btn btn-block btn-rounded btn-success' @click="save">決定</button>
        </div>

        </div>
        <canvas ref="canvas" id="canvas" width="300px" height="200px"></canvas>
      </div>
      <photo-untakeable-modal />
    </div>
    <Loading v-show="!shooting"/>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/company/Loading';
import PhotoUntakeableModal from '@/components/PhotoUntakeableModal';
export default {
  name: 'TakeFaceImage',
  components: { PhotoUntakeableModal, Loading },
  data() {
    return {
      video: {},
      canvas: {},
      capturedUrl: '',
      userId: '1',
      errorMsg: '',
      reservation: null,
      reservationToken: '',
      showCautionModal: false,
      shooting: true,
    }
  },
  async mounted() {
    await this.setCamera()
    this.reservationToken = window.location.href.match(/.+\/navi\/(.+)\/customers\/.+\/face_photo/)[1]
    await this.fetchReservation()
  },
  computed: {
  },
  methods: {
    async setCamera(){
      this.video = this.$refs.video
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          var stream = await navigator.mediaDevices.getUserMedia({ video: true })
          this.video.srcObject = stream
          this.video.play()
        } catch(e){
          if(e.name == 'NotAllowedError'){
            this.showCautionModal = true
          }
        }
      }else{
        //カメラ未対応時の処理
        alert('カメラ未対応')
      }
    },
    capture () {
      this.canvas = this.$refs.canvas
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, 300, 200)
      this.capturedUrl = this.canvas.toDataURL('image/jpeg')
    },
    retake() {
      this.capturedUrl = ''
    },
    async save(){
      this.shooting = false
      try {
        const res = await axios.patch(`/api/navi/customers/${this.reservation.customer.id}/save_face_image`, {
          face_image: this.capturedUrl,
        })
        if(res.data.error == ''){
          location.href = `/navi/${this.reservationToken}`
        }else{
          this.capturedUrl = ''
          this.errorMsg = res.data.error
          this.shooting = true
          setTimeout(() => {
            this.errorMsg = '' }
            , 3000
          )
        }
      } catch {
        this.capturedUrl = ''
        this.errorMsg = '登録時にエラーが発生しました。お手数ですが、運営事務局までお問い合わせください。'
        this.shooting = true
        setTimeout(() => {
          this.errorMsg = '' }
          , 3000
        )
      }
    },
    async fetchReservation() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}`)
        this.reservation = res.data.reservation
      } catch(e) {
        alert(e)
      }
    },
    hide() {
      this.$modal.hide('photo-untakeable-photo');
    },
  },
  watch: {
    showCautionModal: function(newVal, oldVal){
      if(newVal){
        this.$modal.show('photo-untakeable-modal')
      }else{
        this.$modal.hide('photo-untakeable-modal')
      }
    }
  }
}
</script>

<style scope>
#canvas {
  display: none;
}
.capture {
  /* display: inline; */
  padding: 5px;
}
a.btn--orange {
  color: #fff;
  background-color: #eb6100;
}
a.btn--orange:hover {
  color: #fff;
  background: #f56500;
}
a.btn--shadow {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
a.btn--circle {
  border-radius: 50%;
  line-height: 90px;
  width: 75px;
  height: 75px;
  padding: 0;
}
video {
  object-fit: fill;
}
#faceCamera {
  position: relative;
}
#faceSizeImage {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
