import React, { useEffect, useState } from "react";
import StationAccordion from "./StationSearch/StationAccordion";
import iconTrain from "../../../../assets/images/icon/icon_train.svg";
import iconSearch from "../../../../assets/images/icon_search.svg";
import DetailConditions from "../PropertiesSearch/DetailConditions";

const StationSearch = (props) => {
  const [stations, setStations] = useState({});
  const [showDetailConditions, setShowDetailConditions] = useState(false);
  const [showStationSearch, setShowStationSearch] = useState(true);
  const [selectStation, setStation] = useState([]);

  useEffect(() => {
    const getStation = async () => {
      let tmpStations = {};
      JSON.parse(props.stationRoutes).station_routes.forEach((route) => {
        tmpStations[route.name] = {};
        route.station_line_masters.forEach((line) => {
          tmpStations[route.name][line.name] = line.station_masters;
        });
      });
      setStations(tmpStations);
      props.selectStation && setStation(props.selectStation);
    };
    getStation();
  }, []);

  return (
    <form action="/searches" method="post" name="form">
      {props.selectCityIds &&
        props.selectCityIds.map((id) => {
          return (
            <input
              type="hidden"
              id={`region_${id}`}
              name="city_ids[]"
              value={id}
            />
          );
        })}
      <DetailConditions details={props.details} isShow={showDetailConditions} />
      <main className="w-full" hidden={!showStationSearch}>
        <div className="breadcrumbStyle">
          <a href="/">トップ</a>
          <span> ＞ </span>
          <span>沿線・駅から探す</span>
        </div>

        <h2 className="text-center searchTrain_title">
          <img src={iconTrain} />
          沿線・駅から探す
        </h2>
        <div className="searchTrain_wrapper" id="stationSearch">
          <div className="searchTrain_selectWrapper">
            {stations &&
              Object.keys(stations).map((route) => {
                const lines = stations[route];
                return (
                  <StationAccordion title={route} key={route} isLine={false}>
                    {Object.keys(lines).map((line) => {
                      const stations = lines[line];
                      return (
                        <StationAccordion
                          title={line}
                          key={line}
                          value={stations[0].line_id}
                          id={stations[0].line_id}
                          isLine={true}
                          checked={
                            selectStation &&
                            selectStation.some(
                              (v) => v.line_id === stations[0].line_id
                            )
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const concatStations =
                                selectStation.concat(stations);
                              setStation(concatStations);
                              return;
                            }
                            setStation(
                              selectStation &&
                                selectStation.filter(
                                  (s) => s.line_id !== stations[0].line_id
                                )
                            );
                          }}
                        >
                          <ul className="searchTrain_list">
                            {stations.map((station, stationIndex) => {
                              return (
                                <li
                                  className="searchTrain_item formStyle_check"
                                  key={stationIndex}
                                >
                                  <input
                                    type="checkbox"
                                    id={`${station.line_id}-${station.id}`}
                                    name="station_master[][station][]"
                                    value={station.id}
                                    checked={
                                      selectStation &&
                                      selectStation.some(
                                        (v) => v.id === station.id
                                      )
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setStation([...selectStation, station]);
                                        return;
                                      }
                                      setStation(
                                        selectStation &&
                                          selectStation.filter(
                                            (s) => s.id !== station.id
                                          )
                                      );
                                    }}
                                  />
                                  <label
                                    className="searchRent_name"
                                    htmlFor={`${station.line_id}-${station.id}`}
                                  >
                                    {station.name}
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </StationAccordion>
                      );
                    })}
                  </StationAccordion>
                );
              })}
          </div>

          <div className="searchArea_bottomMenu">
            <div className="searchArea_bottomMenuWrapper">
              <div
                className="btnStyle-white"
                onClick={(e) => {
                  setShowDetailConditions(true);
                  setShowStationSearch(!showStationSearch);
                }}
              >
                検索条件を追加
              </div>
              <button type="submit" className="btnStyle-fill" disabled={selectStation.length === 0}>
                <img src={iconSearch} />
                検索する
              </button>
            </div>
          </div>
        </div>
      </main>
    </form>
  );
};

export default StationSearch;
