export const VideoBgm = [
  "https://d7t15cfru2447.cloudfront.net/uploads/audio/SellBuyMusic+-+%E1%84%92%E1%85%A2%E1%84%87%E1%85%A7%E1%86%AB%E1%84%8B%E1%85%B4+%E1%84%8C%E1%85%A5%E1%84%82%E1%85%A7%E1%86%A8.mp3",
  "https://d7t15cfru2447.cloudfront.net/uploads/audio/MusMus-BGM-141.mp3",
  "https://d7t15cfru2447.cloudfront.net/uploads/audio/bird.mp3",
];

export const RandomBgm = (array) => {
  let rand = Math.random()*array.length | 0;
  let audioUrl = array[rand];
  return audioUrl;
}