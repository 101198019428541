import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { format } from "date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { useForm } from "react-hook-form";
import { roomLayoutEnums } from "../../../enums/layouts";
import comingSoon from "../../../../../assets/images/coming_soon.png";
import iconCalender from "../../../../../assets/images/icon/icon-calendar.svg";
import { Modal } from "./Modal";
import ReservationCalenderModal, {
  convertDateTimeStr,
  convertDateTimeObj,
  generateNotAcceptedDates,
} from "../ReservationCalenderModal";
import { RecoilRoot } from "recoil";

export const SchedeleCheckInput = ({
  formData,
  setForm,
  navigation,
  props,
  reserved,
}) => {
  const { room, ...other } = props;
  const property = room.property;
  const contract = room.contracts[0];
  const property_access = property.property_accesses[0];
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [firstDateError, setFirstDateError] = useState("");
  const [secondDateError, setSecondDateError] = useState("");
  const [firstNameError ,setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [telError, setTelError] = useState("");
  const [emailError, setEmailError] = useState("");

  const onSubmit = () => {
    if (formData.first_date_time) {
      setFirstDateError("");
    }
    if (formData.second_date_time) {
      setSecondDateError("");
    }
    if (formData.last_name) {
      setLastNameError("");
    }
    if (formData.first_name) {
      setFirstNameError("");
    }
    if (formData.email) {
      setEmailError("");
    }
    if (formData.tel) {
      setTelError("");
    }
    if (
      !formData.first_date_time ||
      !formData.second_date_time ||
      !formData.last_name ||
      !formData.first_name ||
      !formData.email ||
      !formData.tel
    ) {
      if (!formData.first_date_time) {
        setFirstDateError("内見第一希望日を入力してください。");
      }
      if (!formData.second_date_time) {
        setSecondDateError("内見第二希望日を入力してください。");
      }
      if (!formData.last_name) {
        setLastNameError("姓を入力してください。");
      }
      if (!formData.first_name) {
        setFirstNameError("名を入力してください。");
      }
      if (!formData.email) {
        setEmailError("メールアドレスを入力してください。");
      }
      if (!formData.tel) {
        setTelError("お電話番号を入力してください。");
      }
      return;
    }
    navigation.next();
  };

  const [reservedDateTimes, setReservedDateTimes] = useState({});
  const [notAcceptedDates, setNotAcceptedDates] = useState([]);

  const [isShowFirstModal, setIsShowFirstModal] = useState(false);
  const [isShowSecondModal, setIsShowSecondModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  useEffect(() => {
    if (reserved === null || reserved === []) return;

    let tmpArr = [];
    for (const v of reserved) {
      if (v.start_at) {
        tmpArr.push(format(new Date(v.start_at), "yyyy-MM-dd HH:mm"));
      }
    }
    const tmpReservedDateTimes = convertDateTimeObj(tmpArr);
    const tmpNotAcceptedDates = generateNotAcceptedDates(tmpReservedDateTimes);
    setReservedDateTimes(tmpReservedDateTimes);
    setNotAcceptedDates(tmpNotAcceptedDates);
  }, [reserved]);

  const handleCloseModal = () => {
    setIsShowFirstModal(false);
    setIsShowSecondModal(false);
  };

  const handleSetFirstDateTime = (date, time) => {
    if (date === "" || time === "") return;
    setForm({
      target: { name: "first_date", value: date },
    });
    setForm({
      target: { name: "first_time", value: time },
    });
    const first_date_time = convertDateTimeStr(date, time);
    setForm({
      target: { name: "first_date_time", value: first_date_time },
    });
    handleCloseModal();
  };

  const handleSetSecondDateTime = (date, time) => {
    if (date === "" || time === "") return;
    setForm({
      target: { name: "second_date", value: date },
    });
    setForm({
      target: { name: "second_time", value: time },
    });
    const second_date_time = convertDateTimeStr(date, time);
    setForm({
      target: { name: "second_date_time", value: second_date_time },
    });
    handleCloseModal();
  };

  return (
    <ThemeProvider>
      <Container>
        <div className="reservations_wrapper">
          <div className="reservationsProperty">
            <div className="reservationsProperty_top">
              <div className="reservationsProperty_heading">
                内見予約する物件
              </div>
            </div>

            <div className="reservationsProperty_card">
              <div className="reservationsProperty_photo">
                <img src={room.movie_thumbnail_url || comingSoon} />
              </div>
              <div className="reservationsProperty_overview">
                <div className="reservationsProperty_name">
                  {property.name} {room.name}
                </div>
                <div class="reservationsProperty_rent">
                  <span>
                    {contract?.rent ? (
                      <span>{(contract?.rent / 10000).toFixed(1)}</span>
                    ) : (
                      " お問い合わせください "
                    )}
                  </span>
                  {contract?.rent && "万円"}
                  (管:
                  {contract?.management_fee ? (
                    <>{contract?.management_fee.toLocaleString()}</>
                  ) : (
                    " "
                  )}
                  円）
                </div>
                <div className="reservationsProperty_other">
                  <span>
                    {property_access?.station_master.name}
                    {property_access?.station_master.name && "駅"}{" "}
                    {property_access?.walkby && "徒歩"}
                    {property_access?.walkby}
                    {property_access?.walkby && "分"}
                  </span>
                  <span>
                    {room?.layout && roomLayoutEnums[room?.layout]}
                    {room?.size && "/"} {room?.size && room?.size}
                    {room?.size && "㎡"}
                  </span>
                </div>
              </div>
              <div
                class="reservationsProperty_modallink"
                onClick={() => setShowModal(true)}
              >
                詳細を見る
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} class="reservations_form">
            <div class="reservations_input">
              <label class="contact_label">
                内見第一希望日
                <span class="formStyle_input-required">必須</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="first_date_time"
                placeholder="日付選択"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.first_date_time}
                onClick={() => {
                  setIsShowFirstModal(true);
                }}
                onChange={setForm}
                autoComplete="off"
              />
              <div className="reservations_calendar">
                <img src={iconCalender} />
              </div>
            </div>
            <span className="text-danger">{firstDateError}</span>
            <div className="reservations_input">
              <label className="contact_label">内見第二希望日</label>
              <span class="formStyle_input-required">必須</span>
              <input
                type="text"
                className="form-control"
                name="second_date_time"
                placeholder="日付選択"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formData.second_date_time}
                onClick={() => {
                  setIsShowSecondModal(true);
                }}
                onChange={(e) => {
                  console.log("change");
                }}
                autoComplete="off"
              />
              <div className="reservations_calendar">
                <img src={iconCalender} />
              </div>
            </div>
            <span className="text-danger">{secondDateError}</span>
            <div class="reservations_input">
              <label class="contact_label">
                姓<span class="formStyle_input-required">必須</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="last_name"
                {...register("last_name", {
                  maxLength: 80,
                })}
                onChange={setForm}
                value={formData.last_name}
                autoComplete="on"
                placeholder="例：山田"
              />
              <span className="text-danger">{lastNameError}</span>
              {errors.last_name && errors.last_name.type === "maxLength" && (
                <span className="text-danger">
                  80文字以下で入力してください。
                </span>
              )}
            </div>
            <div className="reservations_input">
              <label className="contact_label">
                名<span className="formStyle_input-required">必須</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="first_name"
                {...register("first_name", {
                  maxLength: 80,
                })}
                onChange={setForm}
                value={formData.first_name}
                autoComplete="on"
                placeholder="例：太郎"
              />
              <span className="text-danger">{firstNameError}</span>
              {errors.first_name && errors.first_name.type === "maxLength" && (
                <span className="text-danger">
                  80文字以下で入力してください。
                </span>
              )}
            </div>
            <div className="reservations_input">
              <label className="contact_label">
                メールアドレス
                <span className="formStyle_input-required">必須</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                {...register("email", {
                  pattern:
                    /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                })}
                onChange={setForm}
                value={formData.email}
                autoComplete="on"
                placeholder="例：liferee@chintai.com"
              />
              <span className="text-danger">{emailError}</span>
              {errors.email && errors.email.type === "pattern" && (
                <span className="text-danger">
                  メールアドレスを正しく入力してください。
                </span>
              )}
            </div>
            <div className="reservations_input">
              <label className="contact_label">
                お電話番号<span className="formStyle_input-required">必須</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="tel"
                {...register("tel", {
                  pattern: /^0[-0-9]{9,12}$/,
                })}
                onChange={setForm}
                value={formData.tel}
                autoComplete="on"
                placeholder="例：09012345678"
              />
              <span className="text-danger">{telError}</span>
              {errors.tel && errors.tel.type === "pattern" && (
                <span className="text-danger">
                  電話番号を正しく入力してください
                </span>
              )}
            </div>
            <div className="reservations_action">
              <Button
                variant="contained"
                fullWidth
                color="primary"
                className="btnStyle-fill"
                // onClick={() => navigation.next()}
                type="submit"
              >
                確認画面へ
              </Button>
              <input
                type="button"
                onClick={(e) => {
                  location.href = "/searches";
                }}
                className="btnStyle-white"
                value="物件の一覧ページに戻る"
              />
            </div>
            {isShowFirstModal ? (
              <ReservationCalenderModal
                date={formData.first_date}
                time={formData.first_time}
                handleCloseModal={handleCloseModal}
                handleSetPreviewReserveInfo={handleSetFirstDateTime}
                anotherDateTime={{
                  date: formData.second_date,
                  time: formData.second_time,
                }}
                reservedDateTimes={reservedDateTimes}
                notAcceptedDates={notAcceptedDates}
              />
            ) : null}
            {isShowSecondModal ? (
              <ReservationCalenderModal
                date={formData.second_date}
                time={formData.second_time}
                handleCloseModal={handleCloseModal}
                handleSetPreviewReserveInfo={handleSetSecondDateTime}
                anotherDateTime={{
                  date: formData.first_date,
                  time: formData.first_time,
                }}
                reservedDateTimes={reservedDateTimes}
                notAcceptedDates={notAcceptedDates}
              />
            ) : null}
          </form>
          {showModal ? (
            <RecoilRoot>
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                room={props.room}
                property={props.room.property}
                property_accesses={props.room.property.property_accesses}
                contracts={props.room.contracts}
                room_images={props.room.room_images}
                src={""}
                room_movie_url={props.room.room_movie_url}
                property_movie_url={props.room.property_movie_url}
              />
            </RecoilRoot>
          ) : null}
        </div>
      </Container>
    </ThemeProvider>
  );
};
