import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { format } from "date-fns";

// テストデータ
// const reserveTimes = [];
const reserveTimes = [
  "10:00~",
  "11:00~",
  "12:00~",
  "13:00~",
  "14:00~",
  "15:00~",
  "16:00~",
  "17:00~",
  "18:00~",
  "19:00~",
  "20:00~",
];

const ReservationCalenderModal = ({
  date,
  time,
  handleCloseModal,
  handleSetPreviewReserveInfo,
  anotherDateTime = { date: "", time: "" },
  reservedDateTimes,
  notAcceptedDates,
}) => {
  // 日付選択時にCSSが残って影響しないように動的にする
  const selectedAnotherDateCssClassName = "selected-date";
  const [times, setTimes] = useState([]);
  const [selectDate, setSelectDate] = useState(
    date === "" ? null : new Date(date)
  );
  const [selectTime, setSelectTime] = useState(time);
  const [selectedAnotherDate, setSelectedAnotherDate] = useState(
    selectedAnotherDateCssClassName
  );

  const handleSelectDate = (value) => {
    setSelectTime("");
    setSelectDate(value);
    let filTimes = reserveTimes;
    let selectedAnotherDateFlg = false;
    // 第一もしくは第二希望日で選択されている日時をチェック
    if (anotherDateTime.date !== "" && anotherDateTime.time !== "") {
      if (
        new Date(anotherDateTime.date).toDateString() === value.toDateString()
      ) {
        filTimes = filTimes.filter(
          (time) => time.match(anotherDateTime.time) == null
        );
        selectedAnotherDateFlg = true;
      }
    }

    const formatDate = format(new Date(value), "yyyy-MM-dd");
    // 登録済み日時をチェック
    if (reservedDateTimes !== {}) {
      if (formatDate in reservedDateTimes) {
        for (const v of reservedDateTimes[formatDate]) {
          filTimes = filTimes.filter((time) => time.match(v) == null);
        }
      }
    }

    const nowDate = new Date();
    const toDay = format(nowDate, "yyyy-MM-dd");
    if (toDay === formatDate) {
      const endTime = new Date(toDay + " 20:00:00");
      if (endTime <= nowDate) {
        // 最終時間を超えていたら時間を表示させない
        filTimes = [];
      } else {
        const nowTime = format(new Date(), "HH:00") + "~";
        const index = reserveTimes.indexOf(nowTime);
        if (index !== -1) {
          for (let i = 0; i <= index; i++) {
            filTimes = filTimes.filter(
              (time) => time.match(reserveTimes[i]) == null
            );
          }
        }
      }
    }

    selectedAnotherDateFlg === true
      ? setSelectedAnotherDate("")
      : setSelectedAnotherDate(selectedAnotherDateCssClassName);
    setTimes(filTimes);
  };

  const handleSelectTime = (event) => {
    setSelectTime(event.target.value);
  };

  return (
    <>
      <div id="calendar-overlay">
        <div id="calendar-content">
          <div id="calendar-inner-content">
            <button onClick={handleCloseModal}>×</button>
          </div>
          <div className="m-auto w-12/12">
            <Calendar
              locale="ja-JP"
              calendarType="US"
              onChange={handleSelectDate}
              minDate={new Date()}
              value={selectDate}
              tileDisabled={({ activeStartDate, date, view }) => {
                if (notAcceptedDates.length === 0) return;
                return notAcceptedDates.some(
                  (disabledDate) =>
                    date.getFullYear() === disabledDate.getFullYear() &&
                    date.getMonth() === disabledDate.getMonth() &&
                    date.getDate() === disabledDate.getDate()
                );
              }}
              tileClassName={({ date, view }) => {
                if (view === "month" && anotherDateTime.date !== "") {
                  const anotherDate = new Date(anotherDateTime.date);
                  if (anotherDate.toDateString() === date.toDateString()) {
                    return selectedAnotherDate;
                  }
                }
                return "";
              }}
            />
            {selectDate ? (
              <TimeBoxes
                times={times}
                selectDate={selectDate}
                selectTime={selectTime}
                handleSelectTime={handleSelectTime}
              />
            ) : (
              <div>
                <p>日付を選択してください。</p>
              </div>
            )}

            <div className="flex justify-center mt-2">
              <Button
                color="default"
                variant="contained"
                disabled={selectTime === "" ? true : false}
                onClick={() => {
                  handleSetPreviewReserveInfo(selectDate, selectTime);
                }}
              >
                日付を決定する
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TimeBoxes = ({ times, selectDate, selectTime, handleSelectTime }) => {
  return (
    <div className="mt-2 flex flex-wrap justify-start gap-2">
      <FormControl component="div">
        <FormLabel component="label">内見予約が可能な時間</FormLabel>
        <RadioGroup row value={selectTime} onChange={handleSelectTime}>
          {selectDate && times.length === 0 ? (
            <div>
              <p>申し訳ございません。</p>
              <p>ご案内できる時間がないため、別日を選択してください。</p>
            </div>
          ) : (
            times.map((time, i) => (
              <FormControlLabel
                style={{ marginLeft: "0px" }}
                key={i}
                className={
                  "text-s text-center w-2/12 p-2 pt-3 pb-3 border border-gray-200" +
                  (time === selectTime ? " checked" : "")
                }
                type="radio"
                value={time}
                control={<Radio className="timebox-radio" />}
                label={time}
              ></FormControlLabel>
            ))
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export const convertDateTimeStr = (date, time) => {
  const newDate = new Date(date);
  const ymd = newDate.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const dayOfWeek = ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"][
    newDate.getDay()
  ];
  const dateTime = ymd + dayOfWeek + " " + time;
  return dateTime;
};

/*
dateTimes : ['yyyy-MM-dd HH:mm']
*/
export const convertDateTimeObj = (dateTimes) => {
  if (dateTimes === null || dateTimes === []) return {};
  let tmpObj = {};
  for (const v of dateTimes) {
    const dateTime = v.split(" ");
    const date = dateTime[0];
    const time = dateTime[1];
    if (date in tmpObj) {
      if (tmpObj[date].indexOf(dateTime[1]) === -1) {
        tmpObj[date].push(time);
      }
    } else {
      tmpObj[date] = [time];
    }
  }
  return tmpObj;
};

export const generateNotAcceptedDates = (reservedDateTimes) => {
  if (reservedDateTimes === {}) return [];
  let tmpArr = [];
  for (const key in reservedDateTimes) {
    const filTimes = reserveTimes.filter(
      (val) => reservedDateTimes[key].indexOf(val.replace("~", "")) == -1
    );
    if (filTimes.length === 0) {
      tmpArr.push(new Date(key));
    }
  }
  return tmpArr;
};

export default ReservationCalenderModal;
