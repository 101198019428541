import React, {useState, useEffect} from "react"
import axios from 'axios';
import iconQuestion from "../../../../assets/images/top/icon_question.svg";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    axios.get('/api/portal/faqs.json')
    .then(resp => {
      setFaqs(resp.data);
    })
  }, [])

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }

    setSelected(i)
  }

  return (
    <>
      <h2 className="top_questionTitle">
        <img src={iconQuestion} alt="よくある質問アイコン" />
        よくある質問
      </h2>
      <div className="top_questionListWrapper">
        <ul className="top_questionList">
          {faqs.map((item, i) => (
            <li className={'top_questionItem' + ' ' + (selected === i ? 'active' : '')} key={item.id}>
              <div className="top_questionItem-title" onClick={() => toggle(i)}>
                <span>{item.question}</span>
                <i className="fas fa-chevron-down"></i>
                <i className="fas fa-chevron-up"></i>
              </div>
              <div className={'top_questionItem-info' + ' ' + (selected === i ? 'show' : 'content')}>{item.answer}</div>
            </li>
          ))}
        </ul>

        {/* <ul className="top_questionList">
          <li className="top_questionItem active">
            <div className="top_questionItem-title">
              <span>Q.管理費込みは賃料に含まれますか？</span>


            </div>
            <div className="top_questionItem-info">
              物件情報内で賃料と管理費・共益費が併記されている場合、賃料には管理費・共益費は含まれていません。
            </div>
          </li>
          <li className="top_questionItem">
            <div className="top_questionItem-title">
              <span>Q.物件の価格は税込みですか？</span>
              <i className="fas fa-chevron-down"></i>
              <i className="fas fa-chevron-up"></i>
            </div>
            <div className="top_questionItem-info">
              物件情報内で賃料と管理費・共益費が併記されている場合、賃料には管理費・共益費は含まれていません。
            </div>
          </li>
          <li className="top_questionItem">
            <div className="top_questionItem-title">
              <span>Q.内見予約を変更・キャンセルしたい</span>
              <i className="fas fa-chevron-down"></i>
              <i className="fas fa-chevron-up"></i>
            </div>
            <div className="top_questionItem-info">
              物件情報内で賃料と管理費・共益費が併記されている場合、賃料には管理費・共益費は含まれていません。
            </div>
          </li>
          <li className="top_questionItem">
            <div className="top_questionItem-title">
              <span>Q.問い合わせをしたが、返信がこない</span>
              <i className="fas fa-chevron-down"></i>
              <i className="fas fa-chevron-up"></i>
            </div>
            <div className="top_questionItem-info">
              物件情報内で賃料と管理費・共益費が併記されている場合、賃料には管理費・共益費は含まれていません。
            </div>
          </li>
        </ul> */}
      </div>

      <div className="wrapper">
        <div className="accordion">
         </div>
      </div>
    </>
  )
}

export default Faq
