import React, { useRef, useCallback, useEffect } from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { VideoBgm, RandomBgm } from "../VideoPlayer/VideoBgm";
import comingSoon from "../../../../assets/images/coming_soon.png";
import { roomLayoutEnums } from "../../enums/layouts";

const Properties = ({
  property_accesses,
  contracts,
  room,
  property,
  myNumber,
  videoUrl,
  handleEndCatch,
  playArrRef,
  audioArrRef,
  handleRecommendPropertyModal,
  room_movie_url,
  property_movie_url,
}) => {
  const wrapHandleEndCatch = useCallback(() => {
    handleEndCatch(myNumber);
  }, []);

  const playRef = useRef();
  const audioRef = useRef();
  useEffect(() => {
    // react-slickが裏で同じ要素を作るため上書き回避
    if (playArrRef.current[myNumber]) return;
    playArrRef.current[myNumber] = playRef;
    audioArrRef.current[myNumber] = audioRef;
    if (myNumber === 0 && videoUrl) {
      playRef.current.play();
    }
  }, []);

  const elapsedYearsAndMonth = (year, month) => {
    const nowDate = new Date();
    const buildingDate = new Date(1970, 0, 1);
    buildingDate.setTime(nowDate.getTime() - buildingDate.getTime());
    let elapsedYear = buildingDate.getUTCFullYear() - year;
    let elapsedMonth = buildingDate.getUTCMonth() - (month - 1);
    if (elapsedMonth < 0) {
      elapsedYear--;
      elapsedMonth += 12;
    }
    return `築${elapsedYear}年`;
  };

  return (
    <>
      <div className="recommend_image">
        {videoUrl ? (
          <VideoPlayer
            src={videoUrl}
            playerRef={playRef}
            playFlg={false}
            audioRef={audioRef}
            handleEndCatch={wrapHandleEndCatch}
            myNumber={myNumber}
            audioSrc={RandomBgm(VideoBgm)}
          />
        ) : (
          <img src={comingSoon} />
        )}
      </div>
      <div className="recommend_balloon">
        <div className="recommend_rent">
          <span className="recommend_rent_large">
            {contracts[0]?.rent ? (
              <span>{(contracts[0].rent / 10000).toFixed(1)}</span>
            ) : (
              " お問い合わせください "
            )}
          </span>
          {contracts[0]?.rent && "万円"}
          （管理費
          <span>
            {contracts[0]?.management_fee ? (
              <>{contracts[0]?.management_fee.toLocaleString()}</>
            ) : (
              " "
            )}
          </span>
          円）
        </div>
        <div className="recommend_room">{property.name}</div>
        <div className="recommend_overview">
          {property_accesses[0]?.station_master.station_line_master.name}{" "}
          {property_accesses.length > 1 && "他"}{" "}
          {property_accesses.length > 1 && "/"}
          {property_accesses[0]?.station_master.name}
          {property_accesses[0]?.station_master.name && "駅"}{" "}
          {property_accesses[0]?.walkby && "徒歩"}
          {property_accesses[0]?.walkby} {property_accesses[0]?.walkby && "分"}{" "}
          {room.layout && "/"} {roomLayoutEnums[room.layout]} {room.size && "/"}{" "}
          {room.size}
          {room.size && "㎡"} {room.floor && "/"} {room.floor}
          {room.floor && "階"} {room.floor && "/"}{" "}
          {elapsedYearsAndMonth(
            property.building_year,
            property.building_month
          )}
        </div>
        <div
          className="recommend_btn btnStyle-fill"
          onClick={() => {
            handleRecommendPropertyModal(
              property,
              room,
              contracts,
              property_accesses,
              room_movie_url,
              property_movie_url
            );
          }}
        >
          このお部屋をもっと見てみる！
        </div>
      </div>
    </>
  );
};

export default Properties;
