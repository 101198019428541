import React from "react";
import Map2 from "./Map2";
import { useSetRecoilState } from "recoil";
import { modalShowState } from "./state";

const MapViewModal = ({ setMapViewModal, property}) => {
  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY_202210;
  const setModalShow = useSetRecoilState(modalShowState);
  return (
    <>
      <div className="PropertiesModal_overlay">
        <div className="PropertiesModalBlack">
          <div className="PropertiesModalBlack_header">
            <div
              className="btnStyle-white"
              onClick={() => {
                setMapViewModal(false);
                setModalShow(true);
              }}
            >
              <img src="/assets/icon/icon-arrow-left.svg" />
              お部屋情報に戻る
            </div>
            {/* <div className="btnStyle-fill">地図アプリを開く</div> */}
            <a
              href={`https://www.google.co.jp/maps/@${property.lat},${property.lon},12z`}
              target="_blank"
              className="btnStyle-fill"
            >
              地図アプリを開く
            </a>
          </div>
          <Map2 googleMapsApiKey={googleMapsApiKey} property={property} />
        </div>
      </div>
    </>
  );
};

export default MapViewModal;
