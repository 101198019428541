<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step  @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
      <div class="contents-box my-2">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold mb-3">部屋に入りましたら、内見スタートです。質問などあればチャットから遠慮なくお聞きください。</h3>
          <p class="font-weight-bold  mb-3 mt-3" style="text-decoration: underline">※内見が終了されましたら必ず内見完了ボタンを押してください。
          内見開始後30分以内に内見終了ボタンが押されていない場合、お電話にてご連絡させて頂く場合がございます。</p>
          <div class="step-flow pb-3" v-if="roomLayoutImageUrl">
            <div class="image-box">
              <vue-pure-lightbox
                style="width: 100%"
                :thumbnail="roomLayoutImageUrl"
                :images="lightbox.images"></vue-pure-lightbox>
              <div class="text-center mb-4" v-if="roomLayoutImageUrl">
                <vue-pure-lightbox
                  style="width: 10%; margin: 0 auto;"
                  :thumbnail="expandImageUrl"
                  :images="lightbox.images"
                ></vue-pure-lightbox>
              </div>
            </div>
          </div>
          <div class="check-list border border-primary p-3 pb-2">
            <h4 class="h6 font-weight-bold">内見時チェックリスト</h4>
            <div class="custom-checkbox custom-control">
              <input class="custom-control-input" type="checkbox" id="flexCheck1-1">
              <label class="custom-control-label" for="flexCheck1-1">
                コンセントの位置を確認した
              </label>
            </div>
            <div class="custom-checkbox custom-control">
              <input class="custom-control-input" type="checkbox" id="flexCheck1-2">
              <label class="custom-control-label" for="flexCheck1-2">
                水回りを確認した
              </label>
            </div>
            <div class="custom-checkbox custom-control">
              <input class="custom-control-input" type="checkbox" id="flexCheck1-3">
              <label class="custom-control-label" for="flexCheck1-3">
                カーテンのサイズを確認した
              </label>
            </div>
            <div class="custom-checkbox custom-control">
              <input class="custom-control-input" type="checkbox" id="flexCheck1-4">
              <label class="custom-control-label" for="flexCheck1-4">
                収納を確認した
              </label>
            </div>
          </div>
        </div>
      </div>

      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}-2</h2>
      <div class="contents-box my-2">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold mb-3">内見が終了したら、戸締まりをしてお部屋から出ます。</h3>
          <div class="check-list border border-primary p-3 pb-2">
            <h4 class="h6 font-weight-bold mb-0">内見終了時チェックリスト</h4>
            <p class='mb-3'>
              (全てのチェックが完了すると、次のステップへと進めます)
            </p>
            <div class="custom-checkbox custom-control" v-for="(checklist, index) in checklists" :key="index">
              <input class="custom-control-input" type="checkbox" :id="'checkbox' + index" :value="'checkbox' + index" v-model="checked"  @change="onListChecked()">
              <label class="custom-control-label" :for="'checkbox' + index">
                {{checklist.text}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" btnText="内見終了"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
import VuePureLightbox from 'vue-pure-lightbox'
import styles from 'vue-pure-lightbox/dist/VuePureLightbox.css'
import axios from 'axios'

export default {
  name: 'Viewing',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome, VuePureLightbox},
  data() {
    return {
      checklists: [],
      checked: [],
      lightbox: {
        images: [this.roomLayoutImageUrl]
      },
    }
  },
  mounted() {
    this.$store.commit('flowDetail/setProceedable', false)
    this.lightbox.images = [this.roomLayoutImageUrl]
    this.fetchViewingFinishChecklists()
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep', 'reservationToken']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('room', ['roomLayoutImageUrl']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    },
    haveLayoutImage() {
      return this.roomLayoutImageUrl != null
    },
    proceedable() {
      return this.checklists.length == this.checked.length
    },
    expandImageUrl() {
      return 'https://itsudemo-naiken-production.s3.ap-northeast-1.amazonaws.com/images/expand.png'
    },
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    },
    onListChecked() {
      if(this.proceedable){
        this.$store.commit('flowDetail/setProceedable', true)
      }else{
        this.$store.commit('flowDetail/setProceedable', false)
      }
    },
    async fetchViewingFinishChecklists(){
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}/checklists`,
          { params: { scene: 'viewing_finish' } })
        this.checklists = res.data.checklists
      } catch {
        alert('ERROR');
      }
    },
  }
}
</script>

<style scoped>
.lightbox__thumbnail img:hover{
  cursor: zoom-in;
}
</style>


