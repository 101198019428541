import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { ContactInput } from "./ContactInput"
import { ContactConfirm } from "./ContactConfirm";
import { ContactComplete } from "./ContactComplete";

const defaultData = {
  name: "",
  name_kana: "",
  email: "",
  message: "",
};

const steps = [{ id: "input" }, { id: "confirm" }, { id: "complete" }];

export default function Contact(props) {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const stepData = { formData, setForm, navigation };

  switch (step.id) {
    case "input":
      return (
        <>
          <ContactInput {...stepData} props={props} />
        </>
      );
    case "confirm":
      return (
        <>
          <ContactConfirm {...stepData} props={props} />
        </>
      );
    case "complete":
      return (
        <>
          <ContactComplete {...stepData} props={props} />
        </>
      );
  }

  return <div></div>;
}
