import React, { Fragment, FunctionComponent, useEffect, useState  } from 'react'

const createCalendar = (year, month) => {
  const first = new Date(year, month - 1, 1).getDay()

  const last = new Date(year, month, 0).getDate()

  return [0, 1, 2, 3, 4, 5].map((weekIndex) => {
    return [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
      const day = dayIndex + 1 + weekIndex * 7
      return day - 1 < first || last < day - first ? null : day - first
    })
  })
}

const ReservationCalender = ({day,head,props})=> {
  const [year, setYear] = useState(new Date().getFullYear())

  const [month, setMonth] = useState(new Date().getMonth() + 1)

  const calendar = createCalendar(year, month)


  const onClick = (n) => () => {
    const nextMonth = month + n
    if (12 < nextMonth) {
      setMonth(1)
      setYear(year + 1)
    } else if (nextMonth < 1) {
      setMonth(12)
      setYear(year - 1)
    } else {
      setMonth(nextMonth)
    }
  }


  // useEffect(() => {
  //     fetch('/reservations/room_id.json', {method: 'GET'})
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data)
  //         setPosts(data)
  //     })
  // },[])



  // ステート変数 openを更新するためのsetOpen 関数を宣言します。初期値は"false"
const [isOpen, setOpen] = useState(false);

// clickイベントを発火させる関数
// 発火によりopenを"true"へ
const timeDateTrigger = (j) => {
  setOpen(!isOpen);
  console.log(j)
}


  const yearHead = ['日','月','火','水','木','金','土',]

  const HourData = [
    '00:00 - 01:00',
    '01:00 - 02:00',
    '02:00 - 03:00',
    '03:00 - 04:00',
    '04:00 - 05:00',
    '05:00 - 06:00',
    '06:00 - 07:00',
    '07:00 - 08:00',
    '08:00 - 09:00',
    '09:00 - 10:00',
    '10:00 - 11:00',
    '11:00 - 12:00',
    '12:00 - 13:00',
    '13:00 - 14:00',
    '14:00 - 15:00',
    '15:00 - 16:00',
    '16:00 - 17:00',
    '17:00 - 18:00',
    '18:00 - 19:00',
    '19:00 - 20:00',
    '20:00 - 21:00',
    '21:00 - 22:00',
    '22:00 - 23:00',
    '23:00 - 24:00',
  ]


  const getBackgroundColor =(j)=> {
    if (j === 0) {
        return 'rgba(255,0,0,.1)';
    }
    if (j === 6) {
        return 'rgba(0,0,255,.1)';
    }
    return '';
  }

  return (
    <Fragment>
      <h1>{`${year}年${month}月`}</h1>
      <div>
        <button onClick={onClick(-1)}>{'prev'}</button>
        <button onClick={onClick(1)}>{'next'}</button>
      </div>
      <table className='m-auto w-11/12'>
        <thead>
          <tr>
            {yearHead.map((head,i)=> (
              <th key={i} className='text-s text-center '><p>{head}</p></th>
            ))}
          </tr>
        </thead>
        <tbody>
          
          {calendar.map((week, i) => (
            <tr key={week.join('')} className='text-s text-center border border-gray-200'>
              {week.map((day, j) => (
                <td
                  key={`${i}${j}`}
                  onClick={()=>timeDateTrigger(day)}
                  className='border border-gray-200'
                  style={{ 'backgroundColor': getBackgroundColor(j) }}>
                    <p
                      className='font-semibold p-2'>{day}</p></td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
      <ul
        className='mt-2 flex m-auto w-11/12 flex-wrap'
      >
        {HourData.map((head,i)=> (
          <li
            key={i}
            onClick={()=>timeDateTrigger(head)}
            className='text-s text-center w-3/12 p-2 pt-3 pb-3 border border-gray-200'>
              <p>{head}</p></li>
        ))}
      </ul>        

      </div>
    </Fragment>
  )
}


export default ReservationCalender