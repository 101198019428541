import React from "react";
import CheckBox from "./CheckBox";

const Structure = ({ values, handleSelectValues, rooms_structure_in }) => {
  const rooms_structure_prettier =
    rooms_structure_in?.structure_in &&
    rooms_structure_in?.structure_in.map((a) => {
      return Number(a);
    });

  const include_1 = rooms_structure_prettier?.filter((output, index) => {
    return output === 1;
  });
  const include_4 = rooms_structure_prettier?.filter((output, index) => {
    return output === 4;
  });
  const include_5 = rooms_structure_prettier?.filter((output, index) => {
    return output === 5;
  });
  const include_14 = rooms_structure_prettier?.filter((output, index) => {
    return output === 14;
  });
  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">構造</div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"reinforcingBar"}
            name="detail[structure_in][]"
            value={5}
            // checked={values.reinforcingBar}
            defaultChecked={include_5 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"reinforcingBar"}>
            鉄筋系
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"steelFrame"}
            name="detail[structure_in][]"
            value={4}
            // checked={values.steelFrame}
            defaultChecked={include_4 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"steelFrame"}>
            鉄骨系
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"wooden"}
            name="detail[structure_in][]"
            value={1}
            // checked={values.wooden}
            defaultChecked={include_1 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"wooden"}>
            木造
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"block"}
            name="detail[structure_in][]"
            value={14}
            // checked={values.block}
            defaultChecked={include_14 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"block"}>
            ブロック・その他
          </label>
        </li>
      </ul>
    </div>
  );
};
export default Structure;
