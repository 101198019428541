import React from "react";
import CheckBox from "./CheckBox";

const BuildingType = ({ values, handleSelectValues, rooms_building_in }) => {
  const rooms_building_prettier =
    rooms_building_in?.building_in &&
    rooms_building_in?.building_in.map((a) => {
      return Number(a);
    });

  const include_1 = rooms_building_prettier?.filter((output, index) => {
    return output === 1;
  });
  const include_2 = rooms_building_prettier?.filter((output, index) => {
    return output === 2;
  });
  const include_3 = rooms_building_prettier?.filter((output, index) => {
    return output === 3;
  });
  
  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">建物種別</div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"largeApartment"}
            name="detail[building_in][]"
            value={1}
            defaultChecked={include_1 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"largeApartment"}>
            マンション
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"apartment"}
            name="detail[building_in][]"
            value={2}
            defaultChecked={include_2 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"apartment"}>
            アパート
          </label>
        </li>
        <li className="searchRent_item col-100 formStyle_check">
          <input
            type="checkbox"
            id={"detachedHouse"}
            name="detail[building_in][]"
            value={3}
            defaultChecked={include_3 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"detachedHouse"}>
            一戸建て・その他
          </label>
        </li>
      </ul>
    </div>
  );
};

export default BuildingType;
