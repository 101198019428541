import React from "react";
import { useSetRecoilState } from "recoil";
import Map2 from "./Map2";
import { modalShowState, streetViewShowState } from "./state";

const StreetViewModal = (props) => {
  const setShowStreetView = useSetRecoilState(streetViewShowState);
  const setModalShow = useSetRecoilState(modalShowState);
  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY_202210;

  return (
    <>
      <div className="PropertiesModal_overlay street-view">
        <div className="PropertiesModalBlack">
          <div className="PropertiesModalBlack_header">
            <div
              className="btnStyle-white"
              onClick={() => {
                setShowStreetView(false);
                setModalShow(true);
              }}
            >
              <img src="/assets/icon/icon-arrow-left.svg" />
              お部屋情報に戻る
            </div>
            <a
              href={`https://www.google.co.jp/maps/@${props.property.lat},${props.property.lon},12z`}
              target="_blank"
              className="btnStyle-fill"
            >
              地図アプリを開く
            </a>
          </div>
          <Map2
            googleMapsApiKey={googleMapsApiKey}
            property={props.property}
            isStreetView={true}
          />
        </div>
      </div>
    </>
  );
};

export default StreetViewModal;
