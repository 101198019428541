import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { SelfInput } from "./ReservationForm/SelfInput";
import { SelfConfirm } from "./ReservationForm/SelfConfirm";
import { SelfComplete } from "./ReservationForm/SelfComplete";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

const defaultData = {
  id: "",
  date_time: "",
  date: "",
  time: "",
  first_name: "",
  last_name: "",
  tel: "",
  email: "",
};

const steps = [{ id: "input" }, { id: "confirm" }, { id: "complete" }];

export default function SalesReservationForm({ props, reserved }) {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const stepData = { formData, setForm, navigation };

  switch (step.id) {
    case "input":
      return (
        <>
          <Stepper activeStep={0} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SelfInput {...stepData} props={props} reserved={reserved} />
        </>
      );
    case "confirm":
      return (
        <>
          <Stepper activeStep={1} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SelfConfirm {...stepData} props={props} />
        </>
      );
    case "complete":
      return (
        <>
          <Stepper activeStep={2} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SelfComplete {...stepData} props={props} />
        </>
      );
  }

  return <div></div>;
}
