import React from "react";
import Reservation_modal from "./Reservation_modal";

const Reservation = (props) => {
  return (
    <>
      <p>{props.head}</p>
      <Reservation_modal props={props} />
    </>
  );
};

export default Reservation;
