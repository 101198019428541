import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { Autocomplete } from "@react-google-maps/api";
import { centerState, placeChangedState } from "./state";

const SearchForm = () => {
  const setCenter = useSetRecoilState(centerState);
  const setPlaceChanged = useSetRecoilState(placeChangedState);
  const [autocomplete, setAutocomplete] = useState(null);
  const onLoad = (autocomplete) => setAutocomplete(autocomplete);

  const getCenterGeocode = async (place) => {
    let center = {};
    if (place.geometry) {
      center.lat = Number(place.geometry.location.lat());
      center.lng = Number(place.geometry.location.lng());
      return center;
    }

    const autocomleteService = new google.maps.places.AutocompleteService();
    const placePredictions = await autocomleteService.getPlacePredictions({ input: place.name, });
    const placeId = placePredictions?.predictions[0]?.place_id;
    if (!placeId) return null;

    const geocoder = new google.maps.Geocoder()
    const geocode = await geocoder.geocode({ placeId });
    center.lat = Number(geocode.results[0].geometry.location.lat());
    center.lng = Number(geocode.results[0].geometry.location.lng());

    return center;
  };

  const onPlaceChanged = () => {
    (async () => {
      const place = autocomplete.getPlace();
      const center = await getCenterGeocode(place);
      if (!center) return;
      setCenter(center);
      setPlaceChanged(place);
    })();
  };

  const autocompleteOptions = {
    types: ['(regions)'],
    fields: ["formatted_address", "name", "geometry"],
    componentRestrictions: {
      country: ['jp']
    }
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      options={autocompleteOptions}
    >
      <input
        type="text"
        placeholder="キーワードを入力"
        style={{
          boxSizing: "border-box",
          border: "1px solid transparent",
          width: "190px",
          height: "32px",
          padding: "0 12px",
          borderRadius: "3px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
          fontSize: "14px",
          outline: "none",
          textOverflow: "ellipses",
          position: "absolute",
          top: "1%",
          left: "15%",
          marginLeft: "-40px"
        }}
      />
    </Autocomplete>
  )
}

export default SearchForm;
