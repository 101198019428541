import { atom, selector } from "recoil";

export const showAreaSearchState = atom({
  key: "showAreaSearchState",
  default: false,
});

export const showStationSearchState = atom({
  key: "showStationSearchState",
  default: false,
});

export const showDetailConditionsState = atom({
  key: "showDetailConditionsState",
  default: false,
});

export const showMapSearchState = atom({
  key: "showMapSearchState",
  default: false,
});

export const showTopState = selector({
  key: "showTopState",
  get: ({ get }) => {
    if (get(showAreaSearchState) ||
      get(showStationSearchState) ||
      get(showDetailConditionsState) ||
      get(showMapSearchState)) {
      return false
    } else {
      return true
    }
  },
});

export const areaIdentifierState = atom({
  key: "areaIdentifierState",
  default: [],
});

export const areaIdentifier = selector({
  key: "areaIdentifier",
  get: ({ get }) => {
    return get(areaIdentifierState);
  },
});

export const prefectureState = atom({
  key: "prefectureState",
  default: [],
});

export const prefecture = selector({
  key: "prefecture",
  get: ({ get }) => {
    return get(prefectureState);
  },
});

export const areaState = atom({
  key: "areaState",
  default: {},
});

export const area = selector({
  key: "area",
  get: ({ get }) => get(areaState),
});

export const cityGroupState = atom({
  key: "cityGroupState",
  default: {},
});

export const cityGroup = selector({
  key: "cityGroup",
  get: ({ get }) => {
    return get(cityGroupState);
  },
});

export const cityState = atom({
  key: "cityState",
  default: [],
});

export const city = selector({
  key: "city",
  get: ({ get }) => {
    return get(cityState);
  },
});

export const stationResultState = atom({
  key: "stationResultState",
  default: "",
});

export const stationResult = selector({
  key: "stationResults",
  get: ({ get }) => {
    return get(stationResultState);
  },
});
