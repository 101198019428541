import React, { useEffect, useState } from "react";
import axios from "axios";
import { RecoilRoot, useRecoilValue } from "recoil";
import AreaSearch from "./top/AreaSearch";
import StationSearch from "./top/StationSearch";

import {
  showTopState,
  showAreaSearchState,
  showStationSearchState,
  showDetailConditionsState,
  showMapSearchState,
} from "./top/state";
import Top from "./top/Top";
import MapSearch from "./top/MapSearch";

const TopPage = (props) => {
  return (
    <RecoilRoot>
      <Main tikTokVideos={props.tikTokVideos} stationRoutes={props.stationRoutes} />
    </RecoilRoot>
  );
};

const Main = (props) => {
  const isShowTop = useRecoilValue(showTopState);
  const isShowAreaSearch = useRecoilValue(showAreaSearchState);
  const isShowStationSearch = useRecoilValue(showStationSearchState);
  const isShowDetailConditionsSearch = useRecoilValue(
    showDetailConditionsState
  );
  const isShowMapSearch = useRecoilValue(showMapSearchState);
  const [oheyaGirlVideos, setOheyaGirlVideos] = useState([]);
  useEffect(() => {
    (async () => {
      await axios.get("/api/portal/oheya_girl_videos.json").then((resp) => {
        setOheyaGirlVideos(resp.data);
      });
    })();
  }, []);

  return (
    <>
      {isShowTop && <Top tikTokVideos={props.tikTokVideos} oheyaGirlVideos={oheyaGirlVideos}/>}
      {isShowAreaSearch && <AreaSearch />}
      {isShowStationSearch && <StationSearch stationRoutes={props.stationRoutes} />}
      {isShowMapSearch && <MapSearch />}
    </>
  );
};

export default TopPage;
