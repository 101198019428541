import React, { useEffect, useState } from "react";
import axios from "axios";
import MapContent from "./PropertiesSearch/MapContent";
import Modal from "./PropertiesSearch/Modal";
import Property from "./PropertiesSearch/Property";
import ChangeConditions from "./PropertiesSearch/ChangeConditions";
import AreaSearch from "./top/AreaSearch";
import StationSearch from "./top/StationSearch";
import DetailConditions from "./PropertiesSearch/DetailConditions";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import {
  cityState,
  isShowModal,
  propertiesState,
} from "./PropertiesSearch/state";
import { PropertiesApiPath } from "./PropertiesSearch/common";
import iconMapPin from "../../../assets/images/icon/icon-map-pin.svg";
import iconTodo from "../../../assets/images/icon/icon-todo.svg";
import iconAuto from "../../../assets/images/icon/icon-autorenew.svg";
import iconDl from "../../../assets/images/icon/icon-download-2.svg";
import iconDlGreen from "../../../assets/images/icon/icon-download-2-green.svg";
import iconMap from "../../../assets/images/icon/icon-map.svg";
import iconArrowLeft from "../../../assets/images/icon/icon-arrow-left-black.svg";
import iconArrowRight from "../../../assets/images/icon/icon-arrow-right-black.svg";
import iconClose from "../../../assets/images/icon/icon-close.svg";
import iconSelect from "../../../assets/images/icon/icon_unfold_more.svg";
import iconTrain from "../../../assets/images/top/icon_train_36x36.svg";

import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import {
  showAreaSearchState,
  showDetailConditionsState,
  showStationSearchState,
} from "./top/state";

const PropertiesSearchPage = (props) => {
  return (
    <RecoilRoot>
      <PropertiesSearch
        city_ids={props.city_ids}
        station_master={props.station_master}
        detail={props.detail}
        south_west_lat={props.south_west_lat}
        north_east_lat={props.north_east_lat}
        south_west_lon={props.south_west_lon}
        north_east_lon={props.north_east_lon}
        stationRoutes={props.stationRoutes}
      />
    </RecoilRoot>
  );
};

const PropertiesSearch = (props) => {
  const citiesStorageName = "city_ids";
  const staionsStorageName = "station_master";
  const detailsStorageName = "detail";

  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY_202210;
  const isModalShow = useRecoilValue(isShowModal);
  const [properties, setProperties] = useRecoilState(propertiesState);
  const [cities, setCities] = useRecoilState(cityState);
  const [isShowChangeConditions, setIsShowChangeConditions] = useState(false);
  const [isShowAreaSearch, setIsShowAreaSearch] =
    useRecoilState(showAreaSearchState);
  const [isShowStationSearch, setIsShowStationSearch] = useRecoilState(
    showStationSearchState
  );
  const [isShowDetailConditions, setIsShowDetailConditions] = useRecoilState(
    showDetailConditionsState
  );
  const [isShowMap, setIsShowMap] = useState(false);
  const [isSaveConditions, setIsSaveConditions] = useState(false);
  const [stationNames, setStationNames] = useState([]);
  const [detailNames, setDetailNames] = useState("");
  const [saveStationCondition, setSaveStationConditions] = useState([]);
  const [saveCitiesCondition, setSaveCitiesConditions] = useState([]);
  const [selectStation, setSelectStation] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState(0);
  useEffect(() => {
    (async () => {
      let queryStr = `page=${currentPage}`;

      const citiesStorage = sessionStorage.getItem(citiesStorageName);
      const stationsStorage = sessionStorage.getItem(staionsStorageName);
      const detailStorage = sessionStorage.getItem(detailsStorageName);
      // クエリパラメータ作成
      // 地域
      if (props.city_ids) {
        const joinStr = queryStr === "" ? "" : "&";
        queryStr +=
          joinStr +
          [...new Set(props.city_ids)]
            .map((id) => `city_master_ids[]=${id}`)
            .join("&");
      } else if (citiesStorage && citiesStorage !== "null") {
        const city_ids = citiesStorage.split(",");
        const joinStr = queryStr === "" ? "" : "&";
        queryStr +=
          joinStr +
          [...new Set(city_ids)]
            .map((id) => `city_master_ids[]=${id}`)
            .join("&");
      }
      // 駅・路線
      if (props.station_master) {
        props.station_master.forEach((obj) => {
          const joinStr = queryStr === "" ? "" : "&";
          queryStr += joinStr + `station_master[][line]=${obj.line}`;
          if (obj.station) {
            queryStr +=
              "&" +
              obj.station
                .map((v) => `station_master[][station][]=${v}`)
                .join("&");
          }
        });
      } else if (stationsStorage && stationsStorage !== "null") {
        const station_master = JSON.parse(stationsStorage);
        station_master.forEach((obj) => {
          const joinStr = queryStr === "" ? "" : "&";
          queryStr += joinStr + `station_master[][line]=${obj.line}`;
          if (obj.station) {
            queryStr +=
              "&" +
              obj.station
                .map((v) => `station_master[][station][]=${v}`)
                .join("&");
          }
        });
      }
      // 詳細
      if (props.detail) {
        Object.keys(props.detail).forEach(function (key) {
          if (Array.isArray(this[key])) {
            this[key].forEach(function (value) {
              queryStr += `&detail[${key}][]=${value}`;
            });
          } else {
            queryStr += `&detail[${key}]=${this[key]}`;
          }
        }, props.detail);
      } else if (detailStorage && detailStorage !== "null") {
        const detail = JSON.parse(detailStorage);
        Object.keys(detail).forEach(function (key) {
          if (Array.isArray(this[key])) {
            this[key].forEach(function (value) {
              queryStr += `&detail[${key}][]=${value}`;
            });
          } else {
            queryStr += `&detail[${key}]=${this[key]}`;
          }
        }, detail);
      }

      if (
        props.south_west_lat &&
        props.north_east_lat &&
        props.south_west_lon &&
        props.north_east_lon
      ) {
        queryStr += `&south_west_lat=${props.south_west_lat}&north_east_lat=${props.north_east_lat}&south_west_lon=${props.south_west_lon}&north_east_lon=${props.north_east_lon}`;
      }

      if (sort) {
        queryStr += `&sort=${sort}`
      }

      // ブラウザバック用にpropsの値をsession storageへ保存
      if (props.city_ids || props.station_master || props.detail) {
        props.city_ids && sessionStorage.setItem("city_ids", props.city_ids.join());
        props.station_master && sessionStorage.setItem("station_master", JSON.stringify(props.station_master));
        props.detail && sessionStorage.setItem("detail", JSON.stringify(props.detail));
      }
      axios.get(PropertiesApiPath + "?" + queryStr).then(async res =>  {
        setTotalPage(res.data.total_pages);
        setTotalCount(res.data.total_count);
        setProperties(res.data.properties)
        await setConditionsName();
      })
      const arrayStation = [];
      props.station_master &&
        props.station_master.map((line) => {
          line.station.map((stationId) =>
            arrayStation.push({
              line_id: parseInt(line.line),
              id: parseInt(stationId),
            })
          );
        });
      stationsStorage && JSON.parse(stationsStorage).map((line) => {
        line.station.map((stationId) =>
          arrayStation.push({
            line_id: parseInt(line.line),
            id: parseInt(stationId),
          })
        );
      });
      setSelectStation(arrayStation);
    })();
  }, [sort, currentPage]);

  // useEffect(() => {
  //   const body = document.querySelector("body");
  //   body.style.overflow = isShowMap ? "hidden" : "auto";
  // }, [isShowMap]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isModalShow ? "hidden" : "auto";
  }, [isModalShow]);

  const setConditionsName = async () => {
    // 名称検索
    const ret = await getConditionNames();
    const spilitStr = "";
    const connectStr = "、";

    // 駅・路線名の表示文字列整形
    const resStations = ret.stations;
    let stations = [];
    let stations2 = [];
    if (resStations.length !== 0) {
      let line = resStations[0].station_line_name;
      let stationsStr = line + spilitStr;
      for (let i = 0; i < resStations.length; i++) {
        // 路線ごとの判定
        if (line !== resStations[i].station_line_name) {
          stations.push(stationsStr.slice(0, -1));
          line = resStations[i].station_line_name;
          stationsStr = line + spilitStr;
        }
        stationsStr += resStations[i].station_name + connectStr;
      }
      stations.push(stationsStr.slice(0, -1));
      stations2 = shapeStations(resStations);
    }
    setStationNames(stations);
    setSaveStationConditions(stations2);
    // 地域の表示文字列整形
    const resCities = ret.cities;
    let cities = [];
    let cities2 = [];
    if (resCities.length !== 0) {
      let prefecture = resCities[0].prefecture_name;
      let citiesStr = prefecture + spilitStr;
      for (let i = 0; i < resCities.length; i++) {
        // 都道府県ごとの判定
        if (prefecture !== resCities[i].prefecture_name) {
          cities.push(citiesStr.slice(0, -1));
          prefecture = resCities[i].prefecture_name;
          citiesStr = prefecture + spilitStr;
        }
        citiesStr += resCities[i].city_name + connectStr;
      }
      cities.push(citiesStr.slice(0, -1));
      cities2 = shapeCities(resCities);
    }
    setCities(cities);
    setSaveCitiesConditions(cities2);

    // 駅・路線と地域の検索結果が0件の場合タグから遷移と判定
    if (resStations.length === 0 && resCities.length == 0) {
      const detailStorage = JSON.parse(
        sessionStorage.getItem(detailsStorageName)
      );

      if (
        detailStorage &&
        detailStorage !== "null" &&
        detailStorage.features_name_in
      ) {
        setDetailNames(detailStorage.features_name_in);
      }
    }
  };

  /**
   * 検索条件の名称検索処理
   */
  const getConditionNames = async () => {
    const citiesStorage = sessionStorage.getItem(citiesStorageName);
    const stationsStorage = sessionStorage.getItem(staionsStorageName);
    if (
      (!citiesStorage || citiesStorage === "null") &&
      (!stationsStorage || stationsStorage === "null")
    ) {
      return {
        cities: [],
        stations: [],
      };
    }
    let citys = "city_ids=";
    if (citiesStorage && citiesStorage !== "null") {
      citys += citiesStorage;
    }
    let stations = "station_ids=";
    const stationsObj = JSON.parse(stationsStorage);
    if (stationsObj && stationsObj !== "null") {
      for (let i = 0; i < stationsObj.length; i++) {
        if (stationsObj[i].station) {
          stations += stationsObj[i].station.join(",") + ",";
        }
      }
    }

    const getSearchesURL = "/api/portal/save_searches?";
    const ret = await axios.get(getSearchesURL + citys + "&" + stations);
    return ret.data;
  };

  const handleShowChangeConditions = (value) => {
    setIsShowChangeConditions(value);
  };
  const handleShowAreaSearch = () => {
    setIsShowChangeConditions(false);
    setIsShowAreaSearch(true);
  };
  const handleShowStationSearch = () => {
    setIsShowChangeConditions(false);
    setIsShowStationSearch(true);
  };

  const handleShowDetailConditions = () => {
    setIsShowChangeConditions(false);
    setIsShowDetailConditions(true);
  };

  const handleShowMap = () => {
    setIsShowMap(!isShowMap);
  };

  const showDetailSetting = () => {
    const connectStr = "、";
    let detailStr = "";
    const detailObjct = sessionStorage.getItem(detailsStorageName);
    const detailObj_arr = JSON.parse(detailObjct);
    const detailObj = props.detail || detailObj_arr;
    //こだわり条件
    if (detailObj?.features_name_in) {
      detailStr += detailObj.features_name_in.join(connectStr);
    }
    //共益費/管理費
    if (detailObj?.contracts_management_fee) {
      detailStr += (detailStr === "" ? "" : connectStr) + "共益費/管理費含む";
    }
    //駐車場料金
    if (detailObj?.have_bicycle_parking_eq) {
      detailStr += (detailStr === "" ? "" : connectStr) + "駐車場料金含む";
    }
    //礼金
    if (detailObj?.contracts_reikin_blank) {
      detailStr += (detailStr === "" ? "" : connectStr) + "礼金なし";
    }
    //敷金/保証金
    if (detailObj?.contracts_shikikin_blank) {
      detailStr += (detailStr === "" ? "" : connectStr) + "敷金/保証金なし";
    }
    //初期費用カード決済
    if (detailObj?.initialCostPayment_blank) {
      detailStr +=
        (detailStr === "" ? "" : connectStr) + "初期費用カード決済可";
    }
    //家賃カード決済
    if (detailObj?.rentCostPayment_blank) {
      detailStr += (detailStr === "" ? "" : connectStr) + "家賃カード決済可";
    }
    //駅徒歩
    if (detailObj?.property_accesses_walkby_lteq) {
      const walkStr =
        detailObj?.property_accesses_walkby_lteq === "0"
          ? "指定なし"
          : detailObj?.property_accesses_walkby_lteq + "分以内";

      detailStr += (detailStr === "" ? "" : connectStr) + walkStr;
    }
    //構造
    if (detailObj?.structure_in) {
      const sructures = [
        { key: 1, value: "木造" },
        { key: 4, value: "鉄骨系" },
        { key: 5, value: "鉄筋系" },
        { key: 14, value: "ブロック、その他" },
      ];
      detailObj?.structure_in.map((v) => {
        const sructure = sructures.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + sructure.value;
      });
    }
    //間取りタイプ
    if (detailObj?.rooms_layout_in) {
      const floorPlans = [
        { key: 1, value: "ワンルーム" },
        { key: 2, value: "1K" },
        { key: 3, value: "1DK" },
        { key: 13, value: "1LDK" },
        { key: 4, value: "2K" },
        { key: 5, value: "2DK" },
        { key: 6, value: "2LDK" },
        { key: 7, value: "3K" },
        { key: 8, value: "3DK" },
        { key: 9, value: "3LDK" },
        { key: 10, value: "4K" },
        { key: 11, value: "4DK" },
        { key: 12, value: "4LDK以上" },
      ];
      detailObj?.rooms_layout_in.map((v) => {
        const floorPlan = floorPlans.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + floorPlan.value;
      });
    }

    //建物種別
    if (detailObj?.building_in) {
      const buildings = [
        { key: 1, value: "マンション" },
        { key: 2, value: "アパート" },
        { key: 3, value: "一戸建て・その他" },
      ];
      detailObj?.building_in.map((v) => {
        const building = buildings.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + building.value;
      });
    }

    //賃料下限
    if (detailObj?.contracts_rent_gteq) {
      const contracts_rent_mins = [
        { key: 0, value: "下限なし" },
        { key: 10000, value: "1万" },
        { key: 20000, value: "2万" },
        { key: 30000, value: "3万" },
        { key: 35000, value: "3.5万" },
        { key: 40000, value: "4万" },
        { key: 45000, value: "4.5万" },
        { key: 50000, value: "5万" },
        { key: 55000, value: "5.5万" },
        { key: 60000, value: "6万" },
        { key: 65000, value: "6.5万" },
        { key: 70000, value: "7万" },
        { key: 75000, value: "7.5万" },
        { key: 80000, value: "8万" },
        { key: 85000, value: "8.5万" },
        { key: 90000, value: "9万" },
        { key: 95000, value: "9.5万" },
        { key: 100000, value: "10万" },
        { key: 105000, value: "10.5万" },
        { key: 110000, value: "11万" },
        { key: 115000, value: "11.5万" },
        { key: 120000, value: "12万" },
        { key: 125000, value: "12.5万" },
        { key: 130000, value: "13万" },
        { key: 135000, value: "13.5万" },
        { key: 140000, value: "14万" },
        { key: 145000, value: "14.5万" },
        { key: 150000, value: "15万" },
        { key: 155000, value: "15.5万" },
        { key: 160000, value: "16万" },
        { key: 165000, value: "16.5万" },
        { key: 170000, value: "17万" },
        { key: 175000, value: "17.5万" },
        { key: 180000, value: "18万" },
        { key: 185000, value: "18.5万" },
        { key: 190000, value: "19万" },
        { key: 195000, value: "19.5万" },
        { key: 200000, value: "20万" },
        { key: 210000, value: "21万" },
        { key: 220000, value: "22万" },
        { key: 230000, value: "23万" },
        { key: 240000, value: "24万" },
        { key: 250000, value: "25万" },
        { key: 260000, value: "26万" },
        { key: 270000, value: "27万" },
        { key: 280000, value: "28万" },
        { key: 290000, value: "29万" },
        { key: 300000, value: "30万" },
        { key: 350000, value: "35万" },
        { key: 400000, value: "40万" },
        { key: 500000, value: "50万" },
        { key: 1000000, value: "100万" },
      ];
      const contracts_rent_min = contracts_rent_mins.find(
        (obj) => obj.key == detailObj.contracts_rent_gteq
      );
      if (contracts_rent_min.key >= 10000) {
        detailStr +=
          (detailStr === "" ? "" : connectStr) +
          contracts_rent_min.value +
          "円以上";
      }
    }

    //賃料上限
    if (detailObj?.contracts_rent_lteq) {
      const contracts_rent_maxs = [
        { key: 0, value: "上限なし" },
        { key: 10000, value: "1万" },
        { key: 20000, value: "2万" },
        { key: 30000, value: "3万" },
        { key: 35000, value: "3.5万" },
        { key: 40000, value: "4万" },
        { key: 45000, value: "4.5万" },
        { key: 50000, value: "5万" },
        { key: 55000, value: "5.5万" },
        { key: 60000, value: "6万" },
        { key: 65000, value: "6.5万" },
        { key: 70000, value: "7万" },
        { key: 75000, value: "7.5万" },
        { key: 80000, value: "8万" },
        { key: 85000, value: "8.5万" },
        { key: 90000, value: "9万" },
        { key: 95000, value: "9.5万" },
        { key: 100000, value: "10万" },
        { key: 105000, value: "10.5万" },
        { key: 110000, value: "11万" },
        { key: 115000, value: "11.5万" },
        { key: 120000, value: "12万" },
        { key: 125000, value: "12.5万" },
        { key: 130000, value: "13万" },
        { key: 135000, value: "13.5万" },
        { key: 140000, value: "14万" },
        { key: 145000, value: "14.5万" },
        { key: 150000, value: "15万" },
        { key: 155000, value: "15.5万" },
        { key: 160000, value: "16万" },
        { key: 165000, value: "16.5万" },
        { key: 170000, value: "17万" },
        { key: 175000, value: "17.5万" },
        { key: 180000, value: "18万" },
        { key: 185000, value: "18.5万" },
        { key: 190000, value: "19万" },
        { key: 195000, value: "19.5万" },
        { key: 200000, value: "20万" },
        { key: 210000, value: "21万" },
        { key: 220000, value: "22万" },
        { key: 230000, value: "23万" },
        { key: 240000, value: "24万" },
        { key: 250000, value: "25万" },
        { key: 260000, value: "26万" },
        { key: 270000, value: "27万" },
        { key: 280000, value: "28万" },
        { key: 290000, value: "29万" },
        { key: 300000, value: "30万" },
        { key: 350000, value: "35万" },
        { key: 400000, value: "40万" },
        { key: 500000, value: "50万" },
        { key: 1000000, value: "100万" },
      ];
      const contracts_rent_max = contracts_rent_maxs.find(
        (obj) => obj.key == detailObj.contracts_rent_lteq
      );
      if (contracts_rent_max.key >= 10000) {
        detailStr +=
          (detailStr === "" ? "" : connectStr) +
          contracts_rent_max.value +
          "円以下";
      }
    }

    return detailStr;
  };

  /**
   * 検索結果の路線・駅の整形処理
   * @param {Object} argStations
   * @returns
   */
  const shapeStations = (argStations) => {
    let stations = [];
    let line = argStations[0].station_line_name;
    let stationsStr = line + " / ";
    for (let i = 0; i < argStations.length; i++) {
      // 路線ごとの判定
      if (line !== argStations[i].station_line_name) {
        stations.push(stationsStr.slice(0, -1));
        line = argStations[i].station_line_name;
        stationsStr = line + " / ";
      }
      stationsStr += argStations[i].station_name + "、";
    }
    stations.push(stationsStr.slice(0, -1));
    return stations;
  };

  /**
   * 検索結果の地域の整形処理
   * @param {*} argCities
   * @returns
   */
  const shapeCities = (argCities) => {
    let cities = [];
    let prefecture = argCities[0].prefecture_name;
    let citiesStr = prefecture + " / ";
    for (let i = 0; i < argCities.length; i++) {
      // 都道府県ごとの判定
      if (prefecture !== argCities[i].prefecture_name) {
        cities.push(citiesStr.slice(0, -1));
        prefecture = argCities[i].prefecture_name;
        citiesStr = prefecture + " / ";
      }
      citiesStr += argCities[i].city_name + "、";
    }
    cities.push(citiesStr.slice(0, -1));
    return cities;
  };

  const saveCondtions = () => {
    const storageName1 = "itudemo_save_condition1";
    const storageName2 = "itudemo_save_condition2";
    const nowDate = new Date();
    const toDay = format(nowDate, "yyyy/MM/dd");
    const saveObj = {
      saveDay: toDay,
      city_ids: props.city_ids
        ? props.city_ids
        : sessionStorage.getItem("city_ids"),
      station_master: props.station_master
        ? props.station_master
        : sessionStorage.getItem("station_master"),
      detail: props.detail ? props.detail : sessionStorage.getItem("detail"),
    };
    if (!localStorage.getItem(storageName1)) {
      localStorage.setItem(storageName1, JSON.stringify(saveObj));
      setIsSaveConditions(true);
      return;
    }
    if (!localStorage.getItem(storageName2)) {
      localStorage.setItem(storageName2, JSON.stringify(saveObj));
      setIsSaveConditions(true);
      return;
    }
    alert("検索条件は2件までです。");
  };
  const handleToDate = (date) => {
    date = new Date(date);
    if (date.getMinutes() < 10) {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " ";
    } else {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " ";
    }

    return date;
  };

  return (
    <>
      {!isShowChangeConditions &&
        !isShowStationSearch &&
        !isShowAreaSearch &&
        !isShowDetailConditions &&
        !isShowMap && (
          <main className="w-full">
            <div className="searchResult">
              <div className="searchResult_breadcrumb">
                <a href="/">トップ</a>
                <span> &gt; </span>
                <span>
                  {cities.join(",") || stationNames.join(",")}
                  {cities.length === 0 && stationNames.length === 0 && "現在地"}
                  の賃貸
                </span>
              </div>
              <h2 className="searchResult_heading">
                {cities.join(",") || stationNames.join(",")}
                {cities.length === 0 && stationNames.length === 0 && "現在地"}
                の賃貸(賃貸マンション・アパート)
              </h2>
              <div className="searchResult_wrapper">
                <div className="searchResult_top">
                  <div className="searchResult_subHeading">検索条件</div>
                  <div className="searchResult_table mt10">
                    <div className="searchResult_tr">
                      <div className="searchResult_th">
                        <img src={iconMapPin} />
                        地域
                      </div>
                      <div className="searchResult_td">{cities.join(",")}</div>
                    </div>
                    <div className="searchResult_tr">
                      <div className="searchResult_th">
                        <img src={iconTrain} />
                        駅・路線
                      </div>
                      <div className="searchResult_td">
                        {stationNames.join(",")}
                      </div>
                    </div>

                    <div className="searchResult_tr">
                      <div className="searchResult_th">
                        <img src={iconTodo} />
                        詳細条件
                      </div>
                      <div className="searchResult_td">
                        {showDetailSetting()}
                      </div>
                    </div>
                  </div>
                  <div className="searchResult_action mt10">
                    <div
                      className="btnStyle-line"
                      onClick={() => {
                        handleShowChangeConditions(true);
                      }}
                    >
                      <img src={iconAuto} className="mr5" />
                      条件変更
                    </div>
                    <div className="btnStyle-fill" onClick={saveCondtions}>
                      <img src={iconDl} className="mr5" />
                      条件保存
                    </div>
                    <div className="btnStyle-fill" onClick={handleShowMap}>
                      <img src={iconMap} className="mr5" />
                      地図表示
                    </div>
                  </div>
                  {/*検索一覧、固定ボタン（スクロールで表示切り替え）*/}
                  <div className="searchResult_action_fixed">
                    <div className="btnStyle-line">
                      <img src={iconAuto} />
                      検索条件を変更
                    </div>
                    <div className="btnStyle-line ml5" onClick={saveCondtions}>
                      <img src={iconDlGreen} />
                      条件保存
                    </div>
                  </div>
                </div>
                <div className="searchResultList">
                  <div className="searchResultList_top">
                    <div className="searchResultList_number">
                      {`検索結果：${totalCount}件`}
                    </div>
                    <div className="searchResultList_select">
                      <img src={iconSelect} />
                      <select
                        id="selectBox"
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option value="0">おすすめ</option>
                        <option value="1">新着順</option>
                        <option value="2">賃料が安い順</option>
                        <option value="3">賃料が高い順</option>
                        <option value="4">築年数が新しい順</option>
                        <option value="5">面積が広い順</option>
                        <option value="6">住所別</option>
                        <option value="7">駅から近い順</option>
                      </select>
                    </div>
                  </div>
                  <div className="searchResultList_list">
                    {properties.length === 0 && (
                      <p>物件が見つかりませんでした</p>
                    )}
                    {properties.map((property) => {
                      return <Property key={property.id} property={property} detail={props.detail} sort={sort}/>;
                    })}
                    <div className="searchResultNation">
                      <div className="searchResultNation_action">
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          pageCount={totalPage}
                          onPageChange={(e) => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                            })
                            setCurrentPage(e.selected + 1)
                          }}
                          containerClassName={"searchResultNation_list"}
                          pageClassName={"searchResultNation_item"}
                          previousLinkClassName={"searchResultNation_arrow"}
                          nextLinkClassName={"searchResultNation_arrow"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                          breakLabel={"..."}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={2}
                        />
                      </div>
                      <div className="searchResultNation_number">
                        <span>{`${
                          (currentPage - 1) * 14 + properties.length
                        }件 /${totalCount}件`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*検索一覧、条件保存モーダル（条件保存時に表示）*/}
            <div
              className={
                "modalStyle searchConditionsSave " +
                (isSaveConditions ? "" : "d-none")
              }
            >
              <div className="modalStyle_wrapper">
                <div className="modalStyle_box searchConditionsSave_box">
                  <div className="modalStyle_header">
                    <div className="modalStyle_title">検索条件の保存</div>
                    <div
                      className="modalStyle_times"
                      onClick={() => {
                        setIsSaveConditions(false);
                      }}
                    >
                      <img src={iconClose} />
                    </div>
                  </div>

                  <div className="searchConditionsSave_text">
                    以下の検索条件を保存しました
                  </div>

                  <div className="searchConditionsSave_row">
                    <div className="searchConditionsSave_heading">地域</div>
                    <div className="searchConditionsSave_contents">
                      {saveCitiesCondition.map((value, index) => (
                        <span key={index}>
                          {value}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="searchConditionsSave_row">
                    <div className="searchConditionsSave_heading">駅・路線</div>
                    <div className="searchConditionsSave_contents">
                      {saveStationCondition.map((value, index) => (
                        <span key={index}>
                          {value}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="searchConditionsSave_row">
                    <div className="searchConditionsSave_heading">詳細条件</div>
                    <div className="searchConditionsSave_contents">
                      <span>{showDetailSetting()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*        <div className="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
          <div className="bg-white w-full shadow p-8">
            <Map googleMapsApiKey={googleMapsApiKey} />
          </div>
        </div>
        <div className="w-full my-12">
          <div className="max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
            <div className="bg-white w-full shadow rounded p-8">
              <h1 className="text-2xl font-bold text-gray-700">物件一覧</h1>
              <div className="mt-6">
                {
                  properties.length > 0 ? (
                  properties.map((property) => {
                    if (!property.rooms.length) return;
                    return <Property key={property.id} property={property} />
                  })) : <div>物件が見つかりませんでした</div>
                }
              </div>
            </div>
          </div>
        </div>*/}
          </main>
        )}

      {isModalShow ? <Modal /> : null}

      {/*検索一覧、地図表示部分（地図表示時に表示）*/}
      {isShowMap && (
        <MapContent
          googleMapsApiKey={googleMapsApiKey}
          isShow={isShowMap}
          handleShowMap={handleShowMap}
          cities={cities}
          stationNames={stationNames}
          detailNames={detailNames}
          length={totalCount}
          setIsShowChangeConditions={setIsShowChangeConditions}
          property={properties?.length > 0 ? properties : properties}
        />
      )}
      {isShowChangeConditions ? (
        <ChangeConditions
          stationNames={stationNames}
          cities={cities}
          showDetailSetting={showDetailSetting()}
          handleAreaSearch={handleShowAreaSearch}
          handleStationSearch={handleShowStationSearch}
          handleDetailConditions={handleShowDetailConditions}
        />
      ) : null}
      {isShowAreaSearch ? (
        <AreaSearch
          selectStation={selectStation}
          select_city_ids={props.city_ids || sessionStorage.getItem("city_ids")?.split(",")}
          details={props.detail}
        />
      ) : null}
      {isShowStationSearch ? (
        <StationSearch
          selectCityIds={props.city_ids}
          selectStation={selectStation}
          stationRoutes={props.stationRoutes}
          details={props.detail}
        />
      ) : null}
      {isShowDetailConditions ? (
        <form action="/searches" method="post">
          {props.city_ids &&
            props.city_ids.map((id) => {
              return (
                <input
                  type="hidden"
                  id={`region_${id}`}
                  name="city_ids[]"
                  value={id}
                />
              );
            })}
          {selectStation &&
            selectStation.map((station) => {
              return (
                <input
                  type="hidden"
                  id={`${station.line_id}-${station.id}`}
                  name="station_master[][station][]"
                  value={station.id}
                />
              );
            })}
          {selectStation &&
            [...new Set(selectStation.map((s) => s.line_id))].map((lineId) => {
              return (
                <input
                  type="hidden"
                  id={lineId}
                  name="station_master[][line]"
                  value={lineId}
                />
              );
            })}
          {props.south_west_lat && (
            <input
              type="hidden"
              id="south_west_lat"
              name="south_west_lat"
              value={props.south_west_lat}
            />
          )}
          {props.north_east_lat && (
            <input
              type="hidden"
              id="north_east_lat"
              name="north_east_lat"
              value={props.north_east_lat}
            />
          )}
          {props.south_west_lon && (
            <input
              type="hidden"
              id="south_west_lon"
              name="south_west_lon"
              value={props.south_west_lon}
            />
          )}
          {props.north_east_lon && (
            <input
              type="hidden"
              id="north_east_lon"
              name="north_east_lon"
              value={props.north_east_lon}
            />
          )}
          <DetailConditions
            details={props.detail || JSON.parse(sessionStorage.getItem("detail"))}
            isShow={isShowDetailConditions}
          />
        </form>
      ) : null}
    </>
  );
};

export default PropertiesSearchPage;
