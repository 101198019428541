import React from "react";
import Map3 from "./Map3";
import arrowLeft from "../../../../assets/images/icon/icon-arrow-left.svg";
import arrowRight from "../../../../assets/images/icon/icon-arrow-left-right.svg";

const MapContent = ({
  googleMapsApiKey,
  isShow,
  handleShowMap,
  cities,
  stationNames,
  detailNames,
  length,
  setIsShowChangeConditions,
  property
}) => {

  
  return (
    <div className={"searchResultMap " + (isShow ? "" : "d-none")}>
      <div className="searchResultMap_wrapper">
        <div className="searchResultMap_pc">
          <h2 className="searchResult_heading">
            {cities} {stationNames} {detailNames}{" "}
            {(!cities.length && !stationNames.length && !detailNames.length) && "現在地" }
            の賃貸(賃貸マンション・アパート)
          </h2>
          <div className="searchResultMap_pc_top">
            <div className="searchResultMap_back">
              <div className="btnStyle-fill" onClick={handleShowMap}>
                <img src={arrowLeft} />
                一覧へ戻る
              </div>
            </div>
            <div className="searchResultMap_right">
              <div className="searchResultList_number">
                検索結果：
                <span>{length}</span>件
              </div>
              <div
                className="btnStyle-line ml5"
                onClick={() => {
                  setIsShowChangeConditions(true);
                }}
              >
                <img src={arrowRight} />
                条件変更
              </div>
            </div>
          </div>
        </div>
        <div className="searchResultMap_area">
          <Map3 googleMapsApiKey={googleMapsApiKey} property={property} />
        </div>
        <div className="searchResultMap_action">
          <div className="btnStyle-white">検索条件の変更</div>
          <div className="btnStyle-fill ml5">条件保存</div>
        </div>
      </div>
    </div>
  );
};
export default MapContent;
