require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("src/sp_header_menu")
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '../stylesheets/portal_application.scss'

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
