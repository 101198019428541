import React, { useState, useEffect } from "react";
import iconDeleteBin from "../../../assets/images/icon/icon-delete-bin.svg";
import iconSearch from "../../../assets/images/icon/icon-search.svg";
import axios from "axios";

const MySearchPage = (props) => {
  const storageName1 = "itudemo_save_condition1";
  const storageName2 = "itudemo_save_condition2";

  const [condition1, setCondition1] = useState(
    localStorage.getItem(storageName1)
  );
  const [condition2, setCondition2] = useState(
    localStorage.getItem(storageName2)
  );
  const [isDelete, setIsDelete] = useState(false);
  const [deleteNo, setDeleteNo] = useState(0);

  // 保存した検索条件から整形した文字列を格納するState
  const conditionDetail = {
    saveDay: "",
    cities: [],
    stations: [],
    detail: "",
  };
  const [shapeCondition1, setShapeCondition1] = useState(conditionDetail);
  const [shapeCondition2, setShapeCondition2] = useState(conditionDetail);

  const [deleteCondition, setDeleteCondition] = useState(conditionDetail);

  useEffect(async () => {
    try {
      // ここで保存した検索条件から文字列を整形する（API実行も含む）
      if (condition1 && condition1 !== null) {
        await shapingCondition1();
      }
      if (condition2 && condition2 !== null) {
        await shapingCondition2();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * 検索条件1の条件整形
   */
  const shapingCondition1 = async () => {
    try {
      const condition = JSON.parse(condition1);
      const ret = await requestSaveSearches(condition);
      let stations = [];
      if (ret.stations.length !== 0) {
        stations = shapeStations(ret.stations);
      }
      let cities = [];
      if (ret.cities.length !== 0) {
        cities = shapeCities(ret.cities);
      }
      let detail = "";
      if (condition.detail !== "null") {
        detail = shapeDetail(condition.detail);
      }

      const shapCondtions = {
        saveDay: condition1.saveDay,
        cities: cities,
        stations: stations,
        detail: detail,
      };
      setShapeCondition1(shapCondtions);
    } catch (error) {
      throw error;
    }
  };

  /**
   * 検索条件2の条件整形
   */
  const shapingCondition2 = async () => {
    try {
      const condition = JSON.parse(condition2);
      const ret = await requestSaveSearches(condition);
      let stations = [];
      if (ret.stations.length !== 0) {
        stations = shapeStations(ret.stations);
      }
      let cities = [];
      if (ret.cities.length !== 0) {
        cities = shapeCities(ret.cities);
      }
      let detail = "";
      if (condition.detail !== "null") {
        detail = shapeDetail(condition.detail);
      }
      const shapCondtions = {
        saveDay: condition2.saveDay,
        cities: cities,
        stations: stations,
        detail: detail,
      };
      setShapeCondition2(shapCondtions);
    } catch (error) {
      throw error;
    }
  };

  /**
   * 検索結果の路線・駅の整形処理
   * @param {Object} argStations
   * @returns
   */
  const shapeStations = (argStations) => {
    let stations = [];
    let line = argStations[0].station_line_name;
    let stationsStr = line + " / ";
    for (let i = 0; i < argStations.length; i++) {
      // 路線ごとの判定
      if (line !== argStations[i].station_line_name) {
        stations.push(stationsStr.slice(0, -1));
        line = argStations[i].station_line_name;
        stationsStr = line + " / ";
      }
      stationsStr += argStations[i].station_name + "、";
    }
    stations.push(stationsStr.slice(0, -1));
    return stations;
  };

  /**
   * 検索結果の地域の整形処理
   * @param {*} argCities
   * @returns
   */
  const shapeCities = (argCities) => {
    let cities = [];
    let prefecture = argCities[0].prefecture_name;
    let citiesStr = prefecture + " / ";
    for (let i = 0; i < argCities.length; i++) {
      // 都道府県ごとの判定
      if (prefecture !== argCities[i].prefecture_name) {
        cities.push(citiesStr.slice(0, -1));
        prefecture = argCities[i].prefecture_name;
        citiesStr = prefecture + " / ";
      }
      citiesStr += argCities[i].city_name + "、";
    }
    cities.push(citiesStr.slice(0, -1));
    return cities;
  };

  /**
   * 詳細条件の整形処理
   * @param {*} argDetail
   * @returns
   */
  const shapeDetail = (argDetail) => {
    let detailStr = "";
    const connectStr = "、";

    let detailObj = {};
    try {
      detailObj = JSON.parse(argDetail);
    } catch (error) {
      detailObj = argDetail;
    }

    if (detailObj.contracts_rent_gteq) {
      const contracts_rent_mins = [
        { key: 0, value: "下限なし" },
        { key: 10000, value: "1万" },
        { key: 20000, value: "2万" },
        { key: 30000, value: "3万" },
        { key: 35000, value: "3.5万" },
        { key: 40000, value: "4万" },
        { key: 45000, value: "4.5万" },
        { key: 50000, value: "5万" },
        { key: 55000, value: "5.5万" },
        { key: 60000, value: "6万" },
        { key: 65000, value: "6.5万" },
        { key: 70000, value: "7万" },
        { key: 75000, value: "7.5万" },
        { key: 80000, value: "8万" },
        { key: 85000, value: "8.5万" },
        { key: 90000, value: "9万" },
        { key: 95000, value: "9.5万" },
        { key: 100000, value: "10万" },
        { key: 105000, value: "10.5万" },
        { key: 110000, value: "11万" },
        { key: 115000, value: "11.5万" },
        { key: 120000, value: "12万" },
        { key: 125000, value: "12.5万" },
        { key: 130000, value: "13万" },
        { key: 135000, value: "13.5万" },
        { key: 140000, value: "14万" },
        { key: 145000, value: "14.5万" },
        { key: 150000, value: "15万" },
        { key: 155000, value: "15.5万" },
        { key: 160000, value: "16万" },
        { key: 165000, value: "16.5万" },
        { key: 170000, value: "17万" },
        { key: 175000, value: "17.5万" },
        { key: 180000, value: "18万" },
        { key: 185000, value: "18.5万" },
        { key: 190000, value: "19万" },
        { key: 195000, value: "19.5万" },
        { key: 200000, value: "20万" },
        { key: 210000, value: "21万" },
        { key: 220000, value: "22万" },
        { key: 230000, value: "23万" },
        { key: 240000, value: "24万" },
        { key: 250000, value: "25万" },
        { key: 260000, value: "26万" },
        { key: 270000, value: "27万" },
        { key: 280000, value: "28万" },
        { key: 290000, value: "29万" },
        { key: 300000, value: "30万" },
        { key: 350000, value: "35万" },
        { key: 400000, value: "40万" },
        { key: 500000, value: "50万" },
        { key: 1000000, value: "100万" },
      ];
      const contracts_rent_min = contracts_rent_mins.find(
        (obj) => obj.key == detailObj.contracts_rent_gteq
      );
      if (contracts_rent_min.key >= 10000) {
        detailStr +=
          (detailStr === "" ? "" : connectStr) +
          contracts_rent_min.value +
          "円以上";
      }
    }
    if (detailObj.contracts_rent_lteq) {
      const contracts_rent_maxs = [
        { key: 0, value: "上限なし" },
        { key: 10000, value: "1万" },
        { key: 20000, value: "2万" },
        { key: 30000, value: "3万" },
        { key: 35000, value: "3.5万" },
        { key: 40000, value: "4万" },
        { key: 45000, value: "4.5万" },
        { key: 50000, value: "5万" },
        { key: 55000, value: "5.5万" },
        { key: 60000, value: "6万" },
        { key: 65000, value: "6.5万" },
        { key: 70000, value: "7万" },
        { key: 75000, value: "7.5万" },
        { key: 80000, value: "8万" },
        { key: 85000, value: "8.5万" },
        { key: 90000, value: "9万" },
        { key: 95000, value: "9.5万" },
        { key: 100000, value: "10万" },
        { key: 105000, value: "10.5万" },
        { key: 110000, value: "11万" },
        { key: 115000, value: "11.5万" },
        { key: 120000, value: "12万" },
        { key: 125000, value: "12.5万" },
        { key: 130000, value: "13万" },
        { key: 135000, value: "13.5万" },
        { key: 140000, value: "14万" },
        { key: 145000, value: "14.5万" },
        { key: 150000, value: "15万" },
        { key: 155000, value: "15.5万" },
        { key: 160000, value: "16万" },
        { key: 165000, value: "16.5万" },
        { key: 170000, value: "17万" },
        { key: 175000, value: "17.5万" },
        { key: 180000, value: "18万" },
        { key: 185000, value: "18.5万" },
        { key: 190000, value: "19万" },
        { key: 195000, value: "19.5万" },
        { key: 200000, value: "20万" },
        { key: 210000, value: "21万" },
        { key: 220000, value: "22万" },
        { key: 230000, value: "23万" },
        { key: 240000, value: "24万" },
        { key: 250000, value: "25万" },
        { key: 260000, value: "26万" },
        { key: 270000, value: "27万" },
        { key: 280000, value: "28万" },
        { key: 290000, value: "29万" },
        { key: 300000, value: "30万" },
        { key: 350000, value: "35万" },
        { key: 400000, value: "40万" },
        { key: 500000, value: "50万" },
        { key: 1000000, value: "100万" },
      ];
      const contracts_rent_max = contracts_rent_maxs.find(
        (obj) => obj.key == detailObj.contracts_rent_lteq
      );
      if (contracts_rent_max.key >= 10000) {
        detailStr +=
          (detailStr === "" ? "" : connectStr) +
          contracts_rent_max.value +
          "円以下";
      }
    }

    //こだわり条件
    if (detailObj.features_name_in) {
      detailStr += detailObj.features_name_in.join(connectStr);
    }
    //共益費/管理費
    if (detailObj.contracts_management_fee) {
      detailStr += (detailStr === "" ? "" : connectStr) + "共益費/管理費含む";
    }
    //駐車場料金
    if (detailObj.have_bicycle_parking_eq) {
      detailStr += (detailStr === "" ? "" : connectStr) + "駐車場料金含む";
    }
    //礼金
    if (detailObj.contracts_reikin_blank) {
      detailStr += (detailStr === "" ? "" : connectStr) + "礼金なし";
    }
    //初期費用
    if (detailObj.initialCostPayment_blank === "true") {
      detailStr +=
        (detailStr === "" ? "" : connectStr) + "初期費用カード決済可";
    }
    //家賃カード決済
    if (detailObj.rentCostPayment_blank === "true") {
      detailStr += (detailStr === "" ? "" : connectStr) + "家賃カード決済可";
    }
    //敷金/保証金
    if (detailObj.contracts_shikikin_blank) {
      detailStr += (detailStr === "" ? "" : connectStr) + "敷金/保証金なし";
    }
    //駅徒歩
    if (detailObj.property_accesses_walkby_lteq) {
      const walkStr =
        detailObj.property_accesses_walkby_lteq === "0"
          ? "指定なし"
          : detailObj.property_accesses_walkby_lteq + "分以内";

      detailStr += (detailStr === "" ? "" : connectStr) + walkStr;
    }
    //構造
    if (detailObj.structure_in) {
      const sructures = [
        { key: 1, value: "木造" },
        { key: 4, value: "鉄骨系" },
        { key: 5, value: "鉄筋系" },
      ];
      detailObj.structure_in.map((v) => {
        const sructure = sructures.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + sructure.value;
      });
    }
    if (detailObj.building_in) {
      const buildings = [
        { key: 1, value: "マンション" },
        { key: 2, value: "アパート" },
        { key: 3, value: "一戸建て・その他" },
      ];
      detailObj.building_in.map((v) => {
        const building = buildings.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + building.value;
      });
    }
    //間取りタイプ
    if (detailObj.rooms_layout_in) {
      const floorPlans = [
        { key: 1, value: "ワンルーム" },
        { key: 2, value: "1K" },
        { key: 3, value: "1DK" },
        { key: 13, value: "1LDK" },
        { key: 4, value: "2K" },
        { key: 5, value: "2DK" },
        { key: 6, value: "2LDK" },
        { key: 7, value: "3K" },
        { key: 8, value: "3DK" },
        { key: 9, value: "3LDK" },
        { key: 10, value: "4K" },
        { key: 11, value: "4DK" },
        { key: 12, value: "4LDK以上" },
      ];
      detailObj.rooms_layout_in.map((v) => {
        const floorPlan = floorPlans.find((obj) => obj.key == v);
        detailStr += (detailStr === "" ? "" : connectStr) + floorPlan.value;
      });
    }
    return detailStr;
  };

  /**
   * 保存されている検索条件から路線・駅、地域名を逆引き検索する処理
   * @param {*} condition
   * @returns
   */
  const requestSaveSearches = async (condition) => {
    try {
      if (!condition) return;
      const jsonObj = condition;
      let citys = "city_ids=";
      if (jsonObj.city_ids && jsonObj.city_ids !== "null") {
        citys += jsonObj.city_ids;
      }
      let stations = "station_ids=";
      if (jsonObj.station_master && jsonObj.station_master !== "null") {
        for (let i = 0; i < jsonObj.station_master.length; i++) {
          stations += jsonObj.station_master[i].station.join(",");
        }
      }

      const getSearchesURL = "/api/portal/save_searches?";
      const ret = await axios.get(getSearchesURL + citys + "&" + stations);
      return ret.data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * 削除確認表示処理
   * @param {*} no
   */
  const deleteStep = (no) => {
    setDeleteNo(no);
    setIsDelete(true);
    if (no === 1) {
      setDeleteCondition(shapeCondition1);
    } else {
      setDeleteCondition(shapeCondition2);
    }
  };

  /**
   * localStorageから該当データを削除する処理
   */
  const deleteLocalStorage = () => {
    if (deleteNo === 1) {
      localStorage.removeItem(storageName1);
      setCondition1(null);
      if (condition2) {
        //検索条件2があれば検索条件1に昇格
        localStorage.setItem(storageName1, condition2);
        localStorage.removeItem(storageName2);
        setCondition1(condition2);
        setShapeCondition1(shapeCondition2);
        setShapeCondition2(null);
        setCondition2(null);
      }
      setIsDelete(false);
    } else if (deleteNo === 2) {
      localStorage.removeItem(storageName2);
      setCondition2(null);
      setIsDelete(false);
    } else {
      alert("削除できる検索条件が存在しません。");
    }
  };

  return (
    <>
      <div className="breadcrumbStyle">
        <a href="/">トップ</a>
        <span> ＞ </span>
        <span>保存した検索条件</span>
      </div>

      <div className="mySearch">
        <h1>保存した検索条件</h1>
        <div className="mySearch_wrapper">
          {condition1 ? (
            <ConditionBlock
              no={1}
              deleteStep={deleteStep}
              shapes={shapeCondition1}
              conditions={condition1}
            />
          ) : (
            <></>
          )}
          {condition2 ? (
            <ConditionBlock
              no={2}
              deleteStep={deleteStep}
              shapes={shapeCondition2}
              conditions={condition2}
            />
          ) : (
            <></>
          )}
          {!condition1 && !condition2 ? (
            <>
              <p>保存された検索条件はありません。</p>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={"modalStyle " + (isDelete ? "" : "d-none")}>
        <div className="modalStyle_wrapper">
          <div className="modalStyle_box searchConditionsSave_box">
            <div className="modalStyle_header">
              <div className="modalStyle_title">検索条件の削除</div>
              <div
                className="modalStyle_times"
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                <img src="/assets/icon/icon-close.svg" />
              </div>
            </div>

            <div className="searchConditionsSave_text">
              以下の検索条件を削除しますか？
            </div>
            <div className="mySearchCard">
              <div className="mySearchCard_header">
                <h2>検索条件{deleteNo}</h2>
                <div className="mySearchCard_date">
                  {deleteCondition.saveDay}
                </div>
              </div>
              <div className="mySearchCard_body">
                {deleteCondition.cities.length !== 0 ? (
                  <div className="mySearchCard_item">
                    <div className="mySearchCard_label">地域</div>
                    <div className="mySearchCard_text">
                      {deleteCondition.cities.map((value, index) => (
                        <span key={index}>
                          {value}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {deleteCondition.stations.length !== 0 ? (
                  <div className="mySearchCard_item">
                    <div className="mySearchCard_label">沿線・駅</div>
                    <div className="mySearchCard_text">
                      {deleteCondition.stations.map((value, index) => (
                        <span key={index}>
                          {value}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {deleteCondition.detail.length !== "" ? (
                  <div className="mySearchCard_item">
                    <div className="mySearchCard_label">詳細条件</div>
                    <div className="mySearchCard_text">
                      {deleteCondition.detail}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="mySearchCard_btn">
                  <div className="btnStyle-fill" onClick={deleteLocalStorage}>
                    削除する
                  </div>
                  <div
                    className="btnStyle-white"
                    onClick={() => {
                      setIsDelete(false);
                    }}
                  >
                    キャンセル
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ConditionBlock = ({ no, shapes, conditions, deleteStep }) => {
  const search = () => {
    const jsonObj = JSON.parse(conditions);
    // session storageに詰め込む
    // 検索機能はPropertiesSearchPageにて実施
    sessionStorage.setItem("city_ids", jsonObj.city_ids);
    sessionStorage.setItem("station_master",  JSON.stringify(jsonObj.station_master));
    jsonObj.detail && sessionStorage.setItem("detail",  JSON.stringify(jsonObj.detail));
    location.href = "/searches";
  };
  return (
    <>
      <div className="mySearchCard">
        <div className="mySearchCard_header">
          <h2>検索条件{no}</h2>
          <div className="mySearchCard_date">{shapes.saveDay}</div>
          <div
            className="mySearchCard_delete"
            onClick={() => {
              deleteStep(no);
            }}
          >
            <img src={iconDeleteBin} />
          </div>
        </div>
        <div className="mySearchCard_body">
          {shapes.cities.length !== 0 ? (
            <div className="mySearchCard_item">
              <div className="mySearchCard_label">地域</div>
              <div className="mySearchCard_text">
                {shapes.cities.map((value, index) => (
                  <span key={index}>
                    {value}
                    <br />
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {shapes.stations.length !== 0 ? (
            <div className="mySearchCard_item">
              <div className="mySearchCard_label">沿線・駅</div>
              <div className="mySearchCard_text">
                {shapes.stations.map((value, index) => (
                  <span key={index}>
                    {value}
                    <br />
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {shapes.detail !== "" ? (
            <div className="mySearchCard_item">
              <div className="mySearchCard_label">詳細条件</div>
              <div className="mySearchCard_text">{shapes.detail}</div>
            </div>
          ) : (
            <></>
          )}

          <div className="mySearchCard_btn">
            <div className="btnStyle-fill" onClick={search}>
              <img src={iconSearch} />
              この条件で検索する
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MySearchPage;
