<template>
  <div class='container'>
    <h4>登録が完了しました</h4>
    <div class='row w50 m0a mt-4'>
      <div class='col-6 text-center pl-0'>
        <button class='btn btn-lg btn-rounded btn-secondary' @click="backToCalendar()">
          <i class="fas fa-backward"></i>
            カレンダーに戻る
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'WaitLocalGuidance',
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    onStepSelected(step){
      this.$emit('onStepSelected', step)
    },
    backToCalendar(){
      this.$emit('backToCalendar')
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.w50{
  width: 50% !important
}
.m0a{
  margin: 0 auto;
}
</style>

