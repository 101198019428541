import React from "react";
import Container from "@material-ui/core/Container";

export const ContactComplete = ({ formData }) => {
  const {} = formData;
  return (
    <>
      <div class="contactComplete_wrapper">
        <Container>
            <h2 class="contactComplete_title">送信が完了しました。</h2>
            <div class="contactComplete_info">内容により返信できない場合や、返答までに日数を要する場合がありますので、あらかじめご了承ください。</div>

            <div class="contactComplete_button">
              <a href="/" class="btnStyle-fill">トップページへ</a>
            </div>
        </Container>
      </div>
    </>
  );
};
