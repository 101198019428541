import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Map from "../PropertiesSearch/Map";
import iconMapPin from "../../../../assets/images/icon/icon-location.svg";
import { boundData } from "../PropertiesSearch/state";
import { showDetailConditionsState, showMapSearchState } from "./state";
import DetailConditions from "../PropertiesSearch/DetailConditions";
import MapContent from "./Map";

const MapSearch = (props) => {
  const bound = useRecoilValue(boundData);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [isShowDetailConditions, setIsShowDetailConditions] = useRecoilState(
    showDetailConditionsState
  );
  const [isShowMapSearch, setShowMapSearch] =
    useState(true);

  return (
    <form action={`/searches?${new URLSearchParams(bound)}`} method="post">
      <DetailConditions details={props} isShow={isShowDetailConditions}/>
      <main className="w-full" hidden={!isShowMapSearch}>
        <div className="breadcrumbStyle">
          <a href="/">トップ</a>
          <span> ＞ </span>
          <span>現在地から探す</span>
        </div>

        <h2 className="text-center searchArea_title font-semibold">
          <img src={iconMapPin} />
          現在地から探す
        </h2>
        <div className="searchResultMap_wrapper">
          <div className="searchResultMap_area">
          <Map
              googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY_202210}
              isCurrentLocation={true}
            />
          </div>
        </div>
        <div className="searchArea_bottomMenu">
          <div className="searchArea_bottomMenuWrapper">
            <div
              className="btnStyle-white"
              onClick={() => {
                setIsShowDetailConditions(true);
                setShowMapSearch(!isShowMapSearch);
              }}
            >
              検索条件を追加
            </div>
            <button
              type="submit"
              className="btnStyle-fill"
            >
              一覧を見る
            </button>
          </div>
        </div>
      </main>
    </form>
  );
};
export default MapSearch;
