import { atom, selector } from "recoil";

export const modalShowState = atom({
  key: "modalShowState",
  default: false,
});

export const isShowModal = selector({
  key: "isShowModal",
  get: ({ get }) => {
    return get(modalShowState);
  },
});

export const streetViewShowState = atom({
  key: "streetViewShowState",
  default: false,
});

export const isShowStreetView = selector({
  key: "isShowStreetView",
  get: ({ get }) => {
    return get(streetViewShowState);
  },
});

export const modalDataState = atom({
  key: "modalDataState",
  default: null,
});

export const modalData = selector({
  key: "modalData",
  get: ({ get }) => {
    return get(modalDataState);
  },
});

export const propertiesState = atom({
  key: "propertiesState",
  default: [],
});

export const propertiesData = selector({
  key: "properties",
  get: ({ get }) => {
    return get(propertiesState);
  },
});

export const centerState = atom({
  key: "centerState",
  default: {lat: 35.6809591, lng: 139.7673068},
});

export const placeChangedState = atom({
  key: "placeChangedState",
  default: null,
});

export const roomsState = atom({
  key: "roomsState",
  default: {},
});

export const roomsData = selector({
  key: "rooms",
  get: ({ get }) => {
    return get(roomsState);
  },
});

export const cityState = atom({
  key: "cityState",
  default: {},
});

export const citysData = selector({
  key: "city",
  get: ({ get }) => {
    return get(cityState);
  },
});

export const boundState = atom({
  key: "boundState",
  default: {},
});

export const boundData = selector({
  key: "bound",
  get: ({ get }) => {
    return get(boundState);
  },
});
