import React from "react";
import rightIconImage from "../../../../assets/images/icon/icon-arrow-right-black.svg";
import iconMapPin from "../../../../assets/images/icon/icon_map_pin.svg";
import iconTime from "../../../../assets/images/icon/icon_time.svg";
import iconTrain from "../../../../assets/images/icon/icon_train.svg";
import iconCondition from "../../../../assets/images/icon/icon_condition.svg";

const ChangeConditions = ({
  handleAreaSearch,
  handleStationSearch,
  handleDetailConditions,
  showDetailSetting,
  stationNames,
  cities,
}) => {
  
  return (
    <main className="w-full">
      <div className="searchResult">
        <div className="searchResult_breadcrumb">
          <a href="/">トップ</a>
          <span> &gt; </span>
          <span>検索結果</span>
          <span> &gt; </span>
          <span>検索条件の変更</span>
        </div>
        <h2 className="searchResultEdit_title">検索条件の変更</h2>

        <div className="searchResultEdit_wrapper">
          <div className="searchResultEdit_editWrapper">
            <div className="searchResultEdit_label">
              <div className="searchResultEdit_subtitle d-flex">
                <img src={iconMapPin} />
                地域
              </div>
              <div
                className="searchResultEdit_edit d-flex"
                onClick={handleAreaSearch}
              >
                変更
                <img src={rightIconImage} />
              </div>
            </div>
            <div className="searchResultEdit_condition">
              {cities}
            </div>
          </div>

          <div className="searchResultEdit_editWrapper">
            <div className="searchResultEdit_label">
              <div className="searchResultEdit_subtitle d-flex">
                <img src={iconTrain} />駅
              </div>
              <div
                className="searchResultEdit_edit d-flex"
                onClick={handleStationSearch}
              >
                変更
                <img src={rightIconImage} />
              </div>
            </div>
            <div className="searchResultEdit_condition">
              {stationNames}
            </div>
          </div>

          {/* <div className="searchResultEdit_editWrapper">
            <div className="searchResultEdit_label">
              <div className="searchResultEdit_subtitle d-flex">
                <img src={iconTime} />
                通勤通学時間
              </div>
              <div className="searchResultEdit_edit d-flex">
                変更
                <img src={rightIconImage} />
              </div>
            </div>

            <div className="searchResultEdit_condition">指定なし</div>
          </div> */}

          <div className="searchResultEdit_editWrapper">
            <div className="searchResultEdit_label">
              <div className="searchResultEdit_subtitle d-flex">
                <img src={iconCondition} />
                詳細条件
              </div>
              <div
                className="searchResultEdit_edit d-flex"
                onClick={handleDetailConditions}
              >
                変更
                <img src={rightIconImage} />
              </div>
            </div>

            <div className="searchResultEdit_condition">
              {/* 12万円以下、礼金ｍなし、駅徒歩10分以内、ワンルーム、1K、1DK、専有面積20㎡以上 */}
              {showDetailSetting}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ChangeConditions;
