<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
      <div class="contents-box my-2">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold">本人確認を行ってください</h3>

          <!-- 顔認証パターン -->
          <div v-if="isFaceVerificationUsed">
          <!-- 本人確認開始ボタン -->
          <div v-show="!onIdentificationRequesting && !flowDetailProceedable">
            <div class="text-center">
              <img src="~navi/camera_kao_ninshiki.png" width="160" alt="本人確認をする">
            </div>
            <p class="my-3">本人確認を行います。下記のボタンをクリックし、画像認証による本人確認を開始してください。</p>
            <div class="text-center mb-1">
              <button class="btn btn-primary rounded-pill px-4 py-2"
                :class="identificationBtnDisabledClass"
                @click="startIdentification">本人確認を開始する</button>
            </div>
            <p class="my-3 fs075 text-color">
             ※ 本人確認を完了させる為には、<br/>
              ・画像認証により、事前に撮影されたお写真と撮影された写真が同一人物であると認定されること<br/>
              ・鍵設置位置と、写真撮影位置が同じであること<br/>
              が必要となります。<br/><br/>
              ですので、必ず指定の鍵設置場所に移動した上で、認証写真の撮影を行ってください。<br/>
            </p>
          </div>

          <div v-show="onIdentificationRequesting && !flowDetailProceedable">
            <!-- カメラエリア -->
            <div v-if="identificationCount < 3" v-show='shooting'>
              <div class='row' style='background-color: white;' v-show="capturedUrl == ''">
                <div class="alert alert-danger alert-rounded w100" v-if="!errorMsg == ''">
                  {{ errorMsg }}
                </div>
                <div class='col-sm-12 text-center mt-4' id='faceCamera'>
                  <video ref="video" id="video" width='300' height='200' autoplay></video>
                  <img src="~face_size_image.png" width='300' height='200' id="faceSizeImage">
                </div>
                <div class='col-12 text-center' v-if="!showCautionModal">
                  <a href="" id="camera-icon" @click.prevent="capture()" class="btn btn--circle btn--circle-a btn--shadow">
                    <i class="fas fa-camera fs28"></i>
                  </a>
                </div>
              </div>
              <div class='row bgw' v-show="!capturedUrl == ''">
                <div class='col-12 text-center mt-4'>
                  <img :src="capturedUrl"/>
                </div>
              </div>
              <div class='row bgw pr-3 pl-3 pb-5 pt-3' v-show="!capturedUrl == ''">
                <div class='col-sm-12 col-md-6 text-center mt-3'>
                  <button class='btn btn-block btn-rounded btn-secondary' @click="retake">取り直し</button>
                </div>
                <div class='col-sm-12 col-md-6 text-center mt-3'>
                  <button class='btn btn-block btn-rounded btn-success' @click="save">決定</button>
                </div>
              </div>
              <div id='canvas_wrapper'>
                <canvas ref="canvas" id="canvas" width="300" height="200"></canvas>
              </div>
            </div>
            <Loading v-show='!shooting'/>

            <!-- 電話ボタン -->
            <div v-if="identificationCount >= 3">
              <div class='mb-4' v-show="!customerHasTel">
                <div class="contents-box my-2">
                  <div class="container py-3">
                    <div class="alert alert-danger alert-rounded w100">
                      画像での本人確認が複数回失敗しましたので、電話での本人確認を行います。お手数ですが、下記のボタンをタップし、担当者に入電をしてください。
                    </div>
                    <div class="text-center">
                      <img src="~navi/phone_tell.png" width="160" alt="電話をかける">
                    </div>
                    <div class="text-center mb-1">
                      <a :href="hrefTel"
                        class="btn btn-primary rounded-pill mt-3 px-4 py-2">電話をかける</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class='mb-4' v-show="customerHasTel">
                <div class="contents-box my-2">
                  <div class="container py-3">
                    <div class="alert alert-danger alert-rounded w100">
                      画像での本人確認が複数回失敗しましたので、電話での本人確認を行います。お手数ですが、下記のボタンをタップし、担当者に入電リクエストを送付してください。
                    </div>
                    <div class="text-center">
                      <img src="~navi/phone_tell.png" width="160" alt="電話をかける">
                    </div>
                    <div class="text-center mb-1">
                    <a :href="hrefTel"
                      class="btn btn-primary rounded-pill px-4 py-2 mt-3"
                      :class='actionBtnDisabledClass'
                      @click.prevent="createCallRequestMsg">入電リクエストを送る</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <fetch-location @setLocation='setLocation' v-if="onIdentificationRequesting" />
            <photo-untakeable-modal />
            <location-incorrect-modal />
          </div>
          </div>

          <!-- 顔認証不要パターン -->
          <div v-else>
            <div>
              <div class='mb-4' v-show="!customerHasTel">
                <div class="contents-box my-2">
                  <div class="container py-3">
                    <div class="alert alert-danger alert-rounded w100">
                      電話での本人確認を行います。お手数ですが、下記のボタンをタップし、担当者に入電をしてください。
                    </div>
                    <div class="text-center">
                      <img src="~navi/phone_tell.png" width="160" alt="電話をかける">
                    </div>
                    <div class="text-center mb-1">
                      <a :href="hrefTel"
                        class="btn btn-primary rounded-pill mt-3 px-4 py-2">電話をかける</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class='mb-4' v-show="customerHasTel">
                <div class="contents-box my-2">
                  <div class="container py-3">
                    <div class="alert alert-danger alert-rounded w100">
                      電話での本人確認を行います。お手数ですが、下記のボタンをタップし、担当者に入電リクエストを送付してください。
                    </div>
                    <div class="text-center">
                      <img src="~navi/phone_tell.png" width="160" alt="電話をかける">
                    </div>
                    <div class="text-center mb-1">
                    <a :href="hrefTel"
                      class="btn btn-primary rounded-pill px-4 py-2 mt-3"
                      :class='actionBtnDisabledClass'
                      @click.prevent="createCallRequestMsg">入電リクエストを送る</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 認証完了 -->
      <div class="contents-box my-2" v-if="flowDetailProceedable">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold">本人確認が完了しました</h3>
          <div class="row align-items-center">
            <div class="col-4 text-center g-2">
              <img src="~/navi/navi_ok.png" height="160" class="mx-auto">
            </div>
            <div class="col-8 g-2">
              <p class="mb-1">
                本人確認が完了しました。<br />次のステップに進めるようになりましたので、以下のボタンから次のステップに進み、鍵をお受取りください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
import FetchLocation from '@/components/FetchLocation'
import PhotoUntakeableModal from '@/components/PhotoUntakeableModal'
import LocationIncorrectModal from '@/components/LocationIncorrectModal'
import Loading from '@/components/company/Loading'
import axios from 'axios'
export default {
  name: 'Identification',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome, FetchLocation, PhotoUntakeableModal, LocationIncorrectModal, Loading },
  mounted() {
    this.$store.commit('flowDetail/setProceedable', false)
  },
  data() {
    return {
      timer: '',
      onIdentificationRequesting: false,
      video: {},
      canvas: {},
      capturedUrl: '',
      errorMsg: '',
      showCautionModal: false,
      showLocationIncorrectModal: false,
      stream: '',
      identificationCount: 0,
      onCallRequesting: false,
      shooting: true,
      location: {
        lat: '',
        lon: '',
        accuracy: '',
        errorMsg: '',
        errorCode: '',
      }
    }
  },
  mounted() {
    this.fetchIdentified()
    this.timer = setInterval(this.fetchIdentified, 5000)
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep', 'reservationToken']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('flowDetail', ['flowDetailProceedable']),
    ...mapGetters('user', ['userTel']),
    ...mapGetters('customer', ['customerTel']),
    ...mapGetters('property', ['propertyUseFaceVerification']),
    hrefTel() {
      return `tel:${this.userTel}`
    },
    customerHasTel() {
      if(this.customerTel == null){
        return false
      }else if(this.customerTel.length == 0){
        return false
      }else{
        return true
      }
    },
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    },
    identificationBtnDisabledClass() {
      return this.onIdentificationRequesting ? 'disabled' : ''
    },
    actionBtnDisabledClass() {
      return this.onCallRequesting ? 'disabled' : ''
    },
    isFaceVerificationUsed(){
      return this.propertyUseFaceVerification
    }
  },
  methods: {
    async onNextStepSelected() {
      await clearInterval(this.timer)
      await this.stopCamera()
      this.$emit('goToNextStep')
    },
    async onStepSelected() {
      await this.stopCamera()
      this.$emit('setCurrentFlowDetail')
    },
    async fetchIdentified() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}/identified`);
        this.$store.commit('flowDetail/setProceedable', res.data.identified)
        this.identificationCount = res.data.identification_count
      } catch {
        alert('ERROR');
      }
    },
    async setCamera(){
      this.video = this.$refs.video
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia({ video: true })
          this.video.srcObject = this.stream
          this.video.play()
        } catch(e){
          if(e.name == 'NotAllowedError'){
            this.showCautionModal = true
          }
        }
      }else{
        alert('カメラ未対応の端末のため、本機能をご利用いただけません。')
      }
    },
    async stopCamera(){
      try{
        let tracks = this.stream.getTracks()
        tracks.forEach(function(track) {
          track.stop()
        })
        this.video.srcObject = null
      }catch{
        this.video = {}
      }
    },
    capture () {
      this.canvas = this.$refs.canvas
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, 300, 200)
      this.capturedUrl = this.canvas.toDataURL('image/jpeg')
    },
    retake() {
      this.capturedUrl = ''
    },
    async save(){
      this.shooting = false
      try {
        const res = await axios.post(`/api/navi/reservations/${this.reservationToken}/collating_photos`, {
          collating_photo: this.capturedUrl,
          lat: this.location['lat'],
          lon: this.location['lon'],
        })
        if(res.data.error != ''){
          this.capturedUrl = ''
          this.errorMsg = res.data.error
          this.shooting = true
          setTimeout(() => {
            this.errorMsg = '' }
            , 3000
          )
        }
      } catch(e) {
        this.capturedUrl = ''
        this.errorMsg = e.error
        this.shooting = true
        setTimeout(() => {
          this.errorMsg = '' }
          , 3000
        )
      }
    },
    async startIdentification(){
      this.onIdentificationRequesting = true
      await this.setCamera()
    },
    async setLocation(locationData){
      this.location['lat'] = locationData['lat']
      this.location['lon'] = locationData['lon']
      this.location['accuracy'] = locationData['accuracy']
      this.location['errorMsg'] = locationData['errorMsg']
      this.location['errorCode'] = locationData['errorCode']
      if(this.location['errorCode'] == 1){
        this.showCautionModal = true
      }
      try {
        const res = await axios.post(`/api/navi/reservations/${this.reservationToken}/collating_photos/check_location`, {
          location: this.location,
        })
      }catch{
        this.showLocationIncorrectModal = true
      }
    },
    async createCallRequestMsg(){
     this.onCallRequesting = true;
      this.$emit('createCallRequestMsg', `[システムメッセージ] 本人確認失敗による入電リクエストがありました。 担当者は、${this.customerTel} 宛に入電をお願いします。`)
    }
  },
  watch: {
    showCautionModal: function(newVal, oldVal){
      if(newVal){
        this.$modal.show('photo-untakeable-modal')
      }else{
        this.$modal.hide('photo-untakeable-modal')
      }
    },
    showLocationIncorrectModal: function(newVal, oldVal){
      if(newVal){
        this.$modal.show('location-incorrect-modal')
      }else{
        this.$modal.hide('location-incorrect--modal')
      }
    }
  }
}
</script>

<style scoped>
.bgw {
  background-color: white;
}
#faceCamera {
  position: relative;
}
#faceSizeImage {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
