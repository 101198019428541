import { Box, Button, ButtonGroup } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import Accordion from "./AreaSearch/Accordion";
import iconMapPin from "../../../../assets/images/icon/icon_map_pin.svg";
import iconSearch from "../../../../assets/images/icon_search.svg";
import {
  areaIdentifierState,
  prefectureState,
  cityGroupState,
  showDetailConditionsState,
  showAreaSearchState,
} from "./state";
import DetailConditions from "../PropertiesSearch/DetailConditions";

const AreaSearch = (props) => {
  const [prefectures, setPrefectures] = useRecoilState(prefectureState);
  const [cityGroup, setCityGroup] = useRecoilState(cityGroupState);
  const [areaIdentifiers, setAreaIdentifier] =
    useRecoilState(areaIdentifierState);
  const [showDetailConditions, setShowDetailConditions] = useState(false);
  const [showAreaSearch, setShowAreaSearch] = useState(true);
  const [showHiddenInput, setShowHiddenInput] = useState(true);
  const [selectCityIds, setSelectCityIds] = useState([])

  useEffect(() => {
    const fetchAreaIdentifier = async () => {
      await axios.get("api/company/area_masters").then((res) => {
        setAreaIdentifier(res.data.area_masters);
      });
    };
    const fetchPrefecture = async () => {
      await axios.get("/api/company/prefecture_masters").then((res) => {
        setPrefectures(res.data.prefecture_masters);
      });
    };
    fetchAreaIdentifier();
    fetchPrefecture();
    props.select_city_ids && props.select_city_ids.length > 0 && setSelectCityIds(props.select_city_ids)
  }, []);

  const fetchCity = async (prefecture_id) => {
    await axios
      .get(
        `/api/company/prefecture_masters/${prefecture_id}/city_group_masters`
      )
      .then((res) => {
        setCityGroup({
          ...cityGroup,
          [prefecture_id]: [...res.data.city_group_masters],
        });
      });
  };

  return (
    <form action="/searches" method="post" name="form">
      {props.selectStation &&
        props.selectStation.map((station) => {
          return (
            <input
              type="hidden"
              id={`${station.line_id}-${station.id}`}
              name="station_master[][station][]"
              value={station.id}
            />
          );
        })}
      {props.selectStation &&
       [...new Set(props.selectStation
          .map((s) => s.line_id))]
          .map((lineId) => {
            return (
              <input
                type="hidden"
                id={lineId}
                name="station_master[][line]"
                value={lineId}
              />
            );
          })}
      <DetailConditions details={props.details} isShow={showDetailConditions} />
      <div className="w-full" hidden={!showAreaSearch}>
        <div className="breadcrumbStyle">
          <a href="/">トップ</a>
          <span> ＞ </span>
          <span>地域から探す</span>
        </div>

        <h2 className="text-center searchArea_title font-semibold">
          <img src={iconMapPin} />
          地域から探す
        </h2>
        <div className="searchArea_wrapper">
          <div className="searchArea_selectwrapper">
            {areaIdentifiers &&
              areaIdentifiers.map((areaIdentifier) => {
                const areaPrefectures = prefectures.filter(
                  (prefecture) =>
                    prefecture.area_master_id === areaIdentifier.id
                );
                return (
                  <>
                    <div
                      className="searchArea_headerWrapper"
                      key={`area_${areaIdentifier.id}`}
                    >
                      {areaIdentifier.text}
                    </div>
                    {areaPrefectures &&
                      areaPrefectures.map((prefecture, i) => {
                        return (
                          <Accordion
                            prefecture={prefecture}
                            key={i}
                            onClick={() => fetchCity(prefecture.id)}
                          >
                            <div className="searchArea_listWrapper">
                              {cityGroup[prefecture.id] &&
                                cityGroup[prefecture.id].map((cityGroup) => {
                                  return (
                                    <>
                                      <div className="searchArea_ward">
                                        {cityGroup.text}
                                      </div>
                                      <ul
                                        className="searchArea_list"
                                        id="areaSearch"
                                      >
                                        {cityGroup.cities &&
                                          cityGroup.cities.map((city) => {
                                            return (
                                              <li className="searchArea_item formStyle_check">
                                                <input
                                                  type="checkbox"
                                                  id={`region_${city.id}`}
                                                  name="city_ids[]"
                                                  value={city.id}
                                                  defaultChecked={
                                                    props.select_city_ids &&
                                                    props.select_city_ids.some(
                                                      (id) =>
                                                        id ===
                                                        city.id.toString()
                                                    )
                                                  }
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setSelectCityIds([...selectCityIds, e.target.value])
                                                      return
                                                    }
                                                    setSelectCityIds(selectCityIds.filter(id => id !== e.target.value))
                                                  }}
                                                />
                                                <label
                                                  className="searchArea_name"
                                                  for={`region_${city.id}`}
                                                >
                                                  {city.name}{" "}
                                                </label>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </>
                                  );
                                })}
                            </div>
                          </Accordion>
                        );
                      })}
                  </>
                );
              })}
          </div>
          <div className="searchArea_bottomMenu">
            <div className="searchArea_bottomMenuWrapper">
              <div
                className="btnStyle-white"
                onClick={() => {
                  setShowDetailConditions(true);
                  setShowAreaSearch(!showAreaSearch);
                }}
              >
                検索条件を追加
              </div>
              <button type="submit" className="btnStyle-fill" disabled={selectCityIds.length === 0}>
                <img src={iconSearch} />
                検索する
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AreaSearch;
