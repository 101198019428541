<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
        <div class="contents-box my-2">
          <div class="container py-3">
            <h3 class="h5 font-weight-bold">{{customerFullName}}様、本日は内見お疲れ様でした。</h3>
            <div class="row align-items-center">
              <div class="col-4 text-center g-2">
                <img src="~navi/navi_ok.png" height="160" class="mx-auto" alt="アドバイザー">
              </div>
              <div class="col-8 g-2">
                <p class="mb-1">
                  今後お部屋に関するご質問、お申込みに関する事項などございましたらチャットよりお気軽にご連絡ください。お気をつけてお帰りください。
                </p>
              </div>
            </div>
            <div class="p-2 mt-5 text-center" v-show="impressionSelectShowable">
              <p class="mb-2">本日見られたお部屋の感想をお聞かせください</p>
              <div class="text-center mt-3">
                <button class="btn btn-outline-secondary rounded-pill px-5 py-2"
                  @click.prev="onImpressionSelected('good')">
                  <i class="far fa-thumbs-up"></i>
                  気に入った
                </button>
              </div>
              <div class="text-center mt-3">
                <button class="btn btn-outline-secondary rounded-pill px-5 py-2"
                  @click.prev="onImpressionSelected('soso')">
                  <i class="far fa-hand-point-right"></i>
                  まずまず
                </button>
              </div>
              <div class="text-center mt-3">
                <button class="btn btn-outline-secondary rounded-pill px-5 py-2"
                  @click.prev="onImpressionSelected('bad')">
                  <i class="far fa-thumbs-down"></i>
                  いまいち
                </button>
              </div>
            </div>
            <div class="p-2 mt-3 text-center" v-show="willingSelectShowable">
              <p class="mb-2">この物件の申し込みを検討されますか？</p>
              <div class="text-center mt-3">
                <button class="btn btn-outline-secondary rounded-pill px-5 py-2"
                  @click.prevent="onWillingSelected(true)">
                  <i class="far fa-circle"></i>
                  検討する
                </button>
              </div>
              <div class="text-center mt-3">
                <button class="btn btn-outline-secondary rounded-pill px-5 py-2"
                  @click.prevent="onWillingSelected(false)">
                  <i class="fas fa-times"></i>
                  検討しない
                </button>
              </div>
            </div>
            <div class="p-2 mt-3 text-center" v-show="messageShowable">
              <p class="mb-2">その他、物件の感想や内覧方法に関してなど、ご意見をいただけますと幸いです。</p>
              <div class="text-center mb-1">
                <a href="" class="btn btn-outline-secondary rounded-pill px-5 py-2" @click.prevent="onChatOpenClicked()">チャットを開く</a>
              </div>
            </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
import axios from 'axios'

import { mapGetters } from 'vuex'
export default {
  name: 'FinishViewing',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome },
  data() {
    return {
      impressionSelectShowable: true,
      willingSelectShowable: false,
      messageShowable: false,
      reservationToken: ''
    }
  },
  async mounted() {
    this.reservationToken = window.location.href.match(/navi\/(.+)/)[1]
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep']),
    ...mapGetters('customer', ['customerFullName']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    }
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    },
    async onImpressionSelected(val){
      this.impressionSelectShowable = false
      this.willingSelectShowable = true
      try {
        const res = await axios.patch(`/api/navi/reservations/${this.reservationToken}/update_impression`,
        { impression: val })
      } catch {
        alert('ERROR');
      }
    },
    async onWillingSelected(val){
      this.willingSelectShowable = false
      this.messageShowable = true
      try {
        const res = await axios.patch(`/api/navi/reservations/${this.reservationToken}/update_willing_to_apply`,
        { willing_to_apply: val })
      } catch {
        alert('ERROR');
      }
    },
    onChatOpenClicked() {
      $('.open-chat-btn').click()
    },

  }
}
</script>

<style scoped>
</style>

