export const roomLayoutEnums = {
  one_r: '1R',
  one_k: '1K',
  one_dk: '1DK',
  two_k: '2K',
  two_dk: '2DK',
  two_ldk: '2LDK',
  three_k: '3K',
  three_dk: '3DK',
  three_ldk: '3LDK',
  four_k: '4K',
  four_dk: '4DK',
  four_ldk_over: '4LDK以上'
};