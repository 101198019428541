import React from "react";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";

export const ContactInput = ({ formData, setForm, navigation }) => {
  const { name, name_kana, email, message } = formData;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = () => {
    navigation.next();
  };
  return (
    <>
      <Container class="contact">
        <div className="contact_info">
          トップページの
          <a className="btnStyle-link" href="/#faq" target='_blank'>
            よくある質問
          </a>
          で疑問点が解消されない場合は、こちらのページよりお問い合わせください。
          <br />
          物件についてのご質問の場合は、物件詳細ページに記載されている物件番号を「お問い合わせ内容」の欄にご記入ください。
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="contact_input">
            <label className="contact_label">
              お名前<span className="formStyle_input-required">必須</span>
            </label>
            <input
              type="text"
              className={
                "form-control " +
                (errors.name && errors.name.type === "required" ? "error" : "")
              }
              name="name"
              placeholder="例：山田　太郎"
              value={name}
              {...register("name", {
                required: "Required",
              })}
              onChange={setForm}
            />
            {errors.name && errors.name.type === "required" && (
              <span className="text-danger">お名前を入力してください。</span>
            )}
          </div>

          <div className="contact_input">
            <label className="contact_label">お名前（ふりがな）</label>
            <input
              type="text"
              className="form-control"
              name="name_kana"
              placeholder="例：やまだ　たろう"
              value={name_kana}
              onChange={setForm}
            />
          </div>

          <div className="contact_input">
            <label className="contact_label">
              メールアドレス
              <span className="formStyle_input-required">必須</span>
            </label>
            <input
              type="text"
              className={"form-control " + (errors.email && errors.email.type === "required" ? 'error' : '') }
              name="email"
              placeholder="例：liferee@chintai.com"
              value={email}
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
              })}
              onChange={setForm}
            />
            {errors.email && errors.email.type === "required" && (
              <span className="text-danger">メールアドレスを入力してください。</span>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <span className="text-danger">
                メールアドレスを正しく入力してください
              </span>
            )}
          </div>

          <div className="contact_input">
            <label className="contact_label">
              お問い合わせ内容
              <span className="formStyle_input-required">必須</span>
            </label>
            <textarea
              name="message"
              className={"form-control " + (errors.message && errors.message.type === "required" ? 'error' : '') }
              rows="10"
              value={message}
              {...register("message", {
                required: "Required",
                minLength: 5,
              })}
              onChange={setForm}
            />
            {errors.message && errors.message.type === "required" && (
              <span className="text-danger">お問い合わせ内容を入力してください。</span>
            )}
            {errors.message && errors.message.type === "minLength" && (
              <span className="text-danger">5文字以上で入力してください。</span>
            )}
          </div>

          <div className="contact_privacy text-center">
            <a className="btnStyle-link" href="/privacy" target='_blank'>
              個人情報の取り扱い
            </a>
            について同意の上、送信内容をご確認ください。
          </div>

          <div className="contact_button">
            <button
              className="btnStyle-fill"
              type="submit"
              disabled={!formData.name || !formData.email || !formData.message}
            >
              同意して確認画面へ
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};
