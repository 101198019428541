import React, {useState, useEffect, useCallback} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RecommendPropertyModal from "../../top/RecommendPropertyModal";
import RecommendationTop from "./RecommendationTop";
import { RecoilRoot } from "recoil";

const MemoRecommendProperties = React.memo(
  ({ handleRecommendPropertyModal, oheyaGirlVideos }) => (
    <RecommendationTop
      handleRecommendPropertyModal={handleRecommendPropertyModal}
      oheyaGirlVideos={oheyaGirlVideos}
    />
  )
);

export const Recommendation = ({recommend}) => {
  const [showRecommendPropertyModal, setShowRecommendPropertyModal] =
    useState(false);
  const [recommendProperty, setRecommendProperty] = useState(undefined);
  const [recommendRoom, setRecommendRoom] = useState(undefined);
  const [recommendContract, setRecommendContract] = useState(undefined);
  const [recommendAccess, setRecommendAccess] = useState(undefined);
  const [recommendPropertyMovieUrl, setRecommendPropertyMovieUrl] =
    useState(undefined);
  const [recommendRoomMovieUrl, setRecommendRoomMovieUrl] = useState(undefined);

  const openRecommendPropertyModal = useCallback(
    (
      property,
      room,
      contracts,
      property_accesses,
      property_movie_url,
      room_movie_url
    ) => {
      setRecommendProperty(property);
      setRecommendRoom(room);
      setRecommendContract(contracts);
      setRecommendAccess(property_accesses);
      setRecommendPropertyMovieUrl(property_movie_url);
      setRecommendRoomMovieUrl(room_movie_url);

      setShowRecommendPropertyModal(true);
    },
    []
  );

  const closeRecommendPropertyModal = useCallback(() => {
    setShowRecommendPropertyModal(false);
  }, []);

  return (
    <>
      <div className="top_aboutRoom">
        {recommend.length !== 0 ? (
          <MemoRecommendProperties
            oheyaGirlVideos={recommend}
            handleRecommendPropertyModal={openRecommendPropertyModal}
          />
        ) : null}
      </div>
      {showRecommendPropertyModal && (
        <RecoilRoot>
          <RecommendPropertyModal
            property={recommendProperty}
            room={recommendRoom}
            contracts={recommendContract}
            property_accesses={recommendAccess}
            room_images={recommendRoom.room_images}
            room_movie_url={recommendRoomMovieUrl}
            property_movie_url={recommendPropertyMovieUrl}
            handleCloseModal={closeRecommendPropertyModal}
          />
        </RecoilRoot>
      )}
    </>
  );
};
