import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const CheckBox = ({ label, name, value, handleChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e) => {
            handleChange(name, e.target.checked);
          }}
          name={name}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
