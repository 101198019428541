import React from "react";
import Map2 from "../../PropertiesSearch/Map2";
import { RecoilRoot } from "recoil";

const MapViewModal = ({ setMapViewModal, property, setShowModal, room }) => {
  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY_202210;
  return (
    <>
      <div className="PropertiesModal_overlay">
        <div className="PropertiesModalBlack">
          <div className="PropertiesModalBlack_header">
            <div
              className="btnStyle-white"
              onClick={() => {
                setMapViewModal(false);
              }}
            >
              <img src="/assets/icon/icon-arrow-left.svg" />
              お部屋情報に戻る
            </div>
            <a
              href={`https://www.google.co.jp/maps/@${property.lat},${property.lon},12z`}
              target="_blank"
              className="btnStyle-fill"
            >
              地図アプリを開く
            </a>
          </div>
          <RecoilRoot>
            <Map2 googleMapsApiKey={googleMapsApiKey} property={property} room={room} />
          </RecoilRoot>
        </div>
      </div>
    </>
  );
};

export default MapViewModal;
