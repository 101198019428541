import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { SchedeleCheckInput } from "./ReservationForm/SchedeleCheckInput";
import { SchedeleCheckConfirm } from "./ReservationForm/SchedeleCheckConfirm";
import { SchedeleCheckComplete } from "./ReservationForm/SchedeleCheckComplete";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

const defaultData = {
  id: "",
  first_date_time: "",
  first_date: "",
  first_time: "",
  second_date_time: "",
  second_date: "",
  second_time: "",
  first_name: "",
  last_name: "",
  tel: "",
  email: "",
};

const steps = [{ id: "input" }, { id: "confirm" }, { id: "complete" }];

export default function ScheduleCheckReservationForm({ props, reserved }) {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const stepData = { formData, setForm, navigation };
  switch (step.id) {
    case "input":
      return (
        <>
          <Stepper activeStep={0} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SchedeleCheckInput {...stepData} props={props} reserved={reserved} />
        </>
      );
    case "confirm":
      return (
        <>
          <Stepper activeStep={1} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SchedeleCheckConfirm {...stepData} props={props} />
        </>
      );
    case "complete":
      return (
        <>
          <Stepper activeStep={2} alternativeLabel>
            <Step>
              <StepLabel>情報入力</StepLabel>
            </Step>
            <Step>
              <StepLabel>確認</StepLabel>
            </Step>
            <Step>
              <StepLabel>完了</StepLabel>
            </Step>
          </Stepper>
          <SchedeleCheckComplete {...stepData} props={props} />
        </>
      );
  }

  return <div></div>;
}
