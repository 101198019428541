import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import axios from "axios";
import { format, addMinutes } from "date-fns";
import { csrfToken } from "@rails/ujs";
import { roomLayoutEnums } from "../../../enums/layouts";
import comingSoon from "../../../../../assets/images/coming_soon.png";
import imgCharacter from "../../../../../assets/images/character/character07.png";
import { Modal } from "./Modal";
import { RecoilRoot } from "recoil";

export const SelfConfirm = ({ setForm, formData, navigation, props }) => {
  const [showModal, setShowModal] = useState(false);
  const { room, company_id, user_id, ...other } = props
  const property = room.property
  const contract = room.contracts[0]
  const property_access = property.property_accesses[0]
  const [reservation, setReservation] = useState(null);
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  const [disable, setDisable] = useState(false);

  function change() {
    document.getElementById("one").select();
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken();

  const onSubmit = (data) => {
    const dateTime = convertDateTimeStr();
    const customer_params = {
      customer: {
        first_name: data.first_name,
        last_name: data.last_name,
        tel: data.tel,
        email: data.email,
        company_id: company_id,
        reservations_attributes: [
          {
            start_at: format(new Date(dateTime), "yyyy-MM-dd HH:mm:ss"),
            finish_at: format(
              addMinutes(new Date(dateTime), 30),
              "yyyy-MM-dd HH:mm:ss"
            ),
            room_id: room.id,
            user_id: user_id,
          },
        ],
      },
    };

    axios
      .post("/api/portal/reservations", customer_params)
      .then((res) => {
        // console.log(res.data);
        setReservation(res.data.id);
      })
      .then(() => {
        setTimeout(() => {
          navigation.next();
        }, 1000);
      })
      .catch((e) => {
        document.body.innerHTML = e.response.data;
      });
    setTimeout(() => {
      change();
    }, 600);
    setDisable(true);
  };

  const convertDateTimeStr = () => {
    const date = new Date(formData.date);

    const [month, day, year] = [
      date.getMonth() + 1,
      date.getDate(),
      date.getFullYear(),
    ];

    let time = formData.time.replace("~", ":00");
    if (time.length !== 8) {
      time = "0" + time;
    }
    return year + "/" + month + "/" + day + " " + time;
  };

  const { id, date_time, first_name, last_name, tel, email } = formData;

  return (
    <Container>
      <div class="reservations">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="reservations_wrapper">
            <div class="reservationsProperty">
              <div class="reservationsProperty_top">
                <div class="reservationsProperty_heading">内見予約する物件</div>
              </div>

              <div class="reservationsProperty_card">
                <div class="reservationsProperty_photo">
                  <img src={room.movie_thumbnail_url || comingSoon} />
                </div>
                <div class="reservationsProperty_overview">
                  <div class="reservationsProperty_name">
                    {property.name} {room.name}
                  </div>
                  <div class="reservationsProperty_rent">
                    <span>
                      {contract?.rent ? (
                        <span>{(contract?.rent / 10000).toFixed(1)}</span>
                      ) : (
                        " お問い合わせください "
                      )}
                    </span>
                    {contract?.rent && "万円"}
                    (管:
                    {contract?.management_fee ? (
                      <>{contract?.management_fee.toLocaleString()}</>
                    ) : (
                      " "
                    )}
                    円）
                  </div>
                  <div class="reservationsProperty_other">
                    <span>
                      {property_access?.station_master.name}
                      {property_access?.station_master.name && "駅"}{" "}
                      {property_access?.walkby && "徒歩"}
                      {property_access?.walkby}
                      {property_access?.walkby && "分"}
                    </span>
                    <span>
                      {room?.layout && roomLayoutEnums[room?.layout]}
                      {room?.size && "/"} {room?.size && room?.size}
                      {room?.size && "㎡"}
                    </span>
                  </div>
                </div>
                <div
                  class="reservationsProperty_modallink"
                  onClick={() => setShowModal(true)}
                >
                  詳細を見る
                </div>
              </div>
            </div>

            <div class="reservationsConfirm">
              <div class="reservationsConfirm_box">
                <div class="reservationsConfirm_block">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    内覧希望日
                  </label>
                  <input
                    value={date_time}
                    readOnly
                    {...register("date_time")}
                  />
                </div>

                <div class="reservationsConfirm_block">
                  <label htmlFor="first-name">姓</label>
                  <input
                    value={last_name}
                    readOnly
                    {...register("last_name")}
                  />
                </div>
                <div class="reservationsConfirm_block">
                  <label htmlFor="first-name">名</label>
                  <input
                    value={first_name}
                    readOnly
                    {...register("first_name")}
                  />
                </div>

                <div class="reservationsConfirm_block">
                  <label htmlFor="first-name">メールアドレス</label>
                  <input value={email} readOnly {...register("email")} />
                </div>

                <div class="reservationsConfirm_block">
                  <label htmlFor="first-name">電話番号</label>
                  <input value={tel} readOnly {...register("tel")} />
                </div>

                <div class="reservationsConfirm_block none-margin">
                  <input
                    name="id"
                    id="one"
                    {...register("id")}
                    onFocus={setForm}
                    value={reservation}
                    style={{ height: "0px", width: "0px" }}
                  />
                </div>
              </div>

              <div
                className="reservationsConfirm_back"
                onClick={() => navigation.previous()}
              >
                予約内容を変更する
              </div>
            </div>
          </div>
          {/*reservations_wrapper*/}

          <div class="reservationsConfirm_action">
            <input
              type="submit"
              className="reservationsConfirm_submit btnStyle-fill"
              value="予約を確定する"
              disabled={disable}
            />
            <input
              type="button"
              className="btnStyle-white"
              value="物件の一覧ページに戻る"
            />
          </div>
        </form>
      </div>
      <div className="modalStyle d-none">
        <div className="modalStyle_wrapper">
          <div className="modalStyle_box reserveError">
            <div className="reserveError_heading">
              <img src={imgCharacter} />
              <span>選択した内見希望日の予約に失敗しました。</span>
            </div>

            <div className="reserveError_text">
              <span>
                内見希望日の日時を変更をして
                <br />
                再度内見予約をお試しください。
              </span>
            </div>

            <div className="reserveError_btn">
              <div className="btnStyle-fill">情報入力画面に戻る</div>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <RecoilRoot>
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            room={props.room}
            property={props.room.property}
            property_accesses={props.room.property.property_accesses}
            contracts={props.room.contracts}
            room_images={props.room.room_images}
            src={""}
            room_movie_url={props.room.room_movie_url}
            property_movie_url={props.room.property_movie_url}
          />
        </RecoilRoot>
      ) : null}
    </Container>
  );
};
