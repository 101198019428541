<template>
  <footer class="sticky-footer shadow">
    <div class="container">
      <div class="row align-items-center g-2" v-if="!isLastStep">
        <div class="col-6 text-caption">
          <p class="mb-0">
            操作が完了したら<br>
            次のステップに進みます<br>
          </p>
        </div>
        <div class="col-6 text-right"  v-if="!isLastStep">
          <a href=''
            class="btn btn-primary rounded-pill px-3 py-2 fw-weight-bold"
            :class='disabledClass'
            @click.prevent="onNextStepSelected()">{{nextStepBtnText}}</a>
        </div>
      </div>
      <div class="row align-items-center g-2" v-if="isLastStep">
        <div class="col-12 text-caption text-center">
          <p>以上で内見は終了です。お疲れ様でした。</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  props: ['btnText'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('flowDetail', ['flowDetailProceedable']),
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    },
    disabledClass() {
      return this.flowDetailProceedable ? '' : 'disabled'
    },
    nextStepBtnText() {
      return this.btnText == undefined ? '次のステップへ' : this.btnText
    }
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    }
  }
}
</script>

<style scoped>
</style>


