import React, { useState } from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconClose from "../../../../assets/images/icon/icon-close.svg";
import iconMap from "../../../../assets/images/icon/icon-map.svg";
import iconWalk from "../../../../assets/images/icon/icon-walk.svg";
import iconCalenderW from "../../../../assets/images/icon/icon-calender-white.svg";
import iconQuestion from "../../../../assets/images/icon/icon-question.svg";
import comingSoon from "../../../../assets/images/coming_soon.png";
import character from "../../../../assets/images/character/character06.png";
import iconBathtubGreen from "../../../../assets/images/icon/icon-bathtub-green.svg";
import iconBathtubGray from "../../../../assets/images/icon/icon-bathtub-gray.svg";
import iconHottubGreen from "../../../../assets/images/icon/icon-hot_tub-green.svg";
import iconHottubGray from "../../../../assets/images/icon/icon-hot_tub-gray.svg";
import iconCountertopsGreen from "../../../../assets/images/icon/icon-countertops-green.svg";
import iconCountertopsGray from "../../../../assets/images/icon/icon-countertops-gray.svg";
import iconLaundryGreen from "../../../../assets/images/icon/icon-local_laundry_service-green.svg";
import iconLaundryGray from "../../../../assets/images/icon/icon-local_laundry_service-gray.svg";
import iconWindyGreen from "../../../../assets/images/icon/icon-windy-green.svg";
import iconWindyGray from "../../../../assets/images/icon/icon-windy-gray.svg";
import iconBuildingGreen from "../../../../assets/images/icon/icon-building-green.svg";
import iconBuildingGray from "../../../../assets/images/icon/icon-building-gray.svg";
import iconBearGreen from "../../../../assets/images/icon/icon-bear-green.svg";
import iconBearGray from "../../../../assets/images/icon/icon-bear-gray.svg";
import iconTruckGreen from "../../../../assets/images/icon/icon-truck-green.svg";
import iconTruckGray from "../../../../assets/images/icon/icon-truck-gray.svg";
import iconCarGreen from "../../../../assets/images/icon/icon-car-green.svg";
import iconCarGray from "../../../../assets/images/icon/icon-car-gray.svg";
import iconDoorGreen from "../../../../assets/images/icon/icon-door-lock-box-green.svg";
import iconDoorGray from "../../../../assets/images/icon/icon-door-lock-box-gray.svg";
import iconMail from "../../../../assets/images/icon/icon-mail.svg";
import iconLine from "../../../../assets/images/icon/icon-line-green.svg";
import iconTwitter from "../../../../assets/images/icon/icon-twitter.svg";
import iconFacebook from "../../../../assets/images/icon/icon-facebook.svg";
import iconArrow from "../../../../assets/images/icon/icon-arrow-left.svg";
import { roomLayoutEnums } from "../../enums/layouts";
import { propertyStructureEnums } from "../../enums/structure";
import { propertyDirectionEnums } from "../../enums/direction";
import { feeUnitEnums } from "../../enums/unit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropertyModal from "./PropertyModal.jsx";
import { imageTypeEnums } from "../../enums/imageType";
import { propertyKindEnums } from "../../enums/propertyKind";
import MapViewModal from "../PropertiesSearch/ReservationForm/MapViewModal";
import { streetViewShowState } from "../PropertiesSearch/state";
import { useRecoilState } from "recoil";
import StreetViewModal from "../PropertiesSearch/StreetViewModal";

/**
 *
 * @param param0
 * @returns
 */
const RecommendPropertyModal = ({
  property,
  src,
  handleCloseModal,
  room,
  contracts,
  property_accesses,
  room_images,
  room_movie_url,
  property_movie_url
}) => {
  const handleGoToReservation = () => {
    location.href = `/reservations/${room.id}`;
  };
  const elapsedYearsAndMonth = (year, month) => {
    const nowDate = new Date();
    const buildingDate = new Date(1970, 0, 1);
    buildingDate.setTime(nowDate.getTime() - buildingDate.getTime());
    let elapsedYear = buildingDate.getUTCFullYear() - year;
    let elapsedMonth = buildingDate.getUTCMonth() - (month - 1);
    if (elapsedMonth < 0) {
      elapsedYear--;
      elapsedMonth += 12;
    }
    return `${year}年${month}月（築${elapsedYear}年${elapsedMonth}ヶ月）`;
  };

  const photo = [1, 2, 3, 4, 5];
  var thumbnail = photo.length;
  if (thumbnail >= 16) {
    thumbnail = 16;
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderNavSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: room_images.length,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    focusOnSelect: true,
  };

  const [isStreetView, setShowStreetView] = useRecoilState(streetViewShowState);
  const [tabIndex, setTabIndex] = useState(0);
  const underline = {
    position: "absolute",
    borderBottom: "solid 3px #04bfad",
    fontWeight: "bold",
    width: "100%",
    marginBottom: "0px",
    paddingBottom: "1px"
  };

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const [ModalAppear, setModalAppear] = useState(false);

  const oheyaGirlVideo = room_movie_url.filter(
    (item) => item.role === "oheya_girl"
  );
  const interiorVideo = room_movie_url.filter(
    (item) => item.role === "interior"
  );
  const appearanceVideo = property_movie_url.filter(
    (item) => item.role === "appearance"
  );
  const sharedVideo = property_movie_url.filter(
    (item) => item.role === "shared"
  );
  const surroundingVideo = property_movie_url.filter(
    (item) => item.role === "surrounding"
  );

  const moreThanSecondFloor = room.room_features.filter(
    (item) => item.feature.name === "2階以上の部屋"
  );
  const havePets = room.room_features.filter(
    (item) => item.feature.name === "ペット相談"
  );
  const separateBath = room.room_features.filter(
    (item) => item.feature.name === "バス・トイレ別"
  );
  const reHeating = room.room_features.filter(
    (item) => item.feature.name === "追い焚き"
  );
  const washroom = room.room_features.filter(
    (item) => item.feature.name === "洗面所独立"
  );
  const airConditioner = room.room_features.filter(
    (item) => item.feature.name === "エアコン"
  );
  const deliverBox = room.room_features.filter(
    (item) => item.feature.name === "宅配ボックス"
  );
  const washingMachine = room.room_features.filter(
    (item) => item.feature.name === "室内洗濯機置き場"
  );
  const autoLock = room.room_features.filter(
    (item) => item.feature.name === "オートロック"
  );

  const bathAndToilet = room.room_features.filter(
    (item) =>
      item.feature.name === "バス・トイレ別" ||
      item.feature.name === "追い焚き" ||
      item.feature.name === "洗面所独立" ||
      item.feature.name === "温水洗浄便座" ||
      item.feature.name === "浴室乾燥機" ||
      item.feature.name === "トイレあり"
  );

  const kitchen = room.room_features.filter(
    (item) =>
      item.feature.name === "ガスコンロ" ||
      item.feature.name === "IHコンロ" ||
      item.feature.name === "コンロ二口以上" ||
      item.feature.name === "システムキッチン" ||
      item.feature.name === "カウンターキッチン" ||
      item.feature.name === "冷蔵庫あり" ||
      item.feature.name === "都市ガス" ||
      item.feature.name === "プロパンガス" ||
      item.feature.name === "オール電化"
  );

  const security = room.room_features.filter(
    (item) =>
      item.feature.name === "モニタ付きドアホン" ||
      item.feature.name === "オートロック" ||
      item.feature.name === "管理人あり"
  );

  const insideFacility = room.room_features.filter(
    (item) =>
      item.feature.name === "家具家電付き" ||
      item.feature.name === "エアコン" ||
      item.feature.name === "床暖房" ||
      item.feature.name === "ガス暖房" ||
      item.feature.name === "石油暖房" ||
      item.feature.name === "ウォークインクローゼット" ||
      item.feature.name === "床下収納" ||
      item.feature.name === "トランクルーム" ||
      item.feature.name === "インターネット接続" ||
      item.feature.name === "CSアンテナ" ||
      item.feature.name === "BSアンテナ" ||
      item.feature.name === "CATV" ||
      item.feature.name === "有線放送" ||
      item.feature.name === "フローリング" ||
      item.feature.name === "室内洗濯機置き場" ||
      item.feature.name === "専用庭" ||
      item.feature.name === "出窓" ||
      item.feature.name === "バルコニー" ||
      item.feature.name === "ロフト付き" ||
      item.feature.name === "タイル貼り"
  );

  const sharing = room.room_features.filter(
    (item) =>
      item.feature.name === "宅配ボックス" ||
      item.feature.name === "エレベーターあり"
  );

  const others = room.room_features.filter(
    (item) =>
      item.feature.name === "1階の部屋" ||
      item.feature.name === "2階以上の部屋" ||
      item.feature.name === "最上階の部屋" ||
      item.feature.name === "角部屋" ||
      item.feature.name === "南向き" ||
      item.feature.name === "タワーマンション" ||
      item.feature.name === "バリアフリー" ||
      item.feature.name === "デザイナーズ" ||
      item.feature.name === "特定優良賃貸住宅" ||
      item.feature.name === "ペット相談" ||
      item.feature.name === "2人入居可" ||
      item.feature.name === "即入居可" ||
      item.feature.name === "保証人不要" ||
      item.feature.name === "楽器相談" ||
      item.feature.name === "事務所可" ||
      item.feature.name === "女性限定"
  );

  const handleToDate = (date) => {
    date = new Date(date);
    if (date.getMinutes() < 10) {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " " 
    } else {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " " 
    }

    return date;
  };

  const [mapViewModal, setMapViewModal] = useState(false);

  return (
    <>
      <div className="PropertiesModal">
        <div className="PropertiesModal_overlay">
          <div className="PropertiesModal_contents">
            <div className="PropertiesModal_header">
              <h3 className="PropertiesModal_title">
                {property.name} {room.name}
              </h3>
              <button
                onClick={handleCloseModal}
                className="PropertiesModal_close"
              >
                <img src={iconClose} />
              </button>
            </div>
            <div className="PropertiesModal_body">
              {/*動画エリア*/}
              <div className="PropertiesModal_movieArea">
                {/*動画カテゴリタブ*/}
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList className="PropertiesModal_tab">
                    <Tab className="PropertiesModal_tabItem">
                      <div style={tabIndex === 0 ? underline : null}>
                        TikTok
                      </div>
                    </Tab>
                    <Tab className="PropertiesModal_tabItem">
                      <div style={tabIndex === 1 ? underline : null}>内観</div>
                    </Tab>
                    <Tab className="PropertiesModal_tabItem">
                      <div style={tabIndex === 2 ? underline : null}>外観</div>
                    </Tab>
                    <Tab className="PropertiesModal_tabItem">
                      <div style={tabIndex === 3 ? underline : null}>
                        共有部
                      </div>
                    </Tab>
                    <Tab className="PropertiesModal_tabItem">
                      <div style={tabIndex === 4 ? underline : null}>
                        周辺環境
                      </div>
                    </Tab>
                  </TabList>

                  <TabPanel className="PropertiesModal_movie">
                    {oheyaGirlVideo.length === 0 ? (
                      <img src={comingSoon} />
                    ) : (
                      <VideoPlayer src={oheyaGirlVideo[0].movie_url} />
                    )}
                  </TabPanel>
                  <TabPanel className="PropertiesModal_movie">
                    {interiorVideo.length === 0 ? (
                      <img src={comingSoon} />
                    ) : (
                      <VideoPlayer src={interiorVideo[0].movie_url} />
                    )}
                  </TabPanel>
                  <TabPanel className="PropertiesModal_movie">
                    {appearanceVideo.length === 0 ? (
                      <img src={comingSoon} />
                    ) : (
                      <VideoPlayer src={appearanceVideo[0].movie_url} />
                    )}
                  </TabPanel>
                  <TabPanel className="PropertiesModal_movie">
                    {sharedVideo.length === 0 ? (
                      <img src={comingSoon} />
                    ) : (
                      <VideoPlayer src={sharedVideo[0].movie_url} />
                    )}
                  </TabPanel>
                  <TabPanel className="PropertiesModal_movie">
                    {surroundingVideo.length === 0 ? (
                      <img src={comingSoon} />
                    ) : (
                      <VideoPlayer src={surroundingVideo[0].movie_url} />
                    )}
                  </TabPanel>
                </Tabs>
                {/*動画カテゴリタブ end*/}
              </div>
              {/*動画エリア end*/}

              {/*概要*/}
              <div className="PropertiesModalOverview">
                <div className="PropertiesModalOverview_price">
                  <span className="PropertiesModalOverview_rent">
                    {contracts[0]?.rent ? (
                      <span>{(contracts[0].rent / 10000).toFixed(1)}</span>
                    ) : (
                      " お問い合わせください "
                    )}
                    {contracts[0]?.rent && "万円"}
                  </span>
                  <span className="PropertiesModalOverview_fee">
                    （管理費
                    <span>
                      {contracts[0]?.management_fee ? (
                        <>{contracts[0]?.management_fee.toLocaleString()}</>
                      ) : (
                        " "
                      )}
                    </span>
                    円）
                  </span>
                </div>
                <div className="PropertiesModalOverview_box">
                  <div className="PropertiesModalOverview_flex">
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">敷金</div>
                      <span>
                        <div className="PropertiesModalOverview_td">
                          {contracts[0]?.shikikin && contracts[0]?.shikikin}{" "}
                          {contracts[0]?.shikikin &&
                            feeUnitEnums[contracts[0]?.shikikin_unit]}
                        </div>
                      </span>
                    </div>
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">礼金</div>
                      <span>
                        <div className="PropertiesModalOverview_td">
                          {contracts[0]?.reikin && contracts[0]?.reikin}{" "}
                          {contracts[0]?.reikin &&
                            feeUnitEnums[contracts[0]?.reikin_unit]}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="PropertiesModalOverview_flex">
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">間取り</div>
                      <div className="PropertiesModalOverview_td">
                        {roomLayoutEnums[room?.layout]}
                      </div>
                    </div>
                    {/* <div className="PropertiesModalOverview_flexItem">
                      <DetailItem label={"面積"} values={[`${room.size}㎡`]} />
                    </div> */}
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">面積</div>
                      <div className="PropertiesModalOverview_td">
                        {room?.size}㎡
                      </div>
                    </div>
                  </div>
                  <div className="PropertiesModalOverview_flex">
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">階建</div>
                      <div className="PropertiesModalOverview_td">
                        {room?.floor}階 / {property?.floor}階
                      </div>
                    </div>
                    <div className="PropertiesModalOverview_flexItem">
                      <div className="PropertiesModalOverview_th">種別</div>
                      <div className="PropertiesModalOverview_td">
                        {propertyKindEnums[property?.kind]}
                      </div>
                    </div>
                  </div>
                  <div className="PropertiesModalOverview_tr">
                    <DetailItem
                      label={"築年月"}
                      values={[
                        elapsedYearsAndMonth(
                          property?.building_year,
                          property?.building_month
                        ),
                      ]}
                    />
                  </div>
                  <div className="PropertiesModalOverview_tr">
                    <DetailItem label={"住所"} values={[property?.address]} />
                  </div>

                  <div className="PropertiesModalOverview_action">
                    <div
                      className="btnStyle-fill"
                      onClick={() => {
                        setMapViewModal(true);
                      }}
                    >
                      <img src={iconMap} />
                      地図を見る
                    </div>
                    <div
                      className="btnStyle-fill"
                      onClick={() => {
                        setShowStreetView(true);
                      }}
                    >
                      <img src={iconWalk} />
                      ストリートビューを見る
                    </div>
                  </div>

                  {property_accesses?.map((item) => {
                    return (
                      <div className="PropertiesModalOverview_tr">
                        <div class="PropertiesModalOverview_item">
                          <label class="PropertiesModalOverview_th">交通</label>
                          <div class="PropertiesModalOverview_td">
                            {item.station_master.station_line_master.name} /{" "}
                            {item.station_master.name}駅 徒歩{item.walkby}分
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*概要 end*/}

              <div className="PropertiesModalReservation">
                <div className="PropertiesModalReservation_copy">
                  \ すぐに見れるよ！ /
                </div>
                <div className="btnStyle-fill" onClick={handleGoToReservation}>
                  <img src={iconCalenderW} />
                  セルフ内見予約をする
                </div>
                <a
                  href="/user_guide"
                  className="PropertiesModalReservation_what"
                >
                  <img src={iconQuestion} />
                  セルフ内見予約って？
                </a>
                <a
                  href="/contact"
                  className="PropertiesModalBottom_btn btnStyle-white"
                >
                  このお部屋についてのお問い合わせ
                </a>
              </div>

              <div className="PropertiesModalSlickDefault">
                <Slider
                  {...sliderSettings}
                  asNavFor={slider2}
                  ref={(slider) => setSlider1(slider)}
                  className="slider-for PropertiesModalSlickDefault_for"
                >
                  {room_images.map((value, index) => (
                    <div
                      className="PropertiesModalSlickDefault_item"
                      key={index}
                    >
                      <div className="PropertiesModalSlickDefault_img">
                        <img
                          src={value.name.url}
                          onClick={() => {
                            setModalAppear(true);
                          }}
                        />
                      </div>
                      <p>{imageTypeEnums[value.role]}</p>
                    </div>
                  ))}
                </Slider>

                <Slider
                  {...sliderNavSettings}
                  asNavFor={slider2}
                  ref={(slider) => setSlider1(slider)}
                  className="slider-nav PropertiesModalSlickDefault_nav"
                >
                  {room.room_images.map((value, index) => (
                    <div
                      className="PropertiesModalSlickDefault_navItem"
                      key={index}
                    >
                      <img
                        src={value.name.url}
                        onClick={() => {
                          setModalAppear(true);
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              <div className="PropertiesModalDesc">
                <div className="PropertiesModalDesc_character">
                  <div className="PropertiesModalDesc_balloon">
                    良いところあるじゃん！
                  </div>
                  <div className="PropertiesModal_characterImg">
                    <img src={character} />
                  </div>
                </div>

                <div className="PropertiesModalDesc_list">
                  <div className="PropertiesModalDesc_item">
                    {separateBath.length === 0 ? (
                      <>
                        <img src={iconBathtubGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          バストイレ別
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconBathtubGreen} />
                        <p>バストイレ別</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {reHeating.length === 0 ? (
                      <>
                        <img src={iconHottubGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          追い焚き機能
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconHottubGreen} />
                        <p>追い焚き機能</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {washroom.length === 0 ? (
                      <>
                        <img src={iconCountertopsGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          洗面所独立
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconCountertopsGreen} />
                        <p>洗面所独立</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {washingMachine.length === 0 ? (
                      <>
                        <img src={iconLaundryGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          室内洗濯機置場
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconLaundryGreen} />
                        <p>室内洗濯機置場</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {airConditioner.length === 0 ? (
                      <>
                        <img src={iconWindyGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>エアコン</p>
                      </>
                    ) : (
                      <>
                        <img src={iconWindyGreen} />
                        <p>エアコン</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {moreThanSecondFloor.length === 0 ? (
                      <>
                        <img src={iconBuildingGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>2階以上</p>
                      </>
                    ) : (
                      <>
                        <img src={iconBuildingGreen} />
                        <p>2階以上</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {havePets.length === 0 ? (
                      <>
                        <img src={iconBearGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          ペット相談可
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconBearGreen} />
                        <p>ペット相談可</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {deliverBox.length === 0 ? (
                      <>
                        <img src={iconTruckGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          宅配ボックス
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconTruckGreen} />
                        <p>宅配ボックス</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {property.have_parking === false ? (
                      <>
                        <img src={iconCarGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>駐車場</p>
                      </>
                    ) : (
                      <>
                        <img src={iconCarGreen} />
                        <p>駐車場</p>
                      </>
                    )}
                  </div>
                  <div className="PropertiesModalDesc_item">
                    {autoLock.length === 0 ? (
                      <>
                        <img src={iconDoorGray} />
                        <p style={{ color: "gray", opacity: 0.3 }}>
                          オートロック
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={iconDoorGreen} />
                        <p>オートロック</p>
                      </>
                    )}
                  </div>
                </div>

                <div className="PropertiesModalDesc_text">
                  <p>{room.introduction}</p>
                </div>

                <div className="PropertiesModalDesc_tagArea">
                  {room.room_features.map((item) => {
                    return (
                      <div className="PropertiesModalDesc_tag">
                        {item.feature.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="PropertiesModalDetail">
                <div className="PropertiesModalDetail_heading">費用</div>
                <div className="PropertiesModalDetail_table">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">賃料</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.rent ? (
                        <span>{(contracts[0]?.rent / 10000).toFixed(1)}</span>
                      ) : (
                        " お問い合わせください "
                      )}
                      {contracts[0]?.rent && "万円"}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">管理費等</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.management_fee ? (
                        <>{contracts[0]?.management_fee.toLocaleString()}</>
                      ) : (
                        " "
                      )}
                      円
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">敷金</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.shikikin && contracts[0]?.shikikin}{" "}
                      {contracts[0]?.shikikin &&
                        feeUnitEnums[contracts[0]?.shikikin_unit]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">保証金</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.guaranty_fee && contracts[0]?.guaranty_fee}{" "}
                      {contracts[0]?.guaranty_fee &&
                        feeUnitEnums[contracts[0]?.guaranty_fee_unit]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">礼金</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.reikin && contracts[0]?.reikin}{" "}
                      {contracts[0]?.reikin &&
                        feeUnitEnums[contracts[0]?.reikin_unit]}
                    </div>
                  </div>
                </div>

                <div className="PropertiesModalDetail_heading">
                  お部屋の設備・特徴
                </div>
                <div className="PropertiesModalDetail_table">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">バス・トイレ</div>
                    <div className="PropertiesModalDetail_td">
                      {bathAndToilet.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">キッチン</div>
                    <div className="PropertiesModalDetail_td">
                      {kitchen.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">セキュリティ</div>
                    <div className="PropertiesModalDetail_td">
                      {security.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">室内設備</div>
                    <div className="PropertiesModalDetail_td">
                      {insideFacility.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">共有部</div>
                    <div className="PropertiesModalDetail_td">
                      {sharing.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">その他</div>
                    <div className="PropertiesModalDetail_td">
                      {others.map((item, i, arr) => {
                        return (
                          <>
                            {item.feature.name}
                            {i != arr.length - 1 ? "、 " : ""}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="PropertiesModalDetail_heading">
                  その他お部屋情報
                </div>
                <div className="PropertiesModalDetail_table">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">建物名・部屋</div>
                    <div className="PropertiesModalDetail_td">
                      {property.name} {room.name}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">階 / 階建</div>
                    <div className="PropertiesModalDetail_td">
                      {room?.floor}階 / {property?.floor}階
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">間取り</div>
                    <div className="PropertiesModalDetail_td">
                      {roomLayoutEnums[room?.layout]} ({room?.layout_detail})
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">占有面積</div>
                    <div className="PropertiesModalDetail_td">
                      {room?.size}㎡
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">主要採光面</div>
                    <div className="PropertiesModalDetail_td">
                      {propertyDirectionEnums[room?.direction]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">物件種目</div>
                    <div className="PropertiesModalDetail_td">
                      {propertyKindEnums[property?.kind]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">築年月</div>
                    <div className="PropertiesModalDetail_td">
                      {[
                        elapsedYearsAndMonth(
                          property?.building_year,
                          property?.building_month
                        ),
                      ]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">
                      建物構造・工法
                    </div>
                    <div className="PropertiesModalDetail_td">
                      {propertyStructureEnums[property?.structure]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">契約期間</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.term && `${contracts[0]?.term}年`}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">仲介手数料</div>
                    <div className="PropertiesModalDetail_td">1万円</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">更新料</div>
                    <div className="PropertiesModalDetail_td">
                      {contracts[0]?.renewal_fee && contracts[0].renewal_fee}
                      {contracts[0]?.renewal_fee_unit && feeUnitEnums[contracts[0]?.renewal_fee_unit]}
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">現況</div>
                    <div className="PropertiesModalDetail_td">空家</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">入居日</div>
                    <div className="PropertiesModalDetail_td">即日</div>
                  </div>
                </div>

                <div className="PropertiesModalDetail_table">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">情報公開日</div>
                    <div className="PropertiesModalDetail_td">
                      {room.published_at && handleToDate(room.published_at)}
                    </div>
                  </div>
                  {/* <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">
                      次回更新予定日
                    </div>
                    <div className="PropertiesModalDetail_td">
                      2022年1月19日
                    </div>
                  </div> */}
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">物件番号</div>
                    <div className="PropertiesModalDetail_td">
                      {/* 12345678 */}
                      {property.id}
                    </div>
                  </div>
                </div>

                {/* <div className="PropertiesModalDetail_heading">
                  その他お部屋情報
                </div>

                <div className="PropertiesModalDetail_action">
                  <div className="btnStyle-fill">
                    <img src={iconMap} />
                    地図を見る
                  </div>
                  <div className="btnStyle-fill">
                    <img src={iconWalk} />
                    ストリートビューを見る
                  </div>
                </div>

                <div className="PropertiesModalDetail_table simple">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">コンビニ</div>
                    <div className="PropertiesModalDetail_td">
                      ファミリーマート 渋谷駅前店まで191m（徒歩3分）
                    </div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">
                      ドラッグストア
                    </div>
                    <div className="PropertiesModalDetail_td">
                      くすりの福太郎 渋谷駅前店まで453m（徒歩6分）
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="PropertiesModalReservation">
                <div className="PropertiesModalReservation_copy">
                  \ すぐに見れるよ！ /
                </div>
                <div className="btnStyle-fill" onClick={handleGoToReservation}>
                  <img src={iconCalenderW} />
                  セルフ内見予約をする
                </div>
                <a
                  href="/user_guide"
                  className="PropertiesModalReservation_what"
                >
                  <img src={iconQuestion} />
                  セルフ内見予約って？
                </a>
              </div>

              <div className="PropertiesModalBottom">
                <a
                  href="/contact"
                  className="PropertiesModalBottom_btn btnStyle-white"
                >
                  このお部屋についてのお問い合わせ
                </a>
              </div>

              <div className="PropertiesModalShare">
                <div className="PropertiesModalShare_list">
                  <div className="PropertiesModalShare_item">
                    <img src={iconMail} />
                    <span>メール</span>で送る
                  </div>
                  <div className="PropertiesModalShare_item">
                    <img src={iconLine} />
                    <span>LINE</span>で送る
                  </div>
                  <div className="PropertiesModalShare_item">
                    <img src={iconTwitter} />
                    <span>Twitter</span>シェア
                  </div>
                  <div className="PropertiesModalShare_item">
                    <img src={iconFacebook} />
                    <span>Facebook</span>シェア
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*地図を見るモーダル*/}
          {/* <div className="PropertiesModalBlack map-view">
            <div className="PropertiesModalBlack_header">
              <div className="btnStyle-white">
                <img src={iconArrow} />
                お部屋情報に戻る
              </div>
              <div className="btnStyle-fill">地図アプリを開く</div>
            </div>
          </div> */}
          {mapViewModal ? (
            <MapViewModal
              setMapViewModal={setMapViewModal}
              property={property}
              room={room}
              // setShowModal={setShowModal}
            />
          ) : null}
          {/*地図を見るモーダル end*/}

          {/*ストリートビューモーダル*/}
          {isStreetView && <StreetViewModal property={property} />}
          {/*ストリートビューモーダル end*/}

          {/*画像を拡大モーダル*/}

          {/* <div className="PropertiesModalBlack image">
            <div className="PropertiesModalBlack_header">
              <div className="btnStyle-white">
                <img src={iconArrow} />
                お部屋情報に戻る
              </div>
              <div className="PropertiesModalBlack_number">1/10</div>
            </div>
            <Slider
              {...sliderSettings}
              className="slider-for PropertiesModalBlackSlider_for"
            >
              {room.room_images.map((value, index) => (
                <div className="PropertiesModalBlackSlider_item" key={index}>
                  <div className="PropertiesModalBlackSlider_img">
                    <img src={value.name.url} />
                  </div>
                  <p>{value}</p>
                </div>
              ))}
            </Slider>
            <Slider
              {...sliderNavSettings}
              className="slider-nav PropertiesModalBlackSlider_nav"
            >
              {room.room_images.map((value, index) => (
                <div className="PropertiesModalBlackSlider_navItem" key={index}>
                  <img src={value.name.url} />
                </div>
              ))}
            </Slider>
          </div> */}
          {/*画像を拡大モーダル end*/}
        </div>
      </div>
      {/*<div id="recommend-property-overlay">
        <div id="recommend-property-content">
          <div id="recommend-property-header">
            <button onClick={handleCloseModal}>×</button>
          </div>
          <div id="recommend-property-inner-content">
            <div className="left-section">
              <div>{property.name}</div>
              <div>10.2万円（管理費8,000円）</div>
              <div className="room-detail">
                <DetailItem label={"敷金"} values={["1ヶ月"]} />
                <DetailItem label={"礼金"} values={["1ヶ月"]} />
                <DetailItem label={"間取り"} values={["1DK"]} />
                <DetailItem label={"面積"} values={["30.56㎡"]} />
                <DetailItem
                  label={"築年月"}
                  values={[
                    elapsedYearsAndMonth(
                      property.building_year,
                      property.building_month
                    ),
                  ]}
                />
                <DetailItem label={"住所"} values={[property.address]} />
                <div className="detail-button-group">
                  <Button variant="contained" color="primary">
                    地図を見る
                  </Button>
                  <Button variant="contained" color="primary">
                    ストリートビューを見る
                  </Button>
                </div>
                <DetailItem
                  label={"交通"}
                  values={[
                    "JR山手線/渋谷駅 徒歩10分",
                    "JR山手線/原宿駅 徒歩18分",
                    "東京メトロ銀座線/表参道駅 徒歩15分",
                  ]}
                />
              </div>
              <div className="button-group">
                <label>セルフ内見可能</label>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGoToReservation}
                >
                  内見予約する
                </Button>
                <Button variant="contained">物件についてのお問い合わせ</Button>
              </div>
            </div>
            <div className="right-section">
              <VideoPlayer src={src} />
            </div>
          </div>
        </div>
      </div>*/}
      {ModalAppear ? (
        <PropertyModal
          room_images={room_images}
          setModalAppear={setModalAppear}
        />
      ) : null}
    </>
  );
};
const DetailItem = ({ label, values }) => (
  <div className="PropertiesModalOverview_item">
    <label className="PropertiesModalOverview_th">{label}</label>
    <div className="PropertiesModalOverview_td">
      {values.map((value, index) => (
        <label key={index}>{value}</label>
      ))}
    </div>
  </div>
);
export default RecommendPropertyModal;
