import React, { useRef, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Properties from "./Properties";

const RecommendProperties = React.memo(
  ({ tiktok_videos, oheyaGirlVideos, handleRecommendPropertyModal }) => {
    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      centerMode: true,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "0px",
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: "50px",
            slidesToShow: 1,
          },
        },
      ],
    };
    const sliderRef = useRef();
    const playArrRef = useRef([]);
    const audioArrRef = useRef([]);
    const goToNext = useCallback((current) => {
      let nextNum = 0;
      if (current < oheyaGirlVideos.length - 1) {
        nextNum = current + 1;
      }
      sliderRef.current.slickGoTo(nextNum);
      playArrRef.current[nextNum].current.play();
    }, []);

    const properties = oheyaGirlVideos.map((item, index) => (
      <div className="recommend_item" key={item.id}>
        <Properties
          property={item.room.property}
          room={item.room}
          contracts={item.room.contracts}
          property_accesses={item.room.property.property_accesses}
          property_movie_url={item.property_movie_url}
          room_movie_url={item.room_movie_url}
          videoUrl={item.movie_url}
          playArrRef={playArrRef}
          audioArrRef={audioArrRef}
          myNumber={index}
          handleEndCatch={goToNext}
          handleRecommendPropertyModal={handleRecommendPropertyModal}
        />
      </div>
    ));

    return (
      <div className="recommend">
        <Slider {...sliderSettings} ref={sliderRef}>
          {properties}
        </Slider>
      </div>
    );
  }
);

//自動再生、ミュートする場合
// const isVideoInFullscreen = () => {
//   if (document.fullscreenElement && document.fullscreenElement.nodeName === 'VIDEO') {
//     return true;
//   }
// videoElement.autoplay = true;
// };

// return (
// <VideoWindow
//   onMouseOver={() => setMouseOver(true)}
//   onMouseLeave={() => setMouseOver(false)}
//   ref={WindowRef}
// >
//   {Object.entries(videos).map(([index, videoPath]) => {
//     return (
//       <video
//         id={`videoMedia-${index}`}
//         key={index}
//         src={videoPath}
//
//       />
//     );
//   })}

//   <Fade in={mouseOver}>
//     <div ref={fade}>
//       <PlayButton>
//         <IconButton
//           dark
//           id={`btnOverlay${playing ? 'Pause' : 'Play'}`}
//           onClick={playing ? clickPause : clickPlay}
//         >
//           {playing ? (
//             <PauseRounded />
//           ) : (
//             <PlayArrowRounded />
//           )}
//         </IconButton>
//       </PlayButton>
//       <Menubar>
//         <IconButton dark id={`btnFullscreen`} onClick={toggleFullScreen}>
//         </IconButton>
//       </Menubar>
//     </div>
//   </Fade>
// </VideoWindow>
// );

export default RecommendProperties;
