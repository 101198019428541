import React, { useState } from "react";
import rightIconImage from "../../../../../assets/images/icon/icon-arrow-right-black.svg";

const Accordion = (props, i) => {
  const prefecture = props.prefecture
  const [expand, setExpand] = useState(false);
  const [selected, setSelected] = useState(null)

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null)
    }

    setSelected(i)
  }

  return (
    <>
      <div className={'searchArea_header d-flex justify-content-between' + ' ' + (selected === i ? 'active' : '')} key={`prefecture_${prefecture.id}`} onClick={() => {
        props.onClick()
        setExpand(!expand)
        toggle(i)
      }}>
        {prefecture.text}
        <img src={rightIconImage} />
      </div>
      {expand && props.children}
    </>
  )
}

export default Accordion;
