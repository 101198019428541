$(function(){
  const $headerMenu = $('.js-header-menu');
  const $headerOpen = $('.js-header-open');
  const $headerClose = $('.js-header-close');
  const activeClass = 'active';

  $headerOpen.on('click', function() {
    $headerMenu.addClass(activeClass);
  });

  $headerClose.on('click', function() {
    $headerMenu.removeClass(activeClass);
  });
});
