<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
      <div class="contents-box my-2" v-if="customerTel.length == 0">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold">{{callTitleText}}</h3>
          <div class="text-center">
            <img src="~navi/phone_tell.png" width="160" alt="電話をかける">
          </div>
          <p class="my-3">{{callSubTitleText}}</p>
          <div class="text-center mb-1">
            <a :href="hrefTel"
              class="btn btn-primary rounded-pill px-4 py-2">{{callBtnText}}</a>
          </div>
        </div>
      </div>
      <div class="contents-box my-2" v-else>
        <div class="container py-3">
          <h3 class="h5 font-weight-bold">{{callRequestTitleText}}</h3>
          <div class="text-center">
            <img src="~navi/phone_tell.png" width="160" alt="入電リクエストを送る">
          </div>
          <p class="my-3">{{callRequestSubTitleText}}</p>
          <div class="text-center mb-1">
            <a :href="hrefTel"
              class="btn btn-primary rounded-pill px-4 py-2"
              :class='actionBtnDisabledClass'
              @click.prevent="createCallRequestMsg">{{callRequestBtnText}}</a>
          </div>
        </div>
      </div>
      <div class="contents-box my-2" v-if="flowDetailProceedable">
        <div class="container py-3">
          <h3 class="h5 font-weight-bold">通話が完了しました</h3>
          <div class="row align-items-center">
            <div class="col-4 text-center g-2">
              <img src="~/navi/navi_ok.png" height="160" class="mx-auto">
            </div>
            <div class="col-8 g-2">
              <p class="mb-1">
                通話が完了しました。<br />次のステップに進めるようになりましたので、以下のボタンから次のステップにお進みください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
import axios from 'axios'
export default {
  name: 'Call',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome },
  props: ['flowDetail'],
  async activated() {
    try {
      const res = await axios.get(`/api/navi/reservations/${this.reservationToken}`)
      if((this.reservationStep == res.data.reservation.step) && !res.data.reservation.call_finished){
        this.onCallRequesting = false
        this.$store.commit('flowDetail/setProceedable', false)
        this.fetchCallFinished()
        this.timer = setInterval(this.fetchCallFinished, 5000)
      }
    } catch(e) {
      alert(e)
    }
  },
  data() {
    return {
      timer: '',
      onCallRequesting: false,
    }
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep', 'reservationToken']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('flowDetail', ['flowDetailProceedable']),
    ...mapGetters('user', ['userTel']),
    ...mapGetters('customer', ['customerTel']),
    hrefTel() {
      return `tel:${this.userTel}`
    },
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    },
    callTitleText(){
      var title = (this.flowDetail.title == null || this.flowDetail.title == '') ? '電話を掛ける' : this.flowDetail.title
      return title
    },
    callRequestTitleText(){
      var title = (this.flowDetail.title == null || this.flowDetail.title == '') ? '入電リクエストを出す' : this.flowDetail.title
      return title
    },
    callSubTitleText(){
      var subTitle = (this.flowDetail.sub_title == null || this.flowDetail.sub_title == '') ? '下記のボタンをタップし、担当者に入電してください。(通話料は、申し訳ございませんがご負担をお願い致します。)' : this.flowDetail.sub_title
      return subTitle
    },
    callRequestSubTitleText(){
      var subTitle = (this.flowDetail.sub_title == null || this.flowDetail.sub_title == '') ? '下記のボタンをタップし、担当者に入電リクエストを送付してください。直ぐに担当者よりご連絡をさせて頂きます。' : this.flowDetail.sub_title
      return subTitle
    },
    callBtnText() {
      var msg = (this.flowDetail.text == null || this.flowDetail.text == '') ? '電話をかける' : this.flowDetail.text
      return msg
    },
    callRequestBtnText() {
      var msg = (this.flowDetail.text == null || this.flowDetail.text == '') ? '入電リクエストを出す' : this.flowDetail.text
      return this.onCallRequesting ? '入電リクエスト中...' : msg
    },
    actionBtnDisabledClass() {
      return this.onCallRequesting ? 'disabled' : ''
    },

  },
  methods: {
    async onNextStepSelected() {
      await clearInterval(this.timer)
      await this.changeCallNotFinished()
      this.$emit('goToNextStep')
    },
    async onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    },
    async fetchCallFinished() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}/call_finished`);
        this.$store.commit('flowDetail/setProceedable', res.data.call_finished)
      } catch {
        alert('ERROR');
      }
    },
    async changeCallNotFinished() {
      try {
        const res = await axios.post(`/api/navi/reservations/${this.reservationToken}/call_not_finished`);
      } catch {
        alert('ERROR');
      }
    },
    async createCallRequestMsg(){
      this.onCallRequesting = true;
      this.$emit('createCallRequestMsg', `[システムメッセージ] 入電リクエストがありました。 担当者は、${this.customerTel} 宛に入電をお願いします。`)
    }
  },

}
</script>

<style scoped>
</style>

