import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const WalkFromStation = ({
  value,
  handleSelectValue,
  property_accesses_walkby_lteq,
}) => {
  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">駅徒歩</div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-1"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={1}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "1"
            }
          />
          <label htmlFor="radio-1" className="searchRent_name">
            1分以内
          </label>
        </li>

        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-2"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={5}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "5"
            }
          />
          <label htmlFor="radio-2" className="searchRent_name">
            5分以内
          </label>
        </li>

        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-3"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={7}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "7"
            }
          />
          <label htmlFor="radio-3" className="searchRent_name">
            7分以内
          </label>
        </li>

        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-4"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={10}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "10"
            }
          />
          <label htmlFor="radio-4" className="searchRent_name">
            10分以内
          </label>
        </li>

        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-5"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={15}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "15"
            }
          />
          <label htmlFor="radio-5" className="searchRent_name">
            15分以内
          </label>
        </li>

        <li className="searchRent_item formStyle_radio">
          <input
            id="radio-6"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={20}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "20"
            }
          />
          <label htmlFor="radio-6" className="searchRent_name">
            20分以内
          </label>
        </li>

        <li className="searchRent_item col-100 formStyle_radio">
          <input
            id="radio-7"
            name="detail[property_accesses_walkby_lteq]"
            type="radio"
            onChange={(e) => {
              console.log(e.target.value);
              handleSelectValue(e.target.value);
            }}
            value={0}
            defaultChecked={
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq &&
              property_accesses_walkby_lteq?.property_accesses_walkby_lteq ===
                "0"
            }
          />
          <label htmlFor="radio-7" className="searchRent_name">
            指定なし
          </label>
        </li>
      </ul>
    </div>
  );
};

export default WalkFromStation;
