import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScriptNext,
  MarkerClusterer,
  StreetViewPanorama,
  Marker
} from "@react-google-maps/api";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  propertiesState,
  centerState,
  placeChangedState,
  isCurrentLocationState,
  boundState,
} from "./state";
import axios from "axios";
import SearchForm from "./SearchForm";
import { PropertiesApiPath } from "./common";
import MapProperties3 from "../top/MapProperties3";
import iconMapArea from "../../../../assets/images/icon/icon-map-area.svg";

const containerStyle = {
  width: "100%",
  height: "90%",
};

const Map3 = (props) => {
  const [properties, setProperties] = useRecoilState(propertiesState);
  const [center, setCenter] = useRecoilState(centerState);
  const [bound, setBound] = useRecoilState(boundState);
  const [libraries] = useState(["places"]);
  const [mapObj, setMapObj] = useState(null);
  const placeChanged = useRecoilValue(placeChangedState);
  const [zoom, setZoom] = useState(13);

  useEffect(() => {
    if (props.isCurrentLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setZoom(14);
        setCenter({
          lat: Number(latitude),
          lng: Number(longitude),
        });
      });
    }
  }, []);

  useEffect(() => boundsAreaSearch(mapObj), [placeChanged]);

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  const boundsAreaSearch = (map) => {
    const bounds = map?.getBounds();
    if (!center && !bounds) return;

    const boundParams = {
      south_west_lat: bounds ? bounds?.getSouthWest()?.lat() : center.lat - 0.1,
      north_east_lat: bounds ? bounds?.getNorthEast()?.lat() : center.lat + 0.1,
      south_west_lon: bounds ? bounds?.getSouthWest()?.lng() : center.lng - 0.5,
      north_east_lon: bounds ? bounds?.getNorthEast()?.lng() : center.lng + 0.5,
    };

    // axios.get(PropertiesApiPath, {
    //   params: bound || boundParams
    // }).then(res => {
    //   setBound(boundParams)
    //   setProperties(res.data.properties);
    // })
    // setProperties(props.property);
  };

  const addBoundsAreaSearchEvent = (controlDiv, map) => {
    const controlButton = document.createElement("button");
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.color = "#666";
    controlButton.style.borderRadius = "999px";
    controlButton.style.fontSize = "14px";
    controlButton.style.fontWeight = "600";
    controlButton.style.marginTop = "8px";
    controlButton.style.marginRight = "2px";
    controlButton.style.padding = "3px 8px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.innerHTML = "このエリアで検索";
    controlDiv.appendChild(controlButton);
    controlButton.addEventListener("click", () => boundsAreaSearch(map));
  };

  const onLoad = (map) => {
    setMapObj(map);
    const customControlDiv = document.createElement("div");
    addBoundsAreaSearchEvent(customControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(customControlDiv);
    props.isCurrentLocation && boundsAreaSearch(map);
  };

  const onZoomChanged = () => boundsAreaSearch(mapObj);

  const clusterStyles = [
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
  ];

  const options = {
    gridSize: 32,
    styles: clusterStyles,
    maxZoom: 15,
  };

  // const latLon = {
  //   lat: parseFloat(props.property.lat),
  //   lng: parseFloat(props.property.lon),
  // };

  const latLon = props.property[0]
    ? {
        lat: parseFloat(props.property[0].lat),
        lng: parseFloat(props.property[0].lon),
      }
    : {
        lat: 35.6809591, 
        lng: 139.7673068
      };

  return (
    <LoadScriptNext
      googleMapsApiKey={props.googleMapsApiKey}
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={latLon}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
        options={mapOptions}
        className="top_googleMap"
      >
        {props.isStreetView ? (
          <>
            <StreetViewPanorama
              position={latLon}
              visible={true}
              pov={{ heading: 270, pitch: 0 }}
            />
            <Marker position={latLon} />
          </>
        ) : (
          <>
            <SearchForm />
            <MarkerClusterer options={options} key="1">
              {(clusterer) => {
                const component = [];
                props.property?.map((property) => {
                component.push(
                  <MapProperties3
                    key={property.id}
                    property={property}
                    clusterer={clusterer}
                  ></MapProperties3>
                );
                });
                return component;
              }}
            </MarkerClusterer>
          </>
        )}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map3;
