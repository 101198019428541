import React from "react";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { csrfToken } from "@rails/ujs";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

export const ContactConfirm = ({ formData, navigation }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken();

  const onSubmit = (data) => {
    const contact_params = {
        name: data.name,
        name_kana: data.name_kana,
        email: data.email,
        message: data.message,
    };

    axios
      .post(
        "/api/portal/contact",
        contact_params
      )
      .catch((e) => {
        document.body.innerHTML = e.response.data;
      });

    navigation.next();
  };

  const { name, name_kana, email, message } = formData;

  return (
    <div className="contactConfirm">
      <div className="contactConfirm_info">
        お問い合わせ内容をご確認ください。
      </div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="contactConfirm_wrapper">
            <div className="contactConfirm_input">
              <label className="contactConfirm_label">お名前</label>
              <TextField
                name="name"
                margin="normal"
                multiline
                defaultValue={name}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                {...register("name")}
              />
            </div>

            <div className="contactConfirm_input">
              <label className="contactConfirm_label">お名前（ふりがな）</label>
              <TextField
                name="name_kana"
                margin="normal"
                multiline
                defaultValue={name_kana}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                {...register("name_kana")}
              />
            </div>

            <div className="contactConfirm_input">
              <label className="contactConfirm_label">メールアドレス</label>
              <TextField
                name="email"
                margin="normal"
                multiline
                defaultValue={email}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                {...register("email")}
              />
            </div>

            <div className="contactConfirm_input">
              <label class="contactConfirm_label">お問い合わせ内容</label>
              <TextField
                name="message"
                margin="normal"
                fullWidth
                multiline
                rows={10}
                defaultValue={message}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                {...register("message")}
              />
            </div>
          </div>

          <div className="contactConfirm_buttonWrapper">
            <div className="contactConfirm_button">
              <button type="submit" className="btnStyle-fill">
                送信する
              </button>
            </div>

            <div className="contactConfirm_button">
              <button
                onClick={() => navigation.previous()}
                className="btnStyle-white"
              >
                修正する
              </button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};
