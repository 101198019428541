<template>
  <div class="stepper-contents stepper-scroll-icon text-center mt-3">
    <h1 class="h6">
      <img src="~navi/icon_scroll.png" width='16px'>
      内見のステップを確認しよう
    </h1>
    <ul class="stepper stepper-scroll d-flex px-0 mb-1" id='stepper'>
      <li v-for="(fd, index) in flowDetailsAll" class="list-unstyled" :class="activeClass(fd)" :id="activeStepperId(index)">
        <a class='step' :class='clickable(fd)' @click.prevent='onStepSelected(fd)'>
          <span class="circle">{{fd.order}}</span>
          <span class="label">{{fd.title}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Step',
  data() {
    return {
      currentStepInDB: 0
    }
  },
  async mounted() {
    await this.fetchCurrentStepInDB()
    this.focusToActiveStep()
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep', 'reservationToken']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    activeClass: function(){
      return function(fd){
        return fd.order == this.reservationStep ? 'active' : ''
      }
    },
    activeStepperId: function() {
      return function(index){
        return `stepper_active_${index+1}`
      }
    },
    clickable: function(fd){
      return function(fd){
        return fd.order <= this.currentStepInDB ? 'clickable': ''
      }
    }
  },
  methods: {
    async onStepSelected(fd) {
      try {
        if(fd.order <= this.currentStepInDB){
          this.$store.commit('reservation/setStep', fd.order)
          this.$emit('changeCurrentStep')
        }
      } catch(e) {
        alert(e)
      }
    },
    async fetchCurrentStepInDB() {
      try {
        const res = await axios.get(`/api/navi/reservations/${this.reservationToken}`)
        this.currentStepInDB = res.data.reservation.step
      } catch(e) {
        alert(e)
      }
    },
    focusToActiveStep() {
      //TODO: 手動で戻った時などうまく動いてない
      var stepper = document.getElementById('stepper');
      stepper.scrollLeft = 0
      var screenWidth = window.parent.screen.width
      var activeStepper = document.getElementById(`stepper_active_${this.reservationStep}`)
      var activeStepperWidth = activeStepper.getBoundingClientRect().x
      if(activeStepperWidth + 70 > screenWidth){
        const px = (activeStepperWidth + 70 - screenWidth)
        stepper.scrollLeft = px
      }else{
      }
    },
  },
  watch: {
    reservationStep: function(newValue, oldValue){
      if(newValue > this.currentStepInDB){
        this.focusToActiveStep()
        this.currentStepInDB = newValue
      }
    },
  }

}
</script>

<style scoped>
.step {
  cursor: default;
}
.clickable {
  cursor: pointer;
}
</style>









