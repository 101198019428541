import React, { useState, useCallback, useMemo, useEffect } from "react";
import MapContent from "./Map";
import AreaSearch from "./PropertiesSearch/AreaSearch";
import StationSearch from "./PropertiesSearch/StationSearch";
import RecommendProperties from "./RecommendProperties";
import MapProperties from "./MapProperties";
import Faq from "./Faq";
import loginRegisterImage from "../../../../assets/images/elite_admin/login-register.jpg";
import RecommendPropertyModal from "./RecommendPropertyModal";
import character from "../../../../assets/images/character/character01.png";
import bubble_1 from "../../../../assets/images/top/bubble_1.svg";
import bubble_2 from "../../../../assets/images/top/bubble_2.svg";
import bubble_3 from "../../../../assets/images/top/bubble_3.svg";
import bubble_4 from "../../../../assets/images/top/bubble_4.svg";
import catchcopy from "../../../../assets/images/top/catchcopy.svg";
import aboutRoom from "../../../../assets/images/top/about_room.svg";
import aboutLifereechintai from "../../../../assets/images/top/about_lifereechintai.svg";
import iconQuestionLeft from "../../../../assets/images/top/icon_question_left.png";
import iconQuestionRight from "../../../../assets/images/top/icon_question_right.png";
import subCatchcopy from "../../../../assets/images/top/sub_catchcopy.svg";

import iconLocation from "../../../../assets/images/top/icon_location_36x36.svg";
import iconInstagram from "../../../../assets/images/top/icon_instagram.svg";

import iconTag from "../../../../assets/images/top/icon_tag.svg";
import separation from "../../../../assets/images/top/separation.png";


import background from "../../../../assets/images/top/bg.png";
import MapSearch from "./PropertiesSearch/MapSearch";

// 再描画を防ぐためmemo化
const MemoRecommendProperties = React.memo(
  ({ handleRecommendPropertyModal, oheyaGirlVideos }) => (
    <RecommendProperties
      handleRecommendPropertyModal={handleRecommendPropertyModal}
      oheyaGirlVideos={oheyaGirlVideos}
    />
  )
);

const Top = (props) => {
  // 再描画を防ぐためmemo化
  const tikTokVideos = useMemo(() => JSON.parse(props.tikTokVideos), []);
  const [showRecommendPropertyModal, setShowRecommendPropertyModal] =
    useState(false);
  const [recommendProperty, setRecommendProperty] = useState(undefined);
  const [recommendRoom, setRecommendRoom] = useState(undefined);
  const [recommendContract, setRecommendContract] = useState(undefined);
  const [recommendAccess, setRecommendAccess] = useState(undefined);
  const [recommendPropertyMovieUrl, setRecommendPropertyMovieUrl] = useState(undefined);
  const [recommendRoomMovieUrl, setRecommendRoomMovieUrl] = useState(undefined);

  const openRecommendPropertyModal = useCallback(
    (
      property,
      room,
      contracts,
      property_accesses,
      property_movie_url,
      room_movie_url
    ) => {
      setRecommendProperty(property);
      setRecommendRoom(room);
      setRecommendContract(contracts);
      setRecommendAccess(property_accesses);
      setRecommendPropertyMovieUrl(property_movie_url);
      setRecommendRoomMovieUrl(room_movie_url);

      setShowRecommendPropertyModal(true);
    },
    []
  );

  const closeRecommendPropertyModal = useCallback(() => {
    setShowRecommendPropertyModal(false);
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = showRecommendPropertyModal ? "hidden" : "auto";
  }, [showRecommendPropertyModal]);

  return (
    <div className="top_wrapper">
      <div className="top_mainVisual">
        <div className="top_mainVisualWrapper">
          <div className="top_characterWrapper">
            <img src={character} className="top_mainCharacter" />
            <img src={iconQuestionLeft} className="top_iconQuestionLeft" />
            <div className="top_bubbleLeftBottom">
              <div className="top_bubbleLeftBottomText">
                <div className="top_bubbleLeftBottomText-top">仲介手数料</div>
                <div className="top_bubbleLeftBottomText-middle">って</div>
                <div className="top_bubbleLeftBottomText-bottom">
                  高すぎじゃん？
                </div>
              </div>

              <img src={bubble_1} />
            </div>

            <div className="top_bubbleLeftTop">
              <div className="top_bubbleLeftTopText">
                <div className="top_bubbleLeftTopText-top">写真って</div>
                <div className="top_bubbleLeftTopText-bottom">
                  なんか実物より
                  <br />
                  広く見えない？
                </div>
              </div>

              <img src={bubble_2} />
            </div>

            <div className="top_bubbleRightTop">
              <div className="top_bubbleRightTopText">
                <div className="top_bubbleRightTopText-top">
                  内見したいだけなのに
                </div>
                <div className="top_bubbleRightTopText-bottom">
                  やりとり
                  <br />
                  面倒。
                </div>
              </div>
              <img src={bubble_3} />
            </div>

            <div className="top_bubbleRightBottom">
              <div className="top_bubbleRightBottomText">
                <div className="top_bubbleRightBottomText-top">
                  スタッフの人が
                  <br />
                  同行してると
                </div>
                <div className="top_bubbleRightBottomText-bottom">
                  ゆっくり
                  <br />
                  見れなくない？
                </div>
              </div>

              <img src={bubble_4} />
            </div>

            <img src={iconQuestionRight} className="top_iconQuestionRight" />
          </div>
        </div>

        <div
          className="top_catchcopy"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img
            src={catchcopy}
            alt="良いお部屋の探し方があるよ！"
            className="top_goodRoom"
          />
          <span className="dli-arrow-down"></span>

          <a href="/user_guide">
            <div className="top_about">
              <div className="top_aboutButton">
                <img src={aboutLifereechintai} alt="Liferee chintaiって？" />
              </div>
            </div>
          </a>

          <div
            className="top_separation"
            style={{ backgroundImage: `url(${separation})` }}
          ></div>
        </div>
      </div>

      <div className="top_searchWrapper">
        <h2>
          <img
            className="top_searchTitle"
            src={subCatchcopy}
            alt="何からお部屋を探す？"
          />
        </h2>

        <div className="top_searchMap">
          <div className="top_searchMapLeft">
            <MapContent content="map" />
          </div>

          <div className="top_searchMapRight">
            <AreaSearch />
            <StationSearch />
            <MapSearch />
          </div>
        </div>
      </div>

      <div className="top_tagWrapper">
        <h2 className="top_tagTitleWrapper">
          <img src={iconTag} alt="タグから探すアイコン" />
          <div className="top_tagTitle">
            <div className="top_tagTitle-text">
              タグ<span>から</span>
            </div>
          </div>
        </h2>

        <ul className="top_tagList">
          <li className="top_tagItem">
            <span><a href="/searches?detail[rooms_floor_gteq]=2">2階以上</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=バス・トイレ別">バス・トイレ別</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=オートロック">オートロック</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[property_accesses_walkby_lteq]=10">駅から徒歩10分以内</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=南向き">南向き</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[have_parking_eq]=true">駐車場</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=フローリング">フローリング</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=追い焚き">追い焚き機能</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=ペット相談">ペット相談可</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=角部屋">角部屋</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=室内洗濯機置き場">室内洗濯機置き場</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=エアコン">エアコン</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=バルコニー">バルコニー</a></span>
          </li>
          <li className="top_tagItem">
            <span><a href="/searches?detail[features_name_in][]=保証人不要">保証人不要</a></span>
          </li>
        </ul>
      </div>

      <div
        className="top_separation"
        style={{ backgroundImage: `url(${separation})` }}
      ></div>

      <div className="top_aboutRoom">
        <h2>
          <img
            className="top_aboutRoom-image"
            src={aboutRoom}
            alt="お部屋ガールイチオシ物件"
          />
        </h2>
        <p className="d-flex text-center justify-content-center mb-5">
          <img src={iconInstagram} className="mr-2" />
          <a href="https://www.instagram.com/oheya_girl/" target="_blank">
            www.instagram.com/oheya_girl/
          </a>
        </p>
        {props.oheyaGirlVideos.length !== 0 ? (
          <MemoRecommendProperties
            oheyaGirlVideos={props.oheyaGirlVideos}
            handleRecommendPropertyModal={openRecommendPropertyModal}
          />
        ) : null}
      </div>
      {showRecommendPropertyModal && (
        <RecommendPropertyModal
          property={recommendProperty}
          room={recommendRoom}
          contracts={recommendContract}
          property_accesses={recommendAccess}
          room_images={recommendRoom.room_images}
          room_movie_url={recommendRoomMovieUrl}
          property_movie_url={recommendPropertyMovieUrl}
          handleCloseModal={closeRecommendPropertyModal}
        />
      )}
      <div
        className="top_separation"
        style={{ backgroundImage: `url(${separation})` }}
      ></div>
      <div className="top_questionWrapper" id="faq">
        <Faq />
      </div>

      {/* 倍率で物件が重なる時モーダル */}
      {/* <div className="MapPropertiesListModal">
        <div className="MapPropertiesListModal_overlay">
          <div className="MapPropertiesListModal_contents">
            <button className="MapPropertiesModal_close">
              <img className="MapPropertiesModal_close-white" src="/assets/icon/icon_close_white.svg" />
              <img className="MapPropertiesModal_close-black" src="/assets/icon/icon-close.svg" />
            </button>

            <div className="MapPropertiesListModal_item">
              <div className="MapPropertiesListModal_movieArea">
                <img src="/assets/coming_soon.png" />
              </div>

              <div className="MapPropertiesListModal_info">
                <div className="MapPropertiesListModal_price">
                  <div className="MapPropertiesListModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesListModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesListModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="MapPropertiesListModal_page">
                  1/17件中
                </div>
              </div>
            </div>
            <div className="MapPropertiesListModal_item">
              <div className="MapPropertiesListModal_movieArea">
                <img src="/assets/coming_soon.png" />
              </div>

              <div className="MapPropertiesListModal_info">
                <div className="MapPropertiesListModal_price">
                  <div className="MapPropertiesListModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesListModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesListModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="MapPropertiesListModal_page">
                  1/17件中
                </div>
              </div>
            </div>
            <div className="MapPropertiesListModal_item">
              <div className="MapPropertiesListModal_movieArea">
                <img src="/assets/coming_soon.png" />
              </div>

              <div className="MapPropertiesListModal_info">
                <div className="MapPropertiesListModal_price">
                  <div className="MapPropertiesListModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesListModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesListModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="MapPropertiesListModal_page">
                  1/17件中
                </div>
              </div>
            </div>
            <div className="MapPropertiesListModal_item">
              <div className="MapPropertiesListModal_movieArea">
                <img src="/assets/coming_soon.png" />
              </div>

              <div className="MapPropertiesListModal_info">
                <div className="MapPropertiesListModal_price">
                  <div className="MapPropertiesListModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesListModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesListModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="MapPropertiesListModal_page">
                  1/17件中
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* マップのピンをクリックした際の物件詳細モーダル */}
      {/* <MapProperties /> */}

      {/* お部屋をもっと見るモーダル */}
      {/* <div className="TopPropertiesModal">
        <div className="TopPropertiesModal_overlay">
          <div className="TopPropertiesModal_contents">
            <div className="TopPropertiesModal_header">
              <button className="TopPropertiesModal_close">
                <img src="/assets/icon/icon-close.svg" />
              </button>
            </div>
            <div className="TopPropertiesModal_body">
              <div className="TopPropertiesModal_movieArea">
                <div className="TopPropertiesModal_movie">
                  <img src="/assets/coming_soon.png" />
                </div>
              </div>

              <div className="TopPropertiesModalOverview">
                <h3 className="TopPropertiesModal_title">
                  AAAレジデンス
                  <span>301号室</span>
                </h3>

                <div className="TopPropertiesModalOverview_price">
                  <span className="TopPropertiesModalOverview_rent">
                    <span>10.2</span>
                    万円
                  </span>
                  <span className="TopPropertiesModalOverview_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </span>
                </div>
                <div className="TopPropertiesModalOverview_box">
                  <div className="TopPropertiesModalOverview_flex">
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_item">
                        <label className="TopPropertiesModalOverview_th">敷金</label>
                        <div className="TopPropertiesModalOverview_td">1ヶ月</div>
                      </div>
                    </div>
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_item">
                        <label className="TopPropertiesModalOverview_th">礼金</label>
                        <div className="TopPropertiesModalOverview_td">1ヶ月</div>
                      </div>
                    </div>
                  </div>
                  <div className="TopPropertiesModalOverview_flex">
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_item">
                        <label className="TopPropertiesModalOverview_th">間取り</label>
                        <div className="TopPropertiesModalOverview_td">1DK</div>
                      </div>
                    </div>
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_item">
                        <label className="TopPropertiesModalOverview_th">面積</label>
                        <div className="TopPropertiesModalOverview_td">30.56㎡</div>
                      </div>
                    </div>
                  </div>
                  <div className="TopPropertiesModalOverview_flex">
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_th">
                        階層
                      </div>
                      <div className="TopPropertiesModalOverview_td">
                        8階/10階
                      </div>
                    </div>
                    <div className="TopPropertiesModalOverview_flexItem">
                      <div className="TopPropertiesModalOverview_th">
                        種別
                      </div>
                      <div className="TopPropertiesModalOverview_td">
                        マンション
                      </div>
                    </div>
                  </div>
                  <div className="TopPropertiesModalOverview_tr">
                    <div className="TopPropertiesModalOverview_item">
                      <label className="TopPropertiesModalOverview_th">築年月</label>
                      <div className="TopPropertiesModalOverview_td">
                        {[elapsedYearsAndMonth(10,2)]}
                      </div>
                    </div>
                  </div>
                  <div className="TopPropertiesModalOverview_flexItem">
                    <div className="TopPropertiesModalOverview_th">
                      住所
                    </div>
                    <div className="TopPropertiesModalOverview_td">
                      東京都渋谷区111
                    </div>
                  </div>

                  <div className="TopPropertiesModalOverview_action">
                    <div className="btnStyle-fill">
                      <img src="/assets/icon/icon-map.svg" />
                      地図を見る
                    </div>
                    <div className="btnStyle-fill">
                      <img src="/assets/icon/icon-walk.svg" />
                      ストリートビューを見る
                    </div>
                  </div>

                  <div className="TopPropertiesModalOverview_tr">
                    <div className="TopPropertiesModalOverview_item">
                      <label className="TopPropertiesModalOverview_th">交通</label>
                      <div className="TopPropertiesModalOverview_td">
                        JR山手線/渋谷駅 徒歩10分
                        <br/>
                        JR山手線/原宿駅 徒歩18分
                        <br/>
                        東京メトロ銀座線/表参道駅 徒歩15分
                      </div>
                    </div>

                  </div>
                </div>
                <div className="TopPropertiesModalReservation">
                  <div className="TopPropertiesModalReservation_copy">

                    \ <img src="/assets/icon/icon-key.svg" /> セルフ内見可能! /
                  </div>
                  <div className="btnStyle-fill">
                    内見予約をする
                  </div>
                  <a href="/user_guide" className="btnStyle-white">
                    物件についてのお問い合わせ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Top;
