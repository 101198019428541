<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
        <div class="contents-box my-2">
          <div class="container py-3">
            <h3 class="h5 font-weight-bold">KEY STATIONに向かう</h3>
            <p class="mb-1">
              下記の場所に設置されているKEY STATIONヘと向かってください。
            </p>
            <p class="mb-2 mt-3">
              <span class="badge bg-primary">目的地</span>
              {{keyStationName}} <br />
              <span class="badge bg-primary">住所</span>
              {{keyStationAddress}}
            </p>
            <div id="map" class='map border border-primary mb-3' style='height:300px;' v-if="haveLatLon">
              <GmapMap
                :center="gmap.center"
                :zoom="gmap.zoom"
                map-type-id="roadmap"
                style="width: 100%; height: 100%;"
                :options="gmap.mapStyle">
                  <GmapMarker v-for="(item, index) in gmap.markerItems"
                  :position="item.position"
                  :title="item.title"
                  :key="index"
                  :clickable="true"
                  :draggable="false"
                  @click="toggleInfoWindow(item)">
                  </GmapMarker>
                  <GmapInfoWindow
                    :options="gmap.infoOptions"
                    :position="gmap.infoWindowPosition"
                    :opened="gmap.infoWindowOpen"
                    @closeclick="gmap.infoWindowOpen=false">
                    {{keyStationName}}
                  </GmapInfoWindow>
              </GmapMap>
            </div>
            <div class="text-right">
              <a :href="gmapNaviUrl" class="btn btn-outline-secondary btn-sm" target='_blank'>
                <img src="~navi/icon_pin.png" width="16" class="align-text-top">
                マップアプリで確認する
              </a>
            </div>
        </div>
      </div>

    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import Welcome from '@/components/navi/common/Welcome'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import NaviFooter from '@/components/navi/common/Footer'
export default {
  name: 'HeadingToKs',
  components: { NaviHeader, Welcome, RoomInfo, Step, NaviFooter },
  data() {
    return {
      gmap: {
        center: {},
        zoom: 17,
        mapStyle: {
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: true,
        },
        markerItems: [],
        infoWindowPosition: null,
        infoWindowOpen: false,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
      }
    }
  },
  mounted() {
    if(this.haveLatLon){
      const center = { lat: parseFloat(this.keyStationLat),
                       lng: parseFloat(this.keyStationLon) }
      this.gmap.center = center
      const marker = { id: this.reservationId,
                       title: this.keyStationName,
                       position: center }
      this.gmap.markerItems.push(marker)
    }
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('keyStation', ['keyStationName', 'keyStationAddress', 'keyStationPassword', 'keyStationLat', 'keyStationLon']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    },
    haveLatLon(){
      return (this.keyStationLat != null && this.keyStationLon != null)
    },
    gmapNaviUrl() {
      return `https://www.google.com/maps/dir/?api=1&directionsmode=walking&destination=${parseFloat(this.keyStationLat)}, ${parseFloat(this.keyStationLon)}&travelmode=walking`
    }
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    },
    toggleInfoWindow(marker) {
      this.gmap.infoWindowPosition = marker.position
      this.gmap.infoWindowOpen = true
    }
  }
}
</script>

<style scoped>
</style>



