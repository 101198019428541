import { Button } from "@material-ui/core";
import React from "react";
import { useRecoilState } from "recoil";
import iconLocation from "../../../../../assets/images/top/icon_location.svg";
import { showMapSearchState } from "../state";

function MapSearch() {
  const [isShowMapSearch, setShowMapSearch] =
    useRecoilState(showMapSearchState);

  return (
    <React.Fragment>
      <div
        className="top_searchMapRight-button"
        onClick={() => {
          setShowMapSearch(!isShowMapSearch);
          sessionStorage.removeItem("city_ids");
          sessionStorage.removeItem("station_master");
          sessionStorage.removeItem("detail");
        }}
      >
        <img
          className="top_searchMapRight-icon"
          src={iconLocation}
          alt="現在地から探すアイコン"
        />
        <div>
          <div className="top_searchMapRight-buttonTop">現在地</div>
          <div className="top_searchMapRight-buttonBottom">から</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MapSearch;
