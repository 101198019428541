import React, { useEffect, useState } from "react";
import SelfReservationForm from "./SelfReservationForm";
import ScheduleCheckReservationForm from "./ScheduleCheckReservationForm";
import axios from "axios";

const Reservation_modal = ({ props }) => {
  const { room, ...other } = props
  const [reserved, setReserved] = useState(null);
  const reservedUrl = "/reservations/[room_id].json";
  useEffect(async () => {
    try {
      if (!props || !room || !room.id) return;
      const regUrl = reservedUrl.replace("[room_id]", room.id);
      const ret = await axios.get(regUrl);
      setReserved(ret.data);
    } catch (e) {
      document.body.innerHTML = e.response.data;
    }
  }, []);
  return (
    <div>
      <div>
        {(() => {
          if (room.property.require_schedule_confirming == true) {
            return (
              <ScheduleCheckReservationForm props={props} reserved={reserved} />
            );
          } 
          else {
            return <SelfReservationForm props={props} reserved={reserved} />;
          }
        })()}
      </div>
    </div>
  );
};

export default Reservation_modal;
