import React from "react";
import Container from "@material-ui/core/Container";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Recommendation } from "./Recommendation";
import { roomLayoutEnums } from "../../../enums/layouts";
import comingSoon from "../../../../../assets/images/coming_soon.png";
import dummyQr from "../../../../../assets/images/dummy-qr.png";
import iconLine from "../../../../../assets/images/icon/icon-LINE.svg";
import imgCharacter from "../../../../../assets/images/character/character07.png";
import linePhone from "../../../../../assets/images/line-phone.svg";

export const SelfComplete = ({ formData, props }) => {
  const { room, ...other } = props
  const property = room.property
  const contract = room.contracts[0]
  const property_access = property.property_accesses[0]
  const {  id, date_time, first_name, last_name, tel, email } = formData;
  let array2 = [
    `予約ID: ${formData.id}`,
    `希望日時: ${formData.date_time}`,
    `物件名: ${property.name} / ${room.name}`,
    `予約者名: ${formData.last_name} ${formData.first_name}`,
  ];
  let array = array2.join("\n");

  return (
    <Container>
      <div class="reservationsComplete">
        <h1 class="reservationsComplete_heading">
          ご予約ありがとうございます。
          <br />
          予約完了メールをご確認ください。
        </h1>
        <p class="reservationsComplete_text">
          <span>鍵の受け渡し方法に関しましては、</span>
          <span>内見の当日にメールにてご案内します。</span>
        </p>
        <p class="reservationsComplete_attention">
          <span>予約完了メールが届かない場合は</span>
          <span>鍵受け渡しの案内ができませんので、</span>
          <span>
            <a href="/contact" target="_blank">
              お問い合わせページ
            </a>
            よりご連絡お願いします。
          </span>
        </p>
        <div class="reservationsLine">
          <div class="reservationsLine_left">
            <div class="reservationsLine_image">
              <img src={linePhone} />
            </div>
            <div class="reservationsLine_top">
              <p class="reservationsLine_line">LINE公式アカウント</p>
              <p class="reservationsLine_account">@lifereechintai</p>
              <p class="reservationsLine_text">
                <span>友だち追加をすると、</span>
                <span>内見までのやりとりがスムーズにできます。</span>
              </p>

              <CopyToClipboard
                text={array}
                onCopy={() => alert("クリップボードにコピーしました！")}
              >
                <button class="btnStyle-line">必要情報をコピーする</button>
              </CopyToClipboard>
            </div>
          </div>
          <div class="reservationsLine_right">
            <div class="reservationsLine_block">
              <p>QRコードから友だち追加</p>
              <img src={dummyQr} />
            </div>

            <div class="reservationsLine_block">
              <p>ID検索から友だち追加</p>
              <div class="reservationsLine_box">
                <span>@lifereechintai</span>
              </div>
              <p>で検索</p>
            </div>
          </div>
          <a href="#" class="reservationsLine_btn">
            <img src={iconLine} />
            <span>友だち追加</span>
          </a>
        </div>

        <div class="reservationsComplete_sub">予約内容</div>
        <div class="reservationsComplete_contents">
          <div class="reservationsComplete_room">
            <div class="reservationsComplete_photo">
              <img src={room?.movie_thumbnail_url || comingSoon} />
            </div>
            <div class="reservationsComplete_overview">
              <div class="reservationsComplete_name">
                {property.name} {room.name}
              </div>
              <div class="reservationsComplete_rent">
                <span>{(contract?.rent / 10000).toFixed(1)}</span>
                万円(管:{(contract?.management_fee * 1000).toLocaleString()}円）
              </div>
              <div class="reservationsComplete_other">
                <span>
                  {property_access?.station_master.name}
                  {property_access?.station_master.name && "駅"}{" "}
                  {property_access?.walkby && "徒歩"}
                  {property_access?.walkby}
                  {property_access?.walkby && "分"}
                </span>
                <span>
                  {room?.layout && roomLayoutEnums[room?.layout]}
                  {room?.size && "/"} {room?.size && room?.size}
                  {room?.size && "㎡"}
                </span>
              </div>
            </div>
          </div>
          <div class="reservationsComplete_block">
            <div class="reservationsComplete_label">内見希望日</div>
            <div class="reservationsComplete_item">{date_time}</div>
          </div>

          <div class="reservationsComplete_block">
            <div class="reservationsComplete_label">お名前</div>
            <div class="reservationsComplete_item">
              {last_name} {first_name}
            </div>
          </div>

          <div class="reservationsComplete_block">
            <div class="reservationsComplete_label">メールアドレス</div>
            <div class="reservationsComplete_item">{email}</div>
          </div>

          <div class="reservationsComplete_block">
            <div class="reservationsComplete_label">電話番号</div>
            <div class="reservationsComplete_item">{tel}</div>
          </div>
        </div>
        <div class="reservationsComplete_action">
          <div class="btnStyle-line">物件の一覧ページに戻る</div>
        </div>
      </div>
      {props.recommend.length >= 4 && (
        <Recommendation recommend={props.recommend} />
      )}
    </Container>
  );
};
