<template>
  <div>
    <h3 class="h6 font-weight-bold">
      <img src="~navi/icon_home.png" width="16">
      内見予定の物件
    </h3>
    <div class="property-detail">
      <ul class="mb-0 px-0">
        <li class="list-unstyled">
          <span class="badge badge-dark mr-2">予約者名</span>{{customerFullName}}様
        </li>
        <li class="list-unstyled">
          <span class="badge badge-dark mr-2">物件名</span>{{reservationRoomWithProperty}}
        </li>
        <li class="list-unstyled">
          <span class="badge badge-dark mr-2">予約日時</span>{{startTime}}
        </li>
        <li class="list-unstyled">
          <span class="badge badge-dark mr-2">担当者</span>{{companyName}}  {{userFullName}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'RoomInfo',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('reservation', ['reservationStartAt', 'reservationRoomWithProperty']),
    ...mapGetters('customer', ['customerFullName']),
    ...mapGetters('user', ['userFullName']),
    ...mapGetters('company', ['companyName']),
    ...mapGetters('flow', ['flowId']),
    startTime() {
      return moment(this.reservationStartAt).format('YYYY年MM月DD日 LT~')
    },
  },
  methods: {
  }
}
</script>

<style scoped>
</style>





