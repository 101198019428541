import React from "react";
import { Button } from "@material-ui/core";
import { showAreaSearchState } from "../state";
import { useRecoilState } from "recoil";
import iconMapPin from "../../../../../assets/images/top/icon_map-pin_36x36.svg";

function AreaSearch() {
  const [isShowAreaSearch, setShowAreaSearch] =
    useRecoilState(showAreaSearchState);

  return (
    <React.Fragment>
      <div
        className="top_searchMapRight-button"
        onClick={() => {
          sessionStorage.removeItem("city_ids");
          sessionStorage.removeItem("station_master");
          sessionStorage.removeItem("detail");
          // sessionStorage.clear();
          setShowAreaSearch(!isShowAreaSearch);
        }}
      >
        <img
          className="top_searchMapRight-icon"
          src={iconMapPin}
          alt="地域探すアイコン"
        />
        <div>
          <div className="top_searchMapRight-buttonTop">地域</div>
          <div className="top_searchMapRight-buttonBottom">から</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AreaSearch;
