import { Button } from "@material-ui/core";
import React from "react";
import iconTime from "../../../../../assets/images/top/icon_time.svg";

function CommuteSearch() {

  return (
    <React.Fragment>
      {/* <div className="top_searchMapRight-button">
        <img className="top_searchMapRight-icon" src={iconTime} alt="通勤通学時間探すアイコン" />
        <div>
          <div className="top_searchMapRight-buttonTop">通勤通学時間</div>
          <div className="top_searchMapRight-buttonBottom">から</div>
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default CommuteSearch
