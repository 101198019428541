import React from "react"

const Calendar = (props) => {
  const content = props.content
  
  return (
    <React.Fragment>
      Content: {content}
    </React.Fragment>
  );
};

export default Calendar;