import React from "react";
import CheckBox from "./CheckBox";

const FloorType = ({ values, handleSelectValues, rooms_type_in }) => {
  const rooms_layout_prettier =
    rooms_type_in?.rooms_layout_in &&
    rooms_type_in?.rooms_layout_in.map((a) => {
      return Number(a);
    });
  const include_1 = rooms_layout_prettier?.filter((output, index) => {
    return output === 1;
  });
  const include_2 = rooms_layout_prettier?.filter((output, index) => {
    return output === 2;
  });
  const include_3 = rooms_layout_prettier?.filter((output, index) => {
    return output === 3;
  });
  const include_4 = rooms_layout_prettier?.filter((output, index) => {
    return output === 4;
  });
  const include_5 = rooms_layout_prettier?.filter((output, index) => {
    return output === 5;
  });
  const include_6 = rooms_layout_prettier?.filter((output, index) => {
    return output === 6;
  });
  const include_7 = rooms_layout_prettier?.filter((output, index) => {
    return output === 7;
  });
  const include_8 = rooms_layout_prettier?.filter((output, index) => {
    return output === 8;
  });
  const include_9 = rooms_layout_prettier?.filter((output, index) => {
    return output === 9;
  });
  const include_10 = rooms_layout_prettier?.filter((output, index) => {
    return output === 10;
  });
  const include_11 = rooms_layout_prettier?.filter((output, index) => {
    return output === 11;
  });
  const include_12 = rooms_layout_prettier?.filter((output, index) => {
    return output === 12;
  });
  const include_13 = rooms_layout_prettier?.filter((output, index) => {
    return output === 13;
  });
  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">間取りタイプ</div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"oneRoom"}
            name="detail[rooms_layout_in][]"
            value={1}
            // checked={values.oneRoom}
            defaultChecked={include_1 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"oneRoom"}>
            ワンルーム
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"oneK"}
            name="detail[rooms_layout_in][]"
            value={2}
            // checked={values.oneK}
            defaultChecked={include_2 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"oneK"}>
            1K
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"oneDk"}
            name="detail[rooms_layout_in][]"
            value={3}
            // checked={values.oneDk}
            defaultChecked={include_3 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"oneDk"}>
            1DK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"oneLdk"}
            name="detail[rooms_layout_in][]"
            value={13}
            // checked={values.oneLdk}
            defaultChecked={include_13 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"oneLdk"}>
            1LDK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"twoK"}
            name="detail[rooms_layout_in][]"
            value={4}
            // checked={values.twoK}
            defaultChecked={include_4 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"twoK"}>
            2K
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"twoDk"}
            name="detail[rooms_layout_in][]"
            value={5}
            // checked={values.twoDk}
            defaultChecked={include_5 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"twoDk"}>
            2DK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"twoLdk"}
            name="detail[rooms_layout_in][]"
            value={6}
            // checked={values.twoLdk}
            defaultChecked={include_6 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"twoLdk"}>
            2LDK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"threeK"}
            name="detail[rooms_layout_in][]"
            value={7}
            // checked={values.threeK}
            defaultChecked={include_7 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"threeK"}>
            3K
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"threeDk"}
            name="detail[rooms_layout_in][]"
            value={8}
            // checked={values.threeDk}
            defaultChecked={include_8 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"threeDk"}>
            3DK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"threeLdk"}
            name="detail[rooms_layout_in][]"
            value={9}
            // checked={values.threeLdk}
            defaultChecked={include_9 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"threeLdk"}>
            3LDK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"fourK"}
            name="detail[rooms_layout_in][]"
            value={10}
            // checked={values.fourK}
            defaultChecked={include_10 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"fourK"}>
            4K
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"fourDk"}
            name="detail[rooms_layout_in][]"
            value={11}
            // checked={values.fourDk}
            defaultChecked={include_11 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"fourDk"}>
            4DK
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"fourLdk"}
            name="detail[rooms_layout_in][]"
            value={12}
            // checked={values.fourLdk}
            defaultChecked={include_12 > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"fourLdk"}>
            4LDK以上
          </label>
        </li>
      </ul>
    </div>
  );
};

export default FloorType;
