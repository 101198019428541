import React, { useMemo, useEffect, useRef } from "react";
import Hls from "hls.js";

/**
 * Hlsコンポーネント
 * @param src 動画のURL
 * @param playerRef
 * @param playFlg 再生フラグ
 * @param audioRef
 * @param handleEndCatch 動画終了イベント
 */
const VideoPlayer = ({
  src,
  playerRef = useRef(null),
  playFlg = true,
  audioSrc,
  audioRef = useRef(null),
  handleEndCatch = () => {},
}) => {
  const isSupportBrowser = useMemo(() => Hls.isSupported(), []);

  useEffect(() => {
    // ビデオコンテンツの終了イベントを拾う
    playerRef.current.addEventListener("ended", (event) => {
      handleEndCatch();
    });

    // ビデオコンテンツの再生・停止・シークにてオーディオコンテンツを操作
    playerRef.current.addEventListener("play", function () {
      audioRef.current.play();
    });

    playerRef.current.addEventListener("pause", function () {
      audioRef.current.pause();
    });

    playerRef.current.addEventListener("seeked", (event) => {
      audioRef.current.currentTime = event.target.currentTime;
    });

    playerRef.current.addEventListener("volumechange", (event) => {
      audioRef.current.muted = event.target.muted;
      audioRef.current.volume = event.target.volume;
      audioRef.current.currentTime = event.target.currentTime;
      if (!event.target.pause && audioRef.current.pause) {
        audioRef.current.play();
      }
    });

    if (isSupportBrowser) {
      const newHls = new Hls({
        enableWorker: false,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);
      });
    }
  }, [src]);

  return (
    <>
      {isSupportBrowser ? (
        <video ref={playerRef} autoPlay={playFlg} muted={true} controls />
      ) : (
        <video
          ref={playerRef}
          src={src}
          autoPlay={playFlg}
          muted={true}
          controls
        />
      )}

      <audio ref={audioRef} src={audioSrc} muted={true}></audio>
    </>
  );
};

export default VideoPlayer;
