import React, { useState } from "react";
import Map from "../PropertiesSearch/Map";
import iconMap from "../../../../assets/images/top/icon_map.svg";

const enableStyle = {
  height: "500px",
};
const disableStyle = {
  height: "0px",
};

const MapContent = () => {
  const [openMapFlg, setOpenMapFlg] = useState(false);
  const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY_202210;

  return (
    <React.Fragment>
      <div className="top_searchMapTitle">
        <div className="top_searchMapInfo sp" onClick={() => setOpenMapFlg(!openMapFlg)}>
          <img src={iconMap} alt="地図から探すアイコン" />
          <span>地図</span>{openMapFlg ? "を閉じる" : "を開く"}
        </div>
        <div className="top_searchMapInfo pc">
          <img src={iconMap} alt="地図から探すアイコン" />
          <span>地図</span>から
        </div>
        {/* <div className="top_searchMapInput"><input type="text" className="form-control" placeholder="市区町村、駅名など" /></div>
        <div className="top_searchMapButton"><span>現在地を表示</span></div> */}
      </div>
      <div className="top_mapSp" style={openMapFlg ? enableStyle : disableStyle} >
        <Map googleMapsApiKey={googleMapsApiKey} />
      </div>
      <div className="top_mapPc"><Map googleMapsApiKey={googleMapsApiKey} /></div>
    </React.Fragment>
  );
};

export default MapContent;
