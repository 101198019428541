import React from "react";
import { useSetRecoilState } from "recoil";
import { modalShowState, modalDataState } from "./state";
import { roomLayoutEnums } from "../../enums/layouts";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

import comingSoon from "../../../../assets/images/coming_soon.png";

const Room = ({ property, room, myNum, total }) => {
  const setModalData = useSetRecoilState(modalDataState);
  const setShow = useSetRecoilState(modalShowState);
  const propertyAccess = property.property_accesses[0];
  const oheyaGirlVideo = room.room_videos.filter(
    (item) => item.role === "oheya_girl"
  );
  const interiorVideo = room.room_videos.filter(
    (item) => item.role === "interior"
  );

  const appearanceVideo = property.property_videos.filter(
    (item) => item.role === "appearance"
  );

  return (
    <div className="propertyCard_item">
      <div className="propertyCard_top">
        <h3 className="propertyCard_heading">{property.name}</h3>
        <div className="propertyCard_sub">
          {propertyAccess?.station_master.name}駅 徒歩{propertyAccess?.walkby}分
        </div>
      </div>
      <div className="propertyCard_middle">
        {(() => {
          if (oheyaGirlVideo.length > 0) {
            return <VideoPlayer src={oheyaGirlVideo[0].movie_url} />;
          } else if (interiorVideo.length > 0) {
            return <VideoPlayer src={interiorVideo[0].movie_url} />;
          } else if (appearanceVideo.length > 0) {
            return <VideoPlayer src={appearanceVideo[0].movie_url} />;
          } else {
            return <img src={comingSoon} />;
          }
        })()}
        <div className="propertyCard_other">
          <div className="propertyCard_other-number">
            スクロールして他の部屋も見る &gt;
          </div>
          <div className="propertyCard_other-number">
            {myNum}/{total}件
          </div>
        </div>
      </div>
      <div className="propertyCard_overview">
        <div className="propertyCard_room">
          {room.name} / {roomLayoutEnums[room.layout]}
        </div>
        <div className="propertyCard_rent">
          <span className="propertyCard_large">
            {room.contracts[0]?.rent ? (
              <span>{(room.contracts[0]?.rent / 10000).toFixed(1)}</span>
            ) : (
              <small className="font-weight-bold">お問い合わせください</small>
            )}
          </span>
          <span>{room.contracts[0]?.rent && "万円"}</span>
          <div className="propertyCard_fee">
            (管理費
            <span>
              {room.contracts[0]?.management_fee ? (
                <>{room.contracts[0]?.management_fee.toLocaleString()}</>
              ) : (
                " "
              )}
            </span>
            円)
          </div>
        </div>
      </div>
      <div className="propertyCard_action">
        <button
          className="btnStyle-fill"
          onClick={() => {
            setModalData(room);
            setShow(true);
          }}
        >
          詳細を見る
        </button>
      </div>
    </div>
  );
};

export default Room;
