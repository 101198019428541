<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step  @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
      <div class="contents-box my-2">
        <div class="container pt-3">
          <h3 class="h5 font-weight-bold mb-3">お部屋のドアはドアジョイナーで施錠されているため、解錠してお部屋に入っていただきます。</h3>
          <div class="step-flow pb-3">
            <p>退出時には再度施錠をして頂きますので、解錠したドアジョイナーは無くさずにお持ちください</p>
            <div class="image-box pb-3">
              <img :src="doorJoinerImageUrl" class="w-100 rounded">
            </div>
            <p>以下の暗証番号でドアジョイナーを解錠してください。</p>
            <div class="bg-primary p-2 text-center text-white">
              お客様の暗証番号：{{doorJoinerPassword}}
            </div>
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
export default {
  name: 'UnlockDoorJoiner',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('doorJoiner', ['doorJoinerPassword', 'doorJoinerImageUrl']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    }
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    }
  }
}
</script>

<style scoped>
</style>

