export const imageTypeEnums = {
  layout: "レイアウト",
  appearance: "外観",
  entrance: "エントランス",
  shared: "共有部",
  hall: "ホール",
  living: "リビング",
  western_room: "洋室",
  japanese_room: "和室",
  toilet: "トイレ",
  bathroom: "お風呂場",
  kitchen: "キッチン",
  washroom: "洗面所",
  balcony: "バルコニー",
  closet: "クローゼット",
};
