<template>
  <div class='container'>
    遠隔案内の待機画面
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'WaitRemoteGuidance',
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    onStepSelected(step){
      this.$emit('onStepSelected', step)
    },
  },
  watch: {
  }
}
</script>

<style scoped>
</style>

