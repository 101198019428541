import React from "react";
import CheckBox from "./CheckBox";

const Particulars = ({ values, handleSelectValues, rooms_features_name_in }) => {
  const rooms_features_name_in_prettier =
    rooms_features_name_in?.features_name_in &&
    rooms_features_name_in?.features_name_in.map((a) => {
      return a;
    });

  const include_1 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "1階の部屋";
  });
  const include_2 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "2階以上の部屋";
  });
  const include_3 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "最上階の部屋";
  });
  const include_4 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "角部屋";
  });
  const include_5 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "南向き";
  });
  const include_6 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "タワーマンション";
  });
  const include_7 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "バリアフリー";
  });
  const include_8 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "デザイナーズ";
  });
  const include_9 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "特定優良賃貸住宅";
  });
  const include_10 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "即入居可";
  });
  const include_11 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "保証人不要";
  });
  const include_12 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "楽器相談";
  });
  const include_13 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "事務所可";
  });
  const include_14 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "女性限定";
  });
  const include_15 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "家具家電付き";
  });
  const include_16 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "風呂・トイレ・キッチン";
  });
  const include_17 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "ガスコンロ";
  });
  const include_18 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "IHコンロ";
  });
  const include_19 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "コンロ二口以上";
  });
  const include_20 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "システムキッチン";
  });
  const include_21 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "カウンターキッチン";
  });
  const include_22 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "冷蔵庫あり";
  });
  const include_23 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "バス・トイレ別";
  });
  const include_24 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "追い焚き";
  });
  const include_25 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "洗面所独立";
  });
  const include_26 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "温水洗浄便座";
  });
  const include_27 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "浴室乾燥機";
  });
  const include_28 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "トイレあり";
  });
  const include_29 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "エアコン";
  });
  const include_30 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "床暖房";
  });
  const include_31 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "ガス暖房";
  });
  const include_32 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "石油暖房";
  });
  const include_33 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "ウォークインクローゼット";
  });
  const include_34 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "床下収納";
  });
  const include_35 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "トランクルーム";
  });
  const include_36 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "インターネット接続";
  });
  const include_38 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "CSアンテナ";
  });
  const include_39 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "BSアンテナ";
  });
  const include_40 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "CATV";
  });
  const include_41 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "有線放送";
  });
  const include_42 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "宅配ボックス";
  });
  const include_43 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "モニタ付きドアホン";
  });
  const include_44 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "オートロック";
  });
  const include_45 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "管理人あり";
  });
  const include_46 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "エレベーターあり";
  });
  const include_47 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "フローリング";
  });
  const include_48 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "室内洗濯機置き場";
  });
  const include_49 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "都市ガス";
  });
  const include_50 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "プロパンガス";
  });
  const include_51 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "オール電化";
  });
  const include_52 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "専用庭";
  });
  const include_53 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "出窓";
  });
  const include_54 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "バルコニー";
  });
  const include_55 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "ロフト付き";
  });
  const include_56 = rooms_features_name_in_prettier?.filter((output, index) => {
    return output === "タイル貼り";
  });

  return (
    <div className="conditions-container">
      <div className="searchRent_subTitle">こだわり条件</div>

      <ul className="searchRent_list">
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"firstFloor"}
            name="detail[features_name_in][]"
            value={"1階の部屋"}
            defaultChecked={include_1?.length > 0}
            onChange={handleSelectValues}
            // checked={values.firstFloor}
          />
          <label className="searchRent_name" htmlFor={"firstFloor"}>
            1階の部屋
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"upperSecondFloor"}
            name="detail[features_name_in][]"
            value={"2階以上の部屋"}
            onChange={handleSelectValues}
            // checked={values.upperSecondFloor}
            defaultChecked={include_2?.length > 0}
          />
          <label className="searchRent_name" htmlFor={"upperSecondFloor"}>
            2階以上の部屋
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"topFloor"}
            name="detail[features_name_in][]"
            value={"最上階の部屋"}
            onChange={handleSelectValues}
            // checked={values.topFloor}
            defaultChecked={include_3?.length > 0}
          />
          <label className="searchRent_name" htmlFor={"topFloor"}>
            最上階の部屋
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"cornerRoom"}
            name="detail[features_name_in][]"
            onChange={handleSelectValues}
            value={"角部屋"}
            // checked={values.cornerRoom}
            defaultChecked={include_4?.length > 0}
          />
          <label className="searchRent_name" htmlFor={"cornerRoom"}>
            角部屋
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"southFacing"}
            name="detail[features_name_in][]"
            value={"南向き"}
            // checked={values.southFacing}
            defaultChecked={include_5?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"southFacing"}>
            南向き
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"towerApartment"}
            name="detail[features_name_in][]"
            value={"タワーマンション"}
            // checked={values.towerApartment}
            defaultChecked={include_6?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"towerApartment"}>
            タワーマンション
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"barrierFree"}
            name="detail[features_name_in][]"
            value={"バリアフリー"}
            // checked={values.barrierFree}
            defaultChecked={include_7?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"barrierFree"}>
            バリアフリー
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"designers"}
            name="detail[features_name_in][]"
            value={"デザイナーズ"}
            // checked={values.designers}
            defaultChecked={include_8?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"designers"}>
            デザイナーズ
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"specifiedExcellentRentalHousing"}
            name="detail[features_name_in][]"
            value={"特定優良賃貸住宅"}
            // checked={values.specifiedExcellentRentalHousing}
            defaultChecked={include_9?.length > 0}
            onChange={handleSelectValues}
          />
          <label
            className="searchRent_name"
            htmlFor={"specifiedExcellentRentalHousing"}
          >
            特定優良賃貸住宅
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"immediateMoveIn"}
            name="detail[features_name_in][]"
            value={"即入居可"}
            // checked={values.immediateMoveIn}
            defaultChecked={include_10?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"immediateMoveIn"}>
            即入居可
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"noGuarantor"}
            name="detail[features_name_in][]"
            value={"保証人不要"}
            // checked={values.noGuarantor}
            defaultChecked={include_11?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"noGuarantor"}>
            保証人不要
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"musicalInstrument"}
            name="detail[features_name_in][]"
            value={"楽器相談"}
            // checked={values.musicalInstrument}
            defaultChecked={include_12?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"musicalInstrument"}>
            楽器相談
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"officeAvailable"}
            name="detail[features_name_in][]"
            value={"事務所可"}
            // checked={values.officeAvailable}
            defaultChecked={include_13?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"officeAvailable"}>
            事務所可
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"womenOnly"}
            name="detail[features_name_in][]"
            value={"女性限定"}
            // checked={values.womenOnly}
            defaultChecked={!include_14?.length === 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"womenOnly"}>
            女性限定
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"withFurnitureAppliances"}
            name="detail[features_name_in][]"
            value={"家具家電付き"}
            // checked={values.withFurnitureAppliances}
            defaultChecked={include_15?.length > 0}
            onChange={handleSelectValues}
          />
          <label
            className="searchRent_name"
            htmlFor={"withFurnitureAppliances"}
          >
            家具家電付き
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"bath"}
            name="detail[features_name_in][]"
            value={"風呂・トイレ・キッチン"}
            // checked={values.bath}
            defaultChecked={include_16?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"bath"}>
            風呂・トイレ・キッチン
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"gasStove"}
            name="detail[features_name_in][]"
            value={"ガスコンロ"}
            // checked={values.gasStove}
            defaultChecked={include_17?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"gasStove"}>
            ガスコンロ
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"ihStove"}
            name="detail[features_name_in][]"
            value={"IHコンロ"}
            // checked={values.ihStove}
            defaultChecked={include_18?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"ihStove"}>
            IHコンロ
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"twoOrMoreStoves"}
            name="detail[features_name_in][]"
            value={"コンロ二口以上"}
            // checked={values.twoOrMoreStoves}
            defaultChecked={include_19?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"twoOrMoreStoves"}>
            コンロ二口以上
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"systemKitchen"}
            name="detail[features_name_in][]"
            value={"システムキッチン"}
            // checked={values.systemKitchen}
            defaultChecked={include_20?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"systemKitchen"}>
            システムキッチン
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"counterKitchen"}
            name="detail[features_name_in][]"
            value={"カウンターキッチン"}
            // checked={values.counterKitchen}
            defaultChecked={include_21?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"counterKitchen"}>
            カウンターキッチン
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"isRefrigerator"}
            name="detail[features_name_in][]"
            value={"冷蔵庫あり"}
            onChange={handleSelectValues}
            defaultChecked={include_22?.length > 0}
          />
          <label className="searchRent_name" htmlFor={"isRefrigerator"}>
            冷蔵庫あり
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"bathAndToiletSeparated"}
            name="detail[features_name_in][]"
            value={"バス・トイレ別"}
            // checked={values.bathAndToiletSeparated}
            defaultChecked={include_23?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"bathAndToiletSeparated"}>
            バス・トイレ別
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"reheating"}
            name="detail[features_name_in][]"
            value={"追い焚き"}
            // checked={values.reheating}
            defaultChecked={include_24?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"reheating"}>
            追い焚き
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"independentWashroom"}
            name="detail[features_name_in][]"
            value={"洗面所独立"}
            // checked={values.independentWashroom}
            defaultChecked={include_25?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"independentWashroom"}>
            洗面所独立
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"warmWaterWashingToilet"}
            name="detail[features_name_in][]"
            value={"温水洗浄便座"}
            // checked={values.warmWaterWashingToilet}
            defaultChecked={include_26?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"warmWaterWashingToilet"}>
            温水洗浄便座
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"bathroomDryer"}
            name="detail[features_name_in][]"
            value={"浴室乾燥機"}
            // checked={values.bathroomDryer}
            defaultChecked={include_27?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"bathroomDryer"}>
            浴室乾燥機
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"isToilet"}
            name="detail[features_name_in][]"
            value={"トイレあり"}
            // checked={values.isToilet}
            defaultChecked={include_28?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"isToilet"}>
            トイレあり
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"airConditioner"}
            name="detail[features_name_in][]"
            value={"エアコン"}
            // checked={values.airConditioner}
            defaultChecked={include_29?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"airConditioner"}>
            エアコン
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"floorHeating"}
            name="detail[features_name_in][]"
            value={"床暖房"}
            // checked={values.floorHeating}
            defaultChecked={include_30?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"floorHeating"}>
            床暖房
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"gasHeating"}
            name="detail[features_name_in][]"
            value={"ガス暖房"}
            // checked={values.gasHeating}
            defaultChecked={include_31?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"gasHeating"}>
            ガス暖房
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"oilHeating"}
            name="detail[features_name_in][]"
            value={"石油暖房"}
            // checked={values.oilHeating}
            defaultChecked={include_32?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"oilHeating"}>
            石油暖房
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"walkInCloset"}
            name="detail[features_name_in][]"
            value={"ウォークインクローゼット"}
            // checked={values.walkInCloset}
            defaultChecked={include_33?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"walkInCloset"}>
            ウォークインクローゼット
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"underfloorStorage"}
            name="detail[features_name_in][]"
            value={"床下収納"}
            // checked={values.underfloorStorage}
            defaultChecked={include_34?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"underfloorStorage"}>
            床下収納
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"trunkRoom"}
            name="detail[features_name_in][]"
            value={"トランクルーム"}
            // checked={values.trunkRoom}
            defaultChecked={include_35?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"trunkRoom"}>
            トランクルーム
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"internetConnection"}
            name="detail[features_name_in][]"
            value={"インターネット接続"}
            // checked={values.internetConnection}
            defaultChecked={include_36?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"internetConnection"}>
            インターネット接続
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"csAntenna"}
            name="detail[features_name_in][]"
            value={"CSアンテナ"}
            // checked={values.csAntenna}
            defaultChecked={include_38?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"csAntenna"}>
            CSアンテナ
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"bsAntenna"}
            name="detail[features_name_in][]"
            value={"BSアンテナ"}
            // checked={values.bsAntenna}
            defaultChecked={include_39?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"bsAntenna"}>
            BSアンテナ
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"catv"}
            name="detail[features_name_in][]"
            value={"CATV"}
            // checked={values.catv}
            defaultChecked={include_40?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"catv"}>
            CATV
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"wiredBroadcasting"}
            name="detail[features_name_in][]"
            value={values.wiredBroadcasting}
            // checked={values.wiredBroadcasting}
            defaultChecked={include_41?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"wiredBroadcasting"}>
            有線放送
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"deliveryBox"}
            name="detail[features_name_in][]"
            value={"宅配ボックス"}
            // checked={values.deliveryBox}
            defaultChecked={include_42?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"deliveryBox"}>
            宅配ボックス
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"doorPhone"}
            name="detail[features_name_in][]"
            value={"モニタ付きドアホン"}
            // checked={values.doorPhone}
            defaultChecked={include_43?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"doorPhone"}>
            モニタ付きドアホン
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"autoLock"}
            name="detail[features_name_in][]"
            value={"オートロック"}
            // checked={values.autoLock}
            defaultChecked={include_44?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"autoLock"}>
            オートロック
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"isCaretaker"}
            name="detail[features_name_in][]"
            value={"管理人あり"}
            // checked={values.isCaretaker}
            defaultChecked={include_45?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"isCaretaker"}>
            管理人あり
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"isElevator"}
            name="detail[features_name_in][]"
            value={"エレベーターあり"}
            // checked={values.isElevator}
            defaultChecked={include_46?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"isElevator"}>
            エレベーターあり
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"flooring"}
            name="detail[features_name_in][]"
            value={"フローリング"}
            // checked={values.flooring}
            defaultChecked={include_47?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"flooring"}>
            フローリング
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"indoorWashingMachineStorage"}
            name="detail[features_name_in][]"
            value={"室内洗濯機置き場"}
            // checked={values.indoorWashingMachineStorage}
            defaultChecked={include_48?.length > 0}
            onChange={handleSelectValues}
          />
          <label
            className="searchRent_name"
            htmlFor={"indoorWashingMachineStorage"}
          >
            室内洗濯機置き場
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"cityGas"}
            name="detail[features_name_in][]"
            value={"都市ガス"}
            // checked={values.cityGas}
            defaultChecked={include_49?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"cityGas"}>
            都市ガス
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"propaneGas"}
            name="detail[features_name_in][]"
            value={"プロパンガス"}
            // checked={values.propaneGas}
            defaultChecked={include_50?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"propaneGas"}>
            プロパンガス
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"allElectric"}
            name="detail[features_name_in][]"
            value={values.allElectric}
            // checked={values.allElectric}
            defaultChecked={include_51?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"allElectric"}>
            オール電化
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"privateGarden"}
            name="detail[features_name_in][]"
            value={"専用庭"}
            // checked={values.privateGarden}
            defaultChecked={include_52?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"privateGarden"}>
            専用庭
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"bayWindow"}
            name="detail[features_name_in][]"
            value={"出窓"}
            // checked={values.bayWindow}
            defaultChecked={include_53?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"bayWindow"}>
            出窓
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"balcony"}
            name="detail[features_name_in][]"
            value={"バルコニー"}
            // checked={values.balcony}
            defaultChecked={include_54?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"balcony"}>
            バルコニー
          </label>
        </li>
        <li className="searchRent_item formStyle_check">
          <input
            type="checkbox"
            id={"withLoft"}
            name="detail[features_name_in][]"
            value={"ロフト付き"}
            // checked={values.withLoft}
            defaultChecked={include_55?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"withLoft"}>
            ロフト付き
          </label>
        </li>
        <li className="searchRent_item formStyle_check col-100">
          <input
            type="checkbox"
            id={"tiling"}
            name="detail[features_name_in][]"
            value={"タイル貼り"}
            // checked={values.tiling}
            defaultChecked={include_56?.length > 0}
            onChange={handleSelectValues}
          />
          <label className="searchRent_name" htmlFor={"tiling"}>
            タイル貼り
          </label>
        </li>
      </ul>
    </div>
  );
};

export default Particulars;
