<template>
  <div>
    <navi-header />
    <main role="main">
      <div class="contents-box my-2 py-2">
        <div class="container">
          <room-info />
        </div>
        <step @changeCurrentStep="onStepSelected" />
      </div>
      <welcome v-if="isFirstStep"/>
      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}</h2>
      <div class="contents-box my-2">
        <div class="container pt-3">
          <h3 class="h5 font-weight-bold mb-3">お部屋を出たら、ドアジョイナーでしっかり施錠してください。</h3>
          <div class="step-flow pb-3">
            <p>ドアジョイナーが解錠したときと同様の暗証番号の状態のままになっていることを確認してから、もともと施錠されていたのと同じ場所に設置します。</p>
            <div class="bg-primary p-2 text-center text-white">
              お客様の暗証番号：{{doorJoinerPassword}}
            </div>
          </div>
        </div>
      </div>

      <h2 class="contents-title font-weight-bold container mt-3">STEP{{reservationStep}}-2</h2>
      <div class="contents-box my-2">
        <div class="container pt-3">
          <h3 class="h5 font-weight-bold mb-3">次に暗証番号以外の番号になるように数値をランダムに動かして、施錠をしてください。</h3>
          <div class="step-flow">
            <div class="image-box pb-3">
              <img src="~navi/lock_door_joiner.png" class="w-100 rounded">
            </div>
          </div>
        </div>
      </div>
    </main>
    <navi-footer @goToNextStep="onNextStepSelected" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NaviHeader from '@/components/navi/common/Header'
import RoomInfo from '@/components/navi/common/RoomInfo'
import Step from '@/components/navi/common/Step'
import Welcome from '@/components/navi/common/Welcome'
import NaviFooter from '@/components/navi/common/Footer'
export default {
  name: 'LockDoorJoiner',
  components: { NaviHeader, RoomInfo, Step, NaviFooter, Welcome },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('reservation', ['reservationStep']),
    ...mapGetters('flowDetails', ['flowDetailsAll']),
    ...mapGetters('doorJoiner', ['doorJoinerPassword', 'doorJoinerImageUrl']),
    isFirstStep() {
      return this.reservationStep == 1
    },
    isLastStep() {
      return this.flowDetailsAll.length == this.reservationStep
    }
  },
  methods: {
    onNextStepSelected() {
      this.$emit('goToNextStep')
    },
    onStepSelected() {
      this.$emit('setCurrentFlowDetail')
    }
  }
}
</script>

<style scoped>
</style>

