import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import Properties from "./Properties";
import RecommendPropertyModal from "./RecommendPropertyModal";
import iconCloseW from "../../../../assets/images/icon/icon_close_white.svg";
import iconClose from "../../../../assets/images/icon/icon-close.svg";
import comingSoon from "../../../../assets/images/coming_soon.png";

const RecommendProperties = ({
  handleRecommendPropertyModal,
}) => {
  const Container = styled.div`
    display: flex;
    justify-content: center;
  `;

  const SliderWrapper = styled.div`
    width: calc(100% - 50px);

    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
  `;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className="MapPropertiesModal">
      <div className="MapPropertiesModal_overlay">
        <div className="MapPropertiesModal_contents right">
          <button className="MapPropertiesModal_close">
            <img className="MapPropertiesModal_close-white" src={iconCloseW} />
            <img className="MapPropertiesModal_close-black" src={iconClose} />
          </button>
          <Slider {...sliderSettings}>
            <div className="MapPropertiesModal_item">
              <div className="MapPropertiesModal_movieArea">
                <img src={comingSoon} />
              </div>
              <div className="MapPropertiesModal_info">
                <div className="MapPropertiesModal_title">
                  AAAレジデンス
                  <div className="MapPropertiesModal_roomNum">301号室</div>
                </div>
                <div className="MapPropertiesModal_price">
                  <div className="MapPropertiesModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="btnStyle-fill">
                  このお部屋をもっと見てみる
                </div>
              </div>
            </div>
            <div className="MapPropertiesModal_item">
              <div className="MapPropertiesModal_movieArea">
                <img src={comingSoon} />
              </div>
              <div className="MapPropertiesModal_info">
                <div class="MapPropertiesModal_title">
                  AAAレジデンス
                  <div class="MapPropertiesModal_roomNum">301号室</div>
                </div>
                <div className="MapPropertiesModal_price">
                  <div className="MapPropertiesModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="btnStyle-fill">
                  このお部屋をもっと見てみる
                </div>
              </div>
            </div>
            <div className="MapPropertiesModal_item">
              <div className="MapPropertiesModal_movieArea">
                <img src={comingSoon} />
              </div>
              <div className="MapPropertiesModal_info">
                <div class="MapPropertiesModal_title">
                  AAAレジデンス
                  <div class="MapPropertiesModal_roomNum">301号室</div>
                </div>
                <div className="MapPropertiesModal_price">
                  <div className="MapPropertiesModal_rent">
                    <span>10.2</span>
                    万円
                  </div>
                  <div className="MapPropertiesModal_fee">
                    （管理費
                    <span>8000</span>
                    円）
                  </div>
                </div>
                <div className="MapPropertiesModal_overview">
                  JR山手線 他 / 渋谷駅　徒歩10分
                  <br/>
                  1K / 19.35㎡ / 3階 / 築10年
                </div>

                <div className="btnStyle-fill">
                  このお部屋をもっと見てみる
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

//自動再生、ミュートする場合
// const isVideoInFullscreen = () => {
//   if (document.fullscreenElement && document.fullscreenElement.nodeName === 'VIDEO') {
//     return true;
//   }
// videoElement.volume = 0;
// videoElement.autoplay = true;
// };

// return (
// <VideoWindow
//   onMouseOver={() => setMouseOver(true)}
//   onMouseLeave={() => setMouseOver(false)}
//   ref={WindowRef}
// >
//   {Object.entries(videos).map(([index, videoPath]) => {
//     return (
//       <video
//         id={`videoMedia-${index}`}
//         key={index}
//         src={videoPath}
//
//       />
//     );
//   })}

//   <Fade in={mouseOver}>
//     <div ref={fade}>
//       <PlayButton>
//         <IconButton
//           dark
//           id={`btnOverlay${playing ? 'Pause' : 'Play'}`}
//           onClick={playing ? clickPause : clickPlay}
//         >
//           {playing ? (
//             <PauseRounded />
//           ) : (
//             <PlayArrowRounded />
//           )}
//         </IconButton>
//       </PlayButton>
//       <Menubar>
//         <IconButton dark id={`btnFullscreen`} onClick={toggleFullScreen}>
//         </IconButton>
//       </Menubar>
//     </div>
//   </Fade>
// </VideoWindow>
// );

export default RecommendProperties;
