import React, { useState } from "react";
import { Button } from "@material-ui/core";
import BuildingType from "./DetailConditions/BuildingType";
import FloorType from "./DetailConditions/FloorPlan";
import Rent from "./DetailConditions/Rent";
import Structure from "./DetailConditions/Structure";
import WalkFromStation from "./DetailConditions/WalkFromStation";
import Particulars from "./DetailConditions/Particulars";
import iconCondition from "../../../../assets/images/icon/icon_condition.svg";
import iconSearch from "../../../../assets/images/icon_search.svg";

const DetailConditions = (props) => {
  const [rentConditions, setRentConditions] = useState({
    lower: 0,
    upper: 0,
    commonServiceFee: false,
    parkingFee: false,
    noKeyMoney: false,
    noDeposit: false,
    initialCostPayment: false,
    rentCostPayment: false,
  });
  const [floorTypeConditions, setFloorTypeConditions] = useState({
    oneRoom: false,
    oneK: false,
    oneDk: false,
    oneLdk: false,
    twoK: false,
    twoDk: false,
    twoLdk: false,
    threeK: false,
    threeDk: false,
    threeLdk: false,
    fourK: false,
    fourDk: false,
    fourLdk: false,
    upperFiveK: false,
  });
  const [buildingTypeConditions, setBuildingTypeConditions] = useState({
    largeApartment: false,
    apartment: false,
    detachedHouse: false,
  });
  const [structureConditions, setStructure] = useState({
    reinforcingBar: false,
    steelFrame: false,
    wooden: false,
    block: false,
  });
  const [walkFromStationCondition, setWalkFromStationCondition] = useState("1");
  const [particularConditions, setParticularConditions] = useState({
    firstFloor: false,
    upperSecondFloor: false,
    topFloor: false,
    cornerRoom: false,
    southFacing: false,
    towerApartment: false,
    barrierFree: false,
    designers: false,
    specifiedExcellentRentalHousing: false,
    immediateMoveIn: false,
    noGuarantor: false,
    musicalInstrument: false,
    officeAvailable: false,
    womenOnly: false,
    withFurnitureAppliances: false,
    bath: false,
    gasStove: false,
    ihStove: false,
    twoOrMoreStoves: false,
    systemKitchen: false,
    counterKitchen: false,
    isRefrigerator: false,
    bathAndToiletSeparated: false,
    reheating: false,
    independentWashroom: false,
    warmWaterWashingToilet: false,
    bathroomDryer: false,
    isToilet: false,
    airConditioner: false,
    floorHeating: false,
    gasHeating: false,
    oilHeating: false,
    walkInCloset: false,
    underfloorStorage: false,
    trunkRoom: false,
    internetConnection: false,
    csAntenna: false,
    bsAntenna: false,
    catv: false,
    wiredBroadcasting: false,
    deliveryBox: false,
    doorPhone: false,
    autoLock: false,
    isCaretaker: false,
    isElevator: false,
    flooring: false,
    indoorWashingMachineStorage: false,
    cityGas: false,
    propaneGas: false,
    allElectric: false,
    privateGarden: false,
    bayWindow: false,
    balcony: false,
    withLoft: false,
    tiling: false,
  });

  const handleRentConditions = (event) => {
    let val = event.target.checked;
    if (event.target.id === "lower" || event.target.id === "upper") {
      val = event.target.value;
    }
    setRentConditions({
      ...rentConditions,
      [event.target.id]: val,
    });
  };
  const handleFloorTypeConditions = (event) => {
    setFloorTypeConditions({
      ...floorTypeConditions,
      [event.target.id]: event.target.checked,
    });
  };
  const handleStructureConditions = (event) => {
    setStructure({
      ...structureConditions,
      [event.target.id]: event.target.checked,
    });
  };

  const handleBuildingTypeConditions = (event) => {
    setBuildingTypeConditions({
      ...buildingTypeConditions,
      [event.target.id]: event.target.checked,
    });
  };
  const handleParticularConditions = (event) => {
    setParticularConditions({
      ...particularConditions,
      [event.target.id]: event.target.checked,
    });
  };

  const handleWalkFromStationCondition = (value) => {
    setWalkFromStationCondition(value);
  };

  return (
    <div hidden={!props.isShow}>
      <div className="breadcrumbStyle">
        <a href="/">トップ</a>
        <span> ＞ </span>
        <a href="#">地域から探す</a>
        <span> ＞ </span>
        <span>条件を絞り込む</span>
      </div>

      <h2 className="text-center searchRent_title">
        <img src={iconCondition} />
        条件を絞り込む
      </h2>
      <div className="searchRent_wrapper">
        <div className="searchRent_conditionsWrapper">
          <Rent
            values={rentConditions}
            handleSelectValues={handleRentConditions}
            details={props.details}
          />
          <FloorType
            values={floorTypeConditions}
            handleSelectValues={handleFloorTypeConditions}
            rooms_type_in={props.details}
          />
          <BuildingType
            values={buildingTypeConditions}
            handleSelectValues={handleBuildingTypeConditions}
            rooms_building_in={props.details}
          />
          <Structure
            values={structureConditions}
            handleSelectValues={handleStructureConditions}
            rooms_structure_in={props.details}
          />
          <Particulars
            values={particularConditions}
            handleSelectValues={handleParticularConditions}
            rooms_features_name_in={props.details}
          />
          <WalkFromStation
            value={walkFromStationCondition}
            handleSelectValue={handleWalkFromStationCondition}
            property_accesses_walkby_lteq={props.details}
          />
        </div>

        <div className="searchArea_bottomMenu">
          <div
            className="searchArea_bottomMenuWrapper mx-auto"
            style={{ justifyContent: "center" }}
          >
            <button type="submit" className="btnStyle-fill">
              <img src={iconSearch} />
              検索する
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailConditions;
