import React, { useRef, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Properties from "../../top/Properties";
import imgCharacter from "../../../../../assets/images/character/character08.png";

const RecommendationTop = React.memo(
  ({ tiktok_videos, oheyaGirlVideos, handleRecommendPropertyModal }) => {
    console.log(oheyaGirlVideos)
    let slideNumber = oheyaGirlVideos.length;
    if (slideNumber == 1) {
      slideNumber = 1;
    } 
    else if (slideNumber >= 4) {
      slideNumber = 3;
    }
    else {
      slideNumber -= 1;
    }

    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: slideNumber,
      centerMode: true,
      slidesToScroll: 1,
      arrows: true,
      centerPadding: "0px",
      dots: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: "50px",
            slidesToShow: 1,
          },
        },
      ],
    };
    const sliderRef = useRef();
    const playArrRef = useRef([]);
    const audioArrRef = useRef([]);
    const goToNext = useCallback((current) => {
      let nextNum = 0;
      if (current < oheyaGirlVideos.length - 1) {
        nextNum = current + 1;
      }
      sliderRef.current.slickGoTo(nextNum);
      playArrRef.current[nextNum].current.play();
    }, []);

    const properties = oheyaGirlVideos.map((item, index) => (
      <div className="recommend_item" key={item.id}>
        <Properties
          property={item.property}
          room={item}
          contracts={item.contracts}
          property_accesses={item.property.property_accesses}
          property_movie_url={item.property_movie_url}
          room_movie_url={item.room_movie_url}
          videoUrl={item.room_movie_url[0].movie_url}
          playArrRef={playArrRef}
          audioArrRef={audioArrRef}
          myNumber={index}
          handleEndCatch={goToNext}
          handleRecommendPropertyModal={handleRecommendPropertyModal}
        />
      </div>
    ));

    return (
      <div className="recommend">
        <div class="recommend_top">
          <p class="recommend_text">
            このお部屋を予約した人は
            <br />
            こっちのお部屋も見ているよ！
          </p>
          <img src={imgCharacter} />
        </div>
        <Slider {...sliderSettings} ref={sliderRef}>
          {properties}
        </Slider>
      </div>
    );
  }
);

export default RecommendationTop;
