import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScriptNext,
  MarkerClusterer,
  StreetViewPanorama,
  useGoogleMap
} from "@react-google-maps/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { propertiesState, centerState, placeChangedState, isCurrentLocationState, boundState } from "./state";
import axios from "axios";
import SearchForm from "./SearchForm";
import { PropertiesApiPath } from "./common";
import MapProperties from "../top/MapProperties";
import iconMapArea from "../../../../assets/images/icon/icon-map-area.svg";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Map = (props) => {
  const [properties, setProperties] = useRecoilState(propertiesState);
  const [center, setCenter] = useRecoilState(centerState);
  const [bound, setBound] = useRecoilState(boundState);
  const [ libraries ] = useState(['places']);
  const placeChanged = useRecoilValue(placeChangedState);
  const [zoom, setZoom] = useState(12);
  const [mapObj, setMap] = useState()

  useEffect(() => {
    if(props.isCurrentLocation) {
      setCenterPosition()
    }
    boundsAreaSearch()
  }, []);

  //useEffect(() => boundsAreaSearch(), [mapObj]);

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  const setCenterPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setZoom(16);
      setCenter({
        lat: Number(latitude),
        lng: Number(longitude)
      })
    })
  }

  const boundsAreaSearch = (map) => {
    const bounds = map?.getBounds();
    if (!center && !bounds) return;

    const boundParams =  {
      south_west_lat: bounds ? bounds?.getSouthWest()?.lat() :center.lat-0.1,
      north_east_lat: bounds ? bounds?.getNorthEast()?.lat() :center.lat+0.1,
      south_west_lon: bounds ? bounds?.getSouthWest()?.lng() : center.lng-0.5,
      north_east_lon: bounds ? bounds?.getNorthEast()?.lng() : center.lng+0.5
    }

    axios.get(PropertiesApiPath, {
      params: boundParams
    }).then(res => {
      setBound(boundParams)
      setProperties(res.data.properties);
    })
  }

  const addBoundsAreaSearchEvent = (controlDiv, map) => {
    const controlButton = document.createElement("button");
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.color = "#666";
    controlButton.style.borderRadius = "999px";
    controlButton.style.fontSize = "14px";
    controlButton.style.fontWeight = "600";
    controlButton.style.marginTop = "8px";
    controlButton.style.marginRight = "2px";
    controlButton.style.padding = "3px 8px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.type = "button"
    controlButton.innerHTML = "このエリアで検索";
    controlDiv.appendChild(controlButton);
    controlButton.addEventListener("click", () => {
      boundsAreaSearch(map)
    });
  };

  const onLoad = useCallback((map) => {
    const customControlDiv = document.createElement("div");
    addBoundsAreaSearchEvent(customControlDiv, map);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(customControlDiv);
    setMap(map)
  }, [])

  const clusterStyles = [
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
    {
      textColor: "white",
      url: iconMapArea,
      height: 32,
      width: 32,
    },
  ];

  const options = {
    gridSize: 32,
    styles: clusterStyles,
    // maxZoom: 15,
  };

  const result = properties.filter(
    (element, index, self) =>
      self.findIndex((e) => e.lat === element.lat && e.lon === element.lon) === index
  );
  const result_have_rooms = result.map((property) => {
      if (!property.rooms.length) return;
      return property;
    });
  const result_boolean = result_have_rooms.filter(Boolean);

  return (
    <LoadScriptNext googleMapsApiKey={props.googleMapsApiKey} libraries={libraries}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        options={mapOptions}
        className="top_googleMap"
        onBoundsChanged={() => {
          boundsAreaSearch(mapObj)
        }}
      >
        {props.isStreetView ? (
          <StreetViewPanorama position={center} visible={true} />
        ) : (
          <>
            <SearchForm />
            <MarkerClusterer options={options} key="1">
              {(clusterer) => {
                const component = [];
                result_boolean.map((property) => {
                  component.push(
                    <MapProperties
                      key={property.id}
                      property={property}
                      clusterer={clusterer}
                    ></MapProperties>
                  );
                });
                return component;
              }}
            </MarkerClusterer>
          </>
        )}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
