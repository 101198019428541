import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { modalShowState, modalData, streetViewShowState } from "./state";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { roomLayoutEnums } from "../../enums/layouts";
import { propertyStructureEnums } from "../../enums/structure";
import { propertyDirectionEnums } from "../../enums/direction";
import { feeUnitEnums } from "../../enums/unit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropertyModal from "../top/PropertyModal";
import comingSoon from "../../../../assets/images/coming_soon";
import iconBathtubGreen from "../../../../assets/images/icon/icon-bathtub-green.svg";
import iconBathtubGray from "../../../../assets/images/icon/icon-bathtub-gray.svg";
import iconHottubGreen from "../../../../assets/images/icon/icon-hot_tub-green.svg";
import iconHottubGray from "../../../../assets/images/icon/icon-hot_tub-gray.svg";
import iconCountertopsGreen from "../../../../assets/images/icon/icon-countertops-green.svg";
import iconCountertopsGray from "../../../../assets/images/icon/icon-countertops-gray.svg";
import iconLaundryGreen from "../../../../assets/images/icon/icon-local_laundry_service-green.svg";
import iconLaundryGray from "../../../../assets/images/icon/icon-local_laundry_service-gray.svg";
import iconWindyGreen from "../../../../assets/images/icon/icon-windy-green.svg";
import iconWindyGray from "../../../../assets/images/icon/icon-windy-gray.svg";
import iconBuildingGreen from "../../../../assets/images/icon/icon-building-green.svg";
import iconBuildingGray from "../../../../assets/images/icon/icon-building-gray.svg";
import iconBearGreen from "../../../../assets/images/icon/icon-bear-green.svg";
import iconBearGray from "../../../../assets/images/icon/icon-bear-gray.svg";
import iconTruckGreen from "../../../../assets/images/icon/icon-truck-green.svg";
import iconTruckGray from "../../../../assets/images/icon/icon-truck-gray.svg";
import iconCarGreen from "../../../../assets/images/icon/icon-car-green.svg";
import iconCarGray from "../../../../assets/images/icon/icon-car-gray.svg";
import iconDoorGreen from "../../../../assets/images/icon/icon-door-lock-box-green.svg";
import iconDoorGray from "../../../../assets/images/icon/icon-door-lock-box-gray.svg";
import character from "../../../../assets/images/character/character06.png";
import { propertyKindEnums } from "../../enums/propertyKind";
import MapViewModal from "./MapViewModal";
import StreetViewModal from "./StreetViewModal";

const Modal = () => {
  const [data, setData] = useState(null);
  const [roomImages, setRoomImages] = useState([]);
  const [roomFeatures, setRoomFeatures] = useState([]);
  const [propertyMovieUrl, setPropertyMovieUrl] = useState([]);
  const [roomMovieUrl, setRoomMovieUrl] = useState([]);
  const setModalShow = useSetRecoilState(modalShowState);
  const closeModal = () => setModalShow(false);
  const room = useRecoilValue(modalData);
  const [isStreetView, setShowStreetView] = useRecoilState(streetViewShowState);

  useEffect(() => {
    if (room) {
      const getRoom = async () => {
        await axios.get(`/api/portal/rooms/${room.id}`).then((res) => {
          setData(res.data);
          setRoomFeatures(res.data.room_features);
          setRoomImages(res.data.room_images);
          setPropertyMovieUrl(res.data.property_movie_url);
          setRoomMovieUrl(res.data.room_movie_url);
        });
      };
      getRoom();
    }
    return () => setData(null);
  }, []);

  const handleGoToReservation = () => {
    location.href = `/reservations/${data.id}`;
  };
  const elapsedYearsAndMonth = (year, month) => {
    const nowDate = new Date();
    const buildingDate = new Date(1970, 0, 1);
    buildingDate.setTime(nowDate.getTime() - buildingDate.getTime());
    let elapsedYear = buildingDate.getUTCFullYear() - year;
    let elapsedMonth = buildingDate.getUTCMonth() - (month - 1);
    if (elapsedMonth < 0) {
      elapsedYear--;
      elapsedMonth += 12;
    }
    return `${year}年${month}月（築${elapsedYear}年${elapsedMonth}ヶ月）`;
  };

  const photo = [1, 2, 3, 4, 5];
  var thumbnail = photo.length;
  if (thumbnail >= 16) {
    thumbnail = 16;
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const sliderNavSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: roomImages.length,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    focusOnSelect: true,
  };

  const [tabIndex, setTabIndex] = useState(0);
  const underline = {
    borderBottom: "solid 3px #04bfad",
    fontWeight: "bold",
    width: "100%",
    marginBottom: "0px",
    paddingBottom: "1px",
  };

  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const [ModalAppear, setModalAppear] = useState(false);
  const [mapViewModal, setMapViewModal] = useState(false);

  const oheyaGirlVideo = roomMovieUrl.filter(
    (item) => item.role === "oheya_girl"
  );
  const interiorVideo = roomMovieUrl.filter((item) => item.role === "interior");
  const appearanceVideo = propertyMovieUrl.filter(
    (item) => item.role === "appearance"
  );
  const sharedVideo = propertyMovieUrl.filter((item) => item.role === "shared");
  const surroundingVideo = propertyMovieUrl.filter(
    (item) => item.role === "surrounding"
  );

  const moreThanSecondFloor = roomFeatures.filter(
    (item) => item.feature.name === "2階以上の部屋"
  );
  const havePets = roomFeatures.filter(
    (item) => item.feature.name === "ペット相談"
  );
  const separateBath = roomFeatures.filter(
    (item) => item.feature.name === "バス・トイレ別"
  );
  const reHeating = roomFeatures.filter(
    (item) => item.feature.name === "追い焚き"
  );
  const washroom = roomFeatures.filter(
    (item) => item.feature.name === "洗面所独立"
  );
  const airConditioner = roomFeatures.filter(
    (item) => item.feature.name === "エアコン"
  );
  const deliverBox = roomFeatures.filter(
    (item) => item.feature.name === "宅配ボックス"
  );
  const washingMachine = roomFeatures.filter(
    (item) => item.feature.name === "室内洗濯機置き場"
  );
  const autoLock = roomFeatures.filter(
    (item) => item.feature.name === "オートロック"
  );

  const bathAndToilet = roomFeatures.filter(
    (item) =>
      item.feature.name === "バス・トイレ別" ||
      item.feature.name === "追い焚き" ||
      item.feature.name === "洗面所独立" ||
      item.feature.name === "温水洗浄便座" ||
      item.feature.name === "浴室乾燥機" ||
      item.feature.name === "トイレあり"
  );

  const kitchen = roomFeatures.filter(
    (item) =>
      item.feature.name === "ガスコンロ" ||
      item.feature.name === "IHコンロ" ||
      item.feature.name === "コンロ二口以上" ||
      item.feature.name === "システムキッチン" ||
      item.feature.name === "カウンターキッチン" ||
      item.feature.name === "冷蔵庫あり" ||
      item.feature.name === "都市ガス" ||
      item.feature.name === "プロパンガス" ||
      item.feature.name === "オール電化"
  );

  const security = roomFeatures.filter(
    (item) =>
      item.feature.name === "モニタ付きドアホン" ||
      item.feature.name === "オートロック" ||
      item.feature.name === "管理人あり"
  );

  const insideFacility = roomFeatures.filter(
    (item) =>
      item.feature.name === "家具家電付き" ||
      item.feature.name === "エアコン" ||
      item.feature.name === "床暖房" ||
      item.feature.name === "ガス暖房" ||
      item.feature.name === "石油暖房" ||
      item.feature.name === "ウォークインクローゼット" ||
      item.feature.name === "床下収納" ||
      item.feature.name === "トランクルーム" ||
      item.feature.name === "インターネット接続" ||
      item.feature.name === "CSアンテナ" ||
      item.feature.name === "BSアンテナ" ||
      item.feature.name === "CATV" ||
      item.feature.name === "有線放送" ||
      item.feature.name === "フローリング" ||
      item.feature.name === "室内洗濯機置き場" ||
      item.feature.name === "専用庭" ||
      item.feature.name === "出窓" ||
      item.feature.name === "バルコニー" ||
      item.feature.name === "ロフト付き" ||
      item.feature.name === "タイル貼り"
  );

  const sharing = roomFeatures.filter(
    (item) =>
      item.feature.name === "宅配ボックス" ||
      item.feature.name === "エレベーターあり"
  );

  const others = roomFeatures.filter(
    (item) =>
      item.feature.name === "1階の部屋" ||
      item.feature.name === "2階以上の部屋" ||
      item.feature.name === "最上階の部屋" ||
      item.feature.name === "角部屋" ||
      item.feature.name === "南向き" ||
      item.feature.name === "タワーマンション" ||
      item.feature.name === "バリアフリー" ||
      item.feature.name === "デザイナーズ" ||
      item.feature.name === "特定優良賃貸住宅" ||
      item.feature.name === "ペット相談" ||
      item.feature.name === "2人入居可" ||
      item.feature.name === "即入居可" ||
      item.feature.name === "保証人不要" ||
      item.feature.name === "楽器相談" ||
      item.feature.name === "事務所可" ||
      item.feature.name === "女性限定"
  );

  const handleToDate = (date) => {
    date = new Date(date);
    if (date.getMinutes() < 10) {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " ";
    } else {
      date =
        date.getFullYear() +
        "/" +
        ((date.getMonth() % 12) + 1) +
        "/" +
        date.getDate() +
        " ";
    }

    return date;
  };


  return (
    <>
      {data ? (
        <div className="PropertiesModal">
          <div className="PropertiesModal_overlay">
            <div className="PropertiesModal_contents">
              <div className="PropertiesModal_header">
                <h3 className="PropertiesModal_title">
                  {data.property.name} {data.name}
                </h3>
                <button onClick={closeModal} className="PropertiesModal_close">
                  <img src="/assets/icon/icon-close.svg" />
                </button>
              </div>
              <div className="PropertiesModal_body">
                {/*動画エリア*/}
                <div className="PropertiesModal_movieArea">
                  {/*動画カテゴリタブ*/}
                  <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                  >
                    <TabList className="PropertiesModal_tab">
                      <Tab className="PropertiesModal_tabItem">
                        <div style={tabIndex === 0 ? underline : null}>
                          TikTok
                        </div>
                      </Tab>
                      <Tab className="PropertiesModal_tabItem">
                        <div style={tabIndex === 1 ? underline : null}>
                          内観
                        </div>
                      </Tab>
                      <Tab className="PropertiesModal_tabItem">
                        <div style={tabIndex === 2 ? underline : null}>
                          外観
                        </div>
                      </Tab>
                      <Tab className="PropertiesModal_tabItem">
                        <div style={tabIndex === 3 ? underline : null}>
                          共有部
                        </div>
                      </Tab>
                      <Tab className="PropertiesModal_tabItem">
                        <div style={tabIndex === 4 ? underline : null}>
                          周辺環境
                        </div>
                      </Tab>
                    </TabList>

                    <TabPanel className="PropertiesModal_movie">
                      {oheyaGirlVideo.length === 0 ? (
                        <img src={comingSoon} />
                      ) : (
                        <VideoPlayer src={oheyaGirlVideo[0].movie_url} />
                      )}
                    </TabPanel>
                    <TabPanel className="PropertiesModal_movie">
                      {interiorVideo.length === 0 ? (
                        <img src={comingSoon} />
                      ) : (
                        <VideoPlayer src={interiorVideo[0].movie_url} />
                      )}
                    </TabPanel>
                    <TabPanel className="PropertiesModal_movie">
                      {appearanceVideo.length === 0 ? (
                        <img src={comingSoon} />
                      ) : (
                        <VideoPlayer src={appearanceVideo[0].movie_url} />
                      )}
                    </TabPanel>
                    <TabPanel className="PropertiesModal_movie">
                      {sharedVideo.length === 0 ? (
                        <img src={comingSoon} />
                      ) : (
                        <VideoPlayer src={sharedVideo[0].movie_url} />
                      )}
                    </TabPanel>
                    <TabPanel className="PropertiesModal_movie">
                      {surroundingVideo.length === 0 ? (
                        <img src={comingSoon} />
                      ) : (
                        <VideoPlayer src={surroundingVideo[0].movie_url} />
                      )}
                    </TabPanel>
                  </Tabs>
                  {/*動画カテゴリタブ end*/}
                </div>
                {/*動画エリア end*/}
                {/*概要*/}
                <div className="PropertiesModalOverview">
                  <div className="PropertiesModalOverview_price">
                    <span className="PropertiesModalOverview_rent">
                      {data.contracts[0]?.rent ? (
                        <span>
                          {(data.contracts[0]?.rent / 10000).toFixed(1)}
                        </span>
                      ) : (
                        " お問い合わせください "
                      )}
                      {data.contracts[0]?.rent && "万円"}
                    </span>
                    <span className="PropertiesModalOverview_fee">
                      （管理費
                      <span>
                        {data.contracts[0]?.management_fee ? (
                          <>
                            {data.contracts[0]?.management_fee.toLocaleString()}
                          </>
                        ) : (
                          " "
                        )}
                      </span>
                      円）
                    </span>
                  </div>
                  <div className="PropertiesModalOverview_box">
                    <div className="PropertiesModalOverview_flex">
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">敷金</div>
                        <div className="PropertiesModalOverview_td">
                          {data.contracts[0]?.rent &&
                            data.contracts[0]?.shikikin}{" "}
                          {data.contracts[0]?.rent &&
                            feeUnitEnums[data.contracts[0]?.shikikin_unit]}
                        </div>
                      </div>
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">礼金</div>
                        <span>
                          <div className="PropertiesModalOverview_td">
                            {data.contracts[0]?.reikin &&
                              data.contracts[0]?.reikin}{" "}
                            {data.contracts[0]?.reikin &&
                              feeUnitEnums[data.contracts[0]?.reikin_unit]}
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="PropertiesModalOverview_flex">
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">間取り</div>
                        <div className="PropertiesModalOverview_td">
                          {data?.layout && roomLayoutEnums[data?.layout]}
                        </div>
                      </div>
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">面積</div>
                        <div className="PropertiesModalOverview_td">
                          {data?.size && data?.size}㎡
                        </div>
                      </div>
                    </div>
                    <div className="PropertiesModalOverview_flex">
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">階建</div>
                        <div className="PropertiesModalOverview_td">
                          {data?.floor && data?.floor}階 /{" "}
                          {data.property?.floor && data.property?.floor}階
                        </div>
                      </div>
                      <div className="PropertiesModalOverview_flexItem">
                        <div className="PropertiesModalOverview_th">種別</div>
                        <div className="PropertiesModalOverview_td">
                          {/* 1ヶ月 */}
                          {data.property?.layout &&
                            propertyKindEnums[data.property?.layout]}
                        </div>
                      </div>
                    </div>
                    <div className="PropertiesModalOverview_tr">
                      <DetailItem
                        label={"築年月"}
                        values={[
                          elapsedYearsAndMonth(
                            data.property.building_year,
                            data.property.building_month
                          ),
                        ]}
                      />
                    </div>
                    <div className="PropertiesModalOverview_tr">
                      <DetailItem
                        label={"住所"}
                        values={[data.property.address]}
                      />
                    </div>

                    <div className="PropertiesModalOverview_action">
                      <div
                        className="btnStyle-fill"
                        onClick={() => {
                          setMapViewModal(true);
                        }}
                      >
                        <img src="/assets/icon/icon-map.svg" />
                        地図を見る
                      </div>
                      <div
                        className="btnStyle-fill"
                        onClick={() => {
                          setShowStreetView(true);
                        }}
                      >
                        <img src="/assets/icon/icon-walk.svg" />
                        ストリートビューを見る
                      </div>
                    </div>

                    {data.property.property_accesses?.map((item) => {
                      return (
                        <div className="PropertiesModalOverview_tr">
                          <div className="PropertiesModalOverview_item">
                            <label className="PropertiesModalOverview_th">
                              交通
                            </label>
                            <div className="PropertiesModalOverview_td">
                              {item.station_master.station_line_master.name} /{" "}
                              {item.station_master.name}駅 徒歩{item.walkby}分
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {/* </div> */}
                  </div>
                </div>
                {/*概要 end*/}
                <div className="PropertiesModalReservation">
                  <div className="PropertiesModalReservation_copy">
                    \ すぐに見れるよ！ /
                  </div>
                  <div
                    className="btnStyle-fill"
                    onClick={handleGoToReservation}
                  >
                    <img src="/assets/icon/icon-calender-white.svg" />
                    セルフ内見予約をする
                  </div>
                  <a
                    href="/user_guide"
                    className="PropertiesModalReservation_what"
                  >
                    <img src="/assets/icon/icon-question.svg" />
                    セルフ内見予約って？
                  </a>
                  <a
                    href="/contact"
                    className="PropertiesModalBottom_btn btnStyle-white"
                  >
                    このお部屋についてのお問い合わせ
                  </a>
                </div>

                <div className="PropertiesModalSlickDefault">
                  <Slider
                    {...sliderSettings}
                    asNavFor={slider2}
                    ref={(slider) => setSlider1(slider)}
                    className="slider-for PropertiesModalSlickDefault_for"
                  >
                    {data.room_images.map((value, index) => (
                      <div
                        className="PropertiesModalSlickDefault_item"
                        key={index}
                      >
                        <div className="PropertiesModalSlickDefault_img">
                          <img
                            src={value.name.url}
                            onClick={() => {
                              setModalAppear(true);
                            }}
                          />
                        </div>
                        {/* <p>{imageTypeEnums[value.role]}</p> */}
                      </div>
                    ))}
                  </Slider>

                  <Slider
                    {...sliderNavSettings}
                    asNavFor={slider2}
                    ref={(slider) => setSlider1(slider)}
                    className="slider-nav PropertiesModalSlickDefault_nav"
                  >
                    {data.room_images.map((value, index) => (
                      <div
                        className="PropertiesModalSlickDefault_navItem"
                        key={index}
                      >
                        <img
                          src={value.name.url}
                          onClick={() => {
                            setModalAppear(true);
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="PropertiesModalDesc">
                  <div className="PropertiesModalDesc_character">
                    <div className="PropertiesModalDesc_balloon">
                      良いところあるじゃん！
                    </div>
                    <div className="PropertiesModal_characterImg">
                      <img src="/assets/character/character06.png" />
                    </div>
                  </div>

                  {/* <div className="PropertiesModalDesc_list">
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-bathtub-green.svg" />
                      <p>バストイレ別</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-hot_tub-green.svg" />
                      <p>追い焚き機能</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-countertops-green.svg" />
                      <p>洗面所独立</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-local_laundry_service-green.svg" />
                      <p>室内洗濯機置場</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-windy-green.svg" />
                      <p>エアコン</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-building-green.svg" />
                      <p>2階以上</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-bear-green.svg" />
                      <p>ペット相談可</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-truck-green.svg" />
                      <p>宅配ボックス</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-car-green.svg" />
                      <p>駐車場</p>
                    </div>
                    <div className="PropertiesModalDesc_item">
                      <img src="/assets/icon/icon-door-lock-box-green.svg" />
                      <p>オートロック</p>
                    </div>
                  </div> */}
                  <div className="PropertiesModalDesc_list">
                    <div className="PropertiesModalDesc_item">
                      {separateBath.length === 0 ? (
                        <>
                          <img src={iconBathtubGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            バストイレ別
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconBathtubGreen} />
                          <p>バストイレ別</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {reHeating.length === 0 ? (
                        <>
                          <img src={iconHottubGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            追い焚き機能
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconHottubGreen} />
                          <p>追い焚き機能</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {washroom.length === 0 ? (
                        <>
                          <img src={iconCountertopsGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            洗面所独立
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconCountertopsGreen} />
                          <p>洗面所独立</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {washingMachine.length === 0 ? (
                        <>
                          <img src={iconLaundryGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            室内洗濯機置場
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconLaundryGreen} />
                          <p>室内洗濯機置場</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {airConditioner.length === 0 ? (
                        <>
                          <img src={iconWindyGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            エアコン
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconWindyGreen} />
                          <p>エアコン</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {moreThanSecondFloor.length === 0 ? (
                        <>
                          <img src={iconBuildingGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>2階以上</p>
                        </>
                      ) : (
                        <>
                          <img src={iconBuildingGreen} />
                          <p>2階以上</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {havePets.length === 0 ? (
                        <>
                          <img src={iconBearGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            ペット相談可
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconBearGreen} />
                          <p>ペット相談可</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {deliverBox.length === 0 ? (
                        <>
                          <img src={iconTruckGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            宅配ボックス
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconTruckGreen} />
                          <p>宅配ボックス</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {data.property.have_parking === false ? (
                        <>
                          <img src={iconCarGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>駐車場</p>
                        </>
                      ) : (
                        <>
                          <img src={iconCarGreen} />
                          <p>駐車場</p>
                        </>
                      )}
                    </div>
                    <div className="PropertiesModalDesc_item">
                      {autoLock.length === 0 ? (
                        <>
                          <img src={iconDoorGray} />
                          <p style={{ color: "gray", opacity: 0.3 }}>
                            オートロック
                          </p>
                        </>
                      ) : (
                        <>
                          <img src={iconDoorGreen} />
                          <p>オートロック</p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="PropertiesModalDesc_text">
                    <p>{data?.introduction && data?.introduction}</p>
                  </div>

                  <div className="PropertiesModalDesc_tagArea">
                    {roomFeatures.map((item) => {
                      return (
                        <div className="PropertiesModalDesc_tag">
                          {item.feature.name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="PropertiesModalDetail">
                  <div className="PropertiesModalDetail_heading">費用</div>
                  <div className="PropertiesModalDetail_table">
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">賃料</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.rent ? (
                          <span>
                            {(data.contracts[0]?.rent / 10000).toFixed(1)}
                          </span>
                        ) : (
                          " お問い合わせください "
                        )}
                        {data.contracts[0]?.rent && "万円"}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">管理費等</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.management_fee ? (
                          <>
                            {data.contracts[0]?.management_fee.toLocaleString()}
                          </>
                        ) : (
                          " "
                        )}
                        円
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">敷金</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.shikikin &&
                          data.contracts[0].shikikin}{" "}
                        {data.contracts[0]?.shikikin &&
                          feeUnitEnums[data.contracts[0]?.shikikin_unit]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">保証金</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.guaranty_fee &&
                          data.contracts[0]?.guaranty_fee}{" "}
                        {data.contracts[0]?.guaranty_fee &&
                          feeUnitEnums[data.contracts[0]?.guaranty_fee_unit]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">礼金</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.reikin && data.contracts[0]?.reikin}{" "}
                        {data.contracts[0]?.reikin &&
                          feeUnitEnums[data.contracts[0]?.reikin_unit]}
                      </div>
                    </div>
                  </div>

                  {/* <div className="PropertiesModalDetail_heading">費用</div>
                <div className="PropertiesModalDetail_table">
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">賃料</div>
                    <div className="PropertiesModalDetail_td">10.2万円</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">管理費等</div>
                    <div className="PropertiesModalDetail_td">8000円</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">敷金</div>
                    <div className="PropertiesModalDetail_td">1ヶ月</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">保証金</div>
                    <div className="PropertiesModalDetail_td">なし</div>
                  </div>
                  <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">礼金</div>
                    <div className="PropertiesModalDetail_td">1ヶ月</div>
                  </div>
                </div> */}

                  <div className="PropertiesModalDetail_heading">
                    お部屋の設備・特徴
                  </div>
                  <div className="PropertiesModalDetail_table">
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">
                        バス・トイレ
                      </div>
                      <div className="PropertiesModalDetail_td">
                        {bathAndToilet.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">キッチン</div>
                      <div className="PropertiesModalDetail_td">
                        {kitchen.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">
                        セキュリティ
                      </div>
                      <div className="PropertiesModalDetail_td">
                        {security.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">室内設備</div>
                      <div className="PropertiesModalDetail_td">
                        {insideFacility.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">共有部</div>
                      <div className="PropertiesModalDetail_td">
                        {sharing.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">その他</div>
                      <div className="PropertiesModalDetail_td">
                        {others.map((item, i, arr) => {
                          return (
                            <>
                              {item.feature.name}
                              {i != arr.length - 1 ? "、 " : ""}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="PropertiesModalDetail_heading">
                    その他お部屋情報
                  </div>
                  <div className="PropertiesModalDetail_table">
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">
                        建物名・部屋
                      </div>
                      <div className="PropertiesModalDetail_td">
                        {data.property.name} {data.name}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">階 / 階建</div>
                      <div className="PropertiesModalDetail_td">
                        {data?.floor && data?.floor}階 /{" "}
                        {data.property?.floor && data.property?.floor}階
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">間取り</div>
                      <div className="PropertiesModalDetail_td">
                        {roomLayoutEnums[data?.layout]} ({data?.layout_detail})
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">占有面積</div>
                      <div className="PropertiesModalDetail_td">
                        {data?.size}㎡
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">主要採光面</div>
                      <div className="PropertiesModalDetail_td">
                        {propertyDirectionEnums[data?.direction]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">物件種目</div>
                      <div className="PropertiesModalDetail_td">
                        {/* 賃貸マンション */}
                        {propertyKindEnums[data.property?.kind]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">築年月</div>
                      <div className="PropertiesModalDetail_td">
                        {[
                          elapsedYearsAndMonth(
                            data.property?.building_year,
                            data.property?.building_month
                          ),
                        ]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">
                        建物構造・工法
                      </div>
                      <div className="PropertiesModalDetail_td">
                        {propertyStructureEnums[data.property?.structure]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">契約期間</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.term &&
                          `${data.contracts[0]?.term}年`}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">仲介手数料</div>
                      <div className="PropertiesModalDetail_td">1万円</div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">更新料</div>
                      <div className="PropertiesModalDetail_td">
                        {data.contracts[0]?.renewal_fee &&
                          data.contracts[0]?.renewal_fee}
                        {data.contracts[0]?.renewal_fee_unit &&
                          feeUnitEnums[data.contracts[0]?.renewal_fee_unit]}
                      </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">現況</div>
                      <div className="PropertiesModalDetail_td">空家</div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">入居日</div>
                      <div className="PropertiesModalDetail_td">即日</div>
                    </div>
                  </div>

                  <div className="PropertiesModalDetail_table">
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">情報公開日</div>
                      <div className="PropertiesModalDetail_td">
                        {data.published_at && handleToDate(data.published_at)}
                      </div>
                    </div>
                    {/* <div className="PropertiesModalDetail_tr">
                    <div className="PropertiesModalDetail_th">
                      次回更新予定日
                    </div>
                    <div className="PropertiesModalDetail_td">
                      2022年1月19日
                    </div>
                  </div> */}
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">物件番号</div>
                      <div className="PropertiesModalDetail_td">
                        {data.property.id}
                      </div>
                    </div>
                  </div>

                  {/* <div className="PropertiesModalDetail_heading">
                    その他お部屋情報
                  </div>

                  <div className="PropertiesModalDetail_action">
                    <div className="btnStyle-fill">
                      <img src="/assets/icon/icon-map.svg" />
                      地図を見る
                    </div>
                    <div className="btnStyle-fill">
                      <img src="/assets/icon/icon-walk.svg" />
                      ストリートビューを見る
                    </div>
                  </div>

                  <div className="PropertiesModalDetail_table simple">
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">コンビニ</div>
                      <div className="PropertiesModalDetail_td">
                        {/* ファミリーマート 渋谷駅前店まで191m（徒歩3分） */}
                  {/* </div>
                    </div>
                    <div className="PropertiesModalDetail_tr">
                      <div className="PropertiesModalDetail_th">
                        ドラッグストア
                      </div>
                      <div className="PropertiesModalDetail_td">
                        くすりの福太郎 渋谷駅前店まで453m（徒歩6分）
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="PropertiesModalReservation">
                  <div className="PropertiesModalReservation_copy">
                    \ すぐに見れるよ！ /
                  </div>
                  <div
                    className="btnStyle-fill"
                    onClick={handleGoToReservation}
                  >
                    <img src="/assets/icon/icon-calender-white.svg" />
                    セルフ内見予約をする
                  </div>
                  <a
                    href="/user_guide"
                    className="PropertiesModalReservation_what"
                  >
                    <img src="/assets/icon/icon-question.svg" />
                    セルフ内見予約って？
                  </a>
                </div>

                <div className="PropertiesModalBottom">
                  <a
                    href="/contact"
                    className="PropertiesModalBottom_btn btnStyle-white"
                  >
                    このお部屋についてのお問い合わせ
                  </a>
                </div>

                <div className="PropertiesModalShare">
                  <div className="PropertiesModalShare_list">
                    <div className="PropertiesModalShare_item">
                      <img src="/assets/icon/icon-mail.svg" />
                      <span>メール</span>で送る
                    </div>
                    <div className="PropertiesModalShare_item">
                      <img src="/assets/icon/icon-line-green.svg" />
                      <span>LINE</span>で送る
                    </div>
                    <div className="PropertiesModalShare_item">
                      <img src="/assets/icon/icon-twitter.svg" />
                      <span>Twitter</span>シェア
                    </div>
                    <div className="PropertiesModalShare_item">
                      <img src="/assets/icon/icon-facebook.svg" />
                      <span>Facebook</span>シェア
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*地図を見るモーダル*/}
            {/* <div className="PropertiesModalBlack map-view">
              <div className="PropertiesModalBlack_header">
                <div className="btnStyle-white">
                  <img src="/assets/icon/icon-arrow-left.svg" />
                  お部屋情報に戻る
                </div>
                <div className="btnStyle-fill">地図アプリを開く</div>
              </div>
            </div> */}
            {mapViewModal ? (
              <MapViewModal
                setMapViewModal={setMapViewModal}
                property={data.property}
              />
            ) : null}
            {/*地図を見るモーダル end*/}

            {/*ストリートビューモーダル*/}
            {isStreetView && data && (
              <StreetViewModal property={data.property} />
            )}
            {/*ストリートビューモーダル end*/}

            {/*画像を拡大モーダル*/}
            {/* <div className="PropertiesModalBlack image">
              <div className="PropertiesModalBlack_header">
                <div className="btnStyle-white">
                  <img src="/assets/icon/icon-arrow-left.svg" />
                  お部屋情報に戻る
                </div>
                <div className="PropertiesModalBlack_number">1/10</div>
              </div>
              <Slider
                {...sliderSettings}
                className="slider-for PropertiesModalBlackSlider_for"
              >
                {photo.map((value) => (
                  <div className="PropertiesModalBlackSlider_item">
                    <div className="PropertiesModalBlackSlider_img">
                      <img src="/assets/coming_soon.png" />
                    </div>
                    <p>{value}</p>
                  </div>
                ))}
              </Slider>
              <Slider
                {...sliderNavSettings}
                className="slider-nav PropertiesModalBlackSlider_nav"
              >
                {photo.map((value) => (
                  <div className="PropertiesModalBlackSlider_navItem">
                    <img src="/assets/coming_soon.png" />
                  </div>
                ))}
              </Slider>
            </div> */}
            {/*画像を拡大モーダル end*/}
          </div>
        </div>
      ) : null}
      {ModalAppear ? (
        <PropertyModal
          room_images={data.room_images}
          setModalAppear={setModalAppear}
        />
      ) : null}
    </>
  );
};
const DetailItem = ({ label, values }) => (
  <div className="PropertiesModalOverview_item">
    <label className="PropertiesModalOverview_th">{label}</label>
    <div className="PropertiesModalOverview_td">
      {values.map((value, index) => (
        <label key={index}>{value}</label>
      ))}
    </div>
  </div>
);
export default Modal;